import * as React from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import useOnClickOutside from 'use-onclickoutside';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { useForm } from 'react-final-form';

import { ANNOTATION_SUBJECT_CUSTOM_PLACEHOLDER } from '../../../../constants/constants';
import CustomPlaceholder from '../../../dashboard/documents/createFlk/CustomPlaceholder';
import {
    CustomPlaceholderRespondentType,
    CustomPlaceholder as CustomPlaceholderType
} from '../../../../types/UploadADoc';
import { Point } from '../../types';
import { createFreeTextAnnotation } from '../../utils';
import AnnotationButton, { AnnotationButtonTypes, DragImageBorderStyle, DragImageTextColor } from '../AnnotationButton';
import Button from '../../../../common/components/Button.js';
import Icon, { IconSize, Icons } from '../../../../common/components/Icon';
import CustomLabelTextInput from '../../../dashboard/documents/buildADoc/components/CustomLabelTextInput';
import {
    getCustomPlaceholderEmptyText,
    getCustomPlaceholderValuePlaceholderText
} from '../../../dashboard/documents/FlkAPdf/utils';
import CustomPlaceholderTypeDropdown from '../../../dashboard/documents/FlkAPdf/CustomPlaceholderTypeDropdown';
import { SIGNER_PLACEHOLDERS_DEMO } from '../../../../constants/featureFlags';
import { DropdownTriggerSize } from '../../../../components/form/FormDropdown';

import styles from './AnnotationButtonsContainer.module.scss';

type AnnotationButtonsContainerProps = {
    index: number;
    placeholder: CustomPlaceholderType;
    webViewerInstance?: WebViewerInstance;
    dropPointRef: React.MutableRefObject<Point | undefined>;
    setHoveredCustomPlaceholderId: (customPlaceholderId?: string) => void;
    triggerIconSize?: IconSize;
    fontSize?: number;
};

export const AnnotationButtonsContainer: React.FC<AnnotationButtonsContainerProps> = ({
    webViewerInstance,
    dropPointRef,
    placeholder,
    index,
    setHoveredCustomPlaceholderId,
    fontSize
}) => {
    const form = useForm();
    const numberOfClients = form.getState().values.clients?.length || 0;

    const isSignerPlaceholderDemoActive = useFeatureFlag(SIGNER_PLACEHOLDERS_DEMO);

    const [isEditing, setIsEditing] = React.useState(false);
    const [hideTooltip, setHideTooltip] = React.useState(false);

    const annotationText = placeholder.value || getCustomPlaceholderEmptyText(placeholder.respondentType);
    const inputContainerRef = React.useRef<HTMLInputElement>(null);
    const valueInputRef = React.useRef<HTMLInputElement>(null);
    const labelInputRef = React.useRef<HTMLInputElement>(null);

    useOnClickOutside(inputContainerRef, () => setIsEditing(false));

    return (
        <div className={styles.buttonContainer}>
            {isEditing ? (
                <>
                    <CustomLabelTextInput
                        ref={inputContainerRef}
                        name={`customPlaceholders[${index}]`}
                        valueInputRef={valueInputRef}
                        labelInputRef={labelInputRef}
                        valuePlaceholder={getCustomPlaceholderValuePlaceholderText(placeholder.respondentType)}
                        className={styles.customPlaceholderInput}
                        valueDisabled={
                            placeholder.respondentType === CustomPlaceholderRespondentType.CLIENT ||
                            placeholder.lockedValue
                        }
                        labelDisabled={placeholder.lockedLabel}
                    />
                </>
            ) : (
                <>
                    <AnnotationButton
                        type={AnnotationButtonTypes.CUSTOM_PLACEHOLDER}
                        webViewerInstance={webViewerInstance}
                        dropPointRef={dropPointRef}
                        createAnnotation={(dropPoint, options) => {
                            if (webViewerInstance) {
                                createFreeTextAnnotation(
                                    annotationText,
                                    ANNOTATION_SUBJECT_CUSTOM_PLACEHOLDER,
                                    true,
                                    webViewerInstance,
                                    dropPoint,
                                    {
                                        ...options,
                                        customPlaceholderData: {
                                            id: placeholder.id,
                                            isBlank: !placeholder.value,
                                            respondentType: placeholder.respondentType
                                        }
                                    }
                                );
                            }
                        }}
                        dragImageText={annotationText}
                        dragImageBorderStyle={
                            placeholder.value ? DragImageBorderStyle.SOLID : DragImageBorderStyle.DASHED
                        }
                        dragImageTextColor={placeholder.value ? DragImageTextColor.BLACK : DragImageTextColor.GREY}
                        onMouseEnter={() => setHoveredCustomPlaceholderId(placeholder.id)}
                        onMouseLeave={() => setHoveredCustomPlaceholderId()}
                        onDragStart={() => setHideTooltip(true)}
                        onDragEnd={() => setHideTooltip(false)}
                        fontSize={fontSize}
                    >
                        <CustomPlaceholder
                            number={index + 1}
                            label={placeholder.label}
                            value={placeholder.value}
                            respondentType={placeholder.respondentType}
                            hideTooltip={hideTooltip}
                        />
                    </AnnotationButton>
                    <div className={styles.buttons}>
                        {isSignerPlaceholderDemoActive && (
                            <CustomPlaceholderTypeDropdown
                                index={index}
                                customPlaceholderName={`customPlaceholders[${index}]`}
                                customPlaceholderRespondentType={placeholder.respondentType}
                                isSignerPlaceholderOptionDisabled={numberOfClients > 1}
                                hideTriggerIcon
                                dropdownTriggerSize={DropdownTriggerSize.SMALL}
                                disabled={placeholder.lockedRespondentType}
                            />
                        )}
                        <Button
                            type="button"
                            className={styles.editButton}
                            onClick={() => {
                                setIsEditing(true);
                                requestAnimationFrame(() => {
                                    if (placeholder.respondentType === CustomPlaceholderRespondentType.SENDER) {
                                        valueInputRef.current?.focus();
                                    } else {
                                        labelInputRef.current?.focus();
                                    }
                                });
                            }}
                        >
                            <Icon icon={Icons.EDIT} size={IconSize.SMALL} />
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
