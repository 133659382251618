const LAST_SEND = 'lastSend';
const MIN_RESEND_TIME = 5000;

export function clearDoubleSendGuard() {
    localStorage.removeItem(LAST_SEND);
}

export default function useDoubleSendGuard() {
    return <T>(fn: () => T) => {
        const now = new Date();

        if (localStorage[LAST_SEND] && now.getTime() - Number(localStorage[LAST_SEND]) < MIN_RESEND_TIME) {
            return;
        }

        localStorage[LAST_SEND] = now.getTime();

        return fn();
    };
}
