import React from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

import Card, { CardShadow, CardStyles } from '../../../../common/components/cards/Card';
import Button from '../../../../common/components/Button.js';
import { ReactComponent as CustomPlaceholderImage } from '../../../../../assets/images/content/customPlaceholderExample.svg';
import { generateRandomId } from '../../../../utils/generalUtils.js';
import Listen from '../buildADoc/components/Listen';
import Icon, { Icons } from '../../../../common/components/Icon';
import { CustomPlaceholder, CustomPlaceholderRespondentType } from '../../../../types/UploadADoc';
import { SIGNER_PLACEHOLDERS_DEMO } from '../../../../constants/featureFlags';
import CustomPlaceholderInputs from './CustomPlaceholderInputs';
import TooltipIcon, { TooltipIconType } from '../../../../common/components/TooltipIcon';

import styles from './CustomPlaceholderFormSection.module.scss';

const PLACEHOLDER_CHANGED_WARNING_TEXT =
    'You have changed information that has been added to the PDF. We suggest checking the PDF before sending it.';
const PLACEHOLDER_COUNT_ERROR_TEXT =
    "Placeholders that haven't been added to the document need to be deleted or added.";

type CustomPlaceholderFormSectionProps = {
    isReadOnly?: boolean;
    handleOpenPdfViewer?: () => void;
    deleteAnnotationsForCustomPlaceholder: (customPlaceholderId: string) => void;
    removeCustomPlaceholderValidation: (index: number) => void;
    canOpenPdfViewer?: boolean;
    showCustomPlaceholderWarning?: boolean;
    className?: string;
    onEditCustomPlaceholderValues?: (count: number) => void;
    isMobileView?: boolean;
    isPdfViewerBusy?: boolean;
    hideOpenPdfViewerButton?: boolean;
    recipientLabelLowercase?: string;
    title?: string;
    subtitle?: string;
    templatePlaceholderKeys?: string[];
    suggestions?: { key: string; values: string[] }[];
};

const CustomPlaceholderFormSection: React.FC<CustomPlaceholderFormSectionProps> = ({
    isReadOnly,
    handleOpenPdfViewer,
    canOpenPdfViewer,
    showCustomPlaceholderWarning,
    deleteAnnotationsForCustomPlaceholder,
    removeCustomPlaceholderValidation,
    onEditCustomPlaceholderValues,
    className,
    isMobileView,
    isPdfViewerBusy,
    hideOpenPdfViewerButton,
    recipientLabelLowercase,
    title,
    subtitle,
    templatePlaceholderKeys,
    suggestions
}) => {
    const inputToFocusRef = React.useRef<HTMLInputElement>(null);
    const form = useForm();

    const hasCountError = !!form
        .getState()
        .errors?.customPlaceholders?.some((placeholder: CustomPlaceholder) => placeholder?.count);

    const isSignerPlaceholderDemoActive = useFeatureFlag(SIGNER_PLACEHOLDERS_DEMO);

    const tooltipIconType = hasCountError ? TooltipIconType.ERROR : TooltipIconType.WARNING;

    return (
        <Card
            style={CardStyles.SQUARE}
            shadow={CardShadow.SMALL}
            className={cx(styles.container, className)}
            animate={false}
        >
            <FieldArray name={'customPlaceholders'}>
                {({ fields }) => (
                    <>
                        <div className={styles.content}>
                            <div className={styles.header}>
                                <h2 className={styles.heading}>{title || 'Custom info placeholders'}</h2>
                                <p className={styles.subHeading}>
                                    {subtitle || ' Create areas for information to be placed on your document '}
                                </p>
                                {!!fields.length && (hasCountError || showCustomPlaceholderWarning) && (
                                    <TooltipIcon type={tooltipIconType} className={styles.icon}>
                                        {hasCountError && <p>{PLACEHOLDER_COUNT_ERROR_TEXT}</p>}
                                        {showCustomPlaceholderWarning && <p>{PLACEHOLDER_CHANGED_WARNING_TEXT}</p>}
                                    </TooltipIcon>
                                )}
                            </div>
                            {!!fields.length && (
                                <>
                                    <CustomPlaceholderInputs
                                        disabled={isReadOnly}
                                        onDelete={(customPlaceholderData, index) => {
                                            deleteAnnotationsForCustomPlaceholder(customPlaceholderData.id);
                                            removeCustomPlaceholderValidation(index);
                                            fields.remove(index);
                                        }}
                                        inputToFocusRef={inputToFocusRef}
                                        recipientLabelLowercase={recipientLabelLowercase}
                                        suggestions={suggestions}
                                        templatePlaceholderKeys={templatePlaceholderKeys}
                                    />
                                    {fields.map((name, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {onEditCustomPlaceholderValues && (
                                                    <Listen
                                                        to={`${name}.value`}
                                                        onChange={() => {
                                                            onEditCustomPlaceholderValues(fields.value[index].count);
                                                        }}
                                                    />
                                                )}
                                                {isSignerPlaceholderDemoActive && (
                                                    <Listen
                                                        to={`${name}.respondentType`}
                                                        onChange={() => {
                                                            form.change(`${name}.value`, '');
                                                        }}
                                                    />
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                            {!isReadOnly && !isMobileView && (
                                <div className={styles.buttons}>
                                    <Button
                                        className={cx(styles.button, styles.addButton)}
                                        newSecondary
                                        onClick={() => {
                                            fields.push({
                                                id: generateRandomId(),
                                                label: '',
                                                value: '',
                                                respondentType: CustomPlaceholderRespondentType.SENDER,
                                                count: 0
                                            });

                                            requestAnimationFrame(() => {
                                                inputToFocusRef.current?.focus();
                                            });
                                        }}
                                        type="button"
                                        endIcon={<Icon icon={Icons.PLUS} className={styles.addIcon} />}
                                        data-test="add-custom-placeholder-button"
                                    >
                                        {fields.length ? 'Add another' : 'Add'}
                                    </Button>
                                    {!!fields.length && !hideOpenPdfViewerButton && handleOpenPdfViewer && (
                                        <Button
                                            disabled={!canOpenPdfViewer}
                                            className={styles.button}
                                            primary
                                            onClick={handleOpenPdfViewer}
                                            type="button"
                                            loading={isPdfViewerBusy}
                                        >
                                            Position placeholders
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                        {!fields.length && (
                            <CustomPlaceholderImage className={cx(styles.image, { [styles.disabled]: isReadOnly })} />
                        )}
                    </>
                )}
            </FieldArray>
        </Card>
    );
};

export default CustomPlaceholderFormSection;
