import React from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import {
    Root as AccordionRoot,
    Item as AccordionItem,
    Trigger as AccordionTrigger,
    Content as AccordionContent
} from '@radix-ui/react-accordion';
import { createStampAnnotation, createFreeTextAnnotation } from '../utils';
import { Point } from '../types';
import { Tag, TagColor, TagSize } from '../../../common/components/tag/Tag';
import Card, { CardShadow, CardStyles } from '../../../common/components/cards/Card';
import {
    ANNOTATION_CONTENT_COMPLETION_DATE,
    ANNOTATION_CONTENT_COMPLETION_DATE_EOC,
    ANNOTATION_SUBJECT_AGENT_NAME,
    ANNOTATION_SUBJECT_COMPLETION_DATE,
    ANNOTATION_SUBJECT_SENDER_NAME,
    AccountType
} from '../../../constants/constants';
import { isAgencyUserAccount } from '../../../utils/userUtils.js';
import senderPlaceHolderImage from '../../../../assets/images/pdf-viewer/Sender signature@4x.png';
import agentPlaceHolderImage from '../../../../assets/images/pdf-viewer/Agent.png';
import Icon, { Icons, IconSize } from '../../../common/components/Icon';
import AnnotationButton, { AnnotationButtonTypes } from './AnnotationButton';

import styles from './Sender.module.scss';

type SenderProps = {
    name: string;
    accountType: AccountType;
    webViewerInstance?: WebViewerInstance;
    dropPointRef?: React.MutableRefObject<Point | undefined>;
    agentName: string;
    isOnboarding: boolean;
    isEoc?: boolean;
    fontSize?: number;
};

const Sender: React.FC<SenderProps> = ({
    name,
    accountType,
    webViewerInstance,
    dropPointRef,
    agentName,
    isOnboarding,
    isEoc,
    fontSize
}) => {
    const completionDateText = isEoc ? ANNOTATION_CONTENT_COMPLETION_DATE_EOC : ANNOTATION_CONTENT_COMPLETION_DATE;

    return (
        <Card animate={false} style={CardStyles.SQUARE} className={styles.sender} shadow={CardShadow.NONE}>
            <AccordionRoot type="single" collapsible defaultValue="sender-1">
                <AccordionItem value={`sender-1`}>
                    <AccordionTrigger className={styles.details}>
                        <Tag text="Sender" color={TagColor.Green} size={TagSize.X_Small} />
                        <div className={styles.senderName}>{name}</div>
                        <Icon icon={Icons.CHEVRON_DOWN} className={styles.accordionIcon} />
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className={styles.buttonSection}>
                            <AnnotationButton
                                type={AnnotationButtonTypes.SENDER}
                                webViewerInstance={webViewerInstance}
                                dropPointRef={dropPointRef}
                                createAnnotation={(dropPoint, options) => {
                                    if (webViewerInstance) {
                                        createStampAnnotation(
                                            isAgencyUserAccount(accountType)
                                                ? agentPlaceHolderImage
                                                : senderPlaceHolderImage,
                                            'agent',
                                            webViewerInstance,
                                            dropPoint,
                                            options
                                        );
                                    }
                                }}
                                dragImageSource={
                                    isAgencyUserAccount(accountType) ? agentPlaceHolderImage : senderPlaceHolderImage
                                }
                                icon={<Icon className={styles.icon} icon={Icons.SIGNATURE} size={IconSize.SMALL} />}
                                dataTest="sender-signature"
                            >
                                Signature
                            </AnnotationButton>
                            {!isOnboarding && (
                                <AnnotationButton
                                    type={AnnotationButtonTypes.SENDER}
                                    icon={<Icon icon={Icons.LABEL} size={IconSize.SMALL} />}
                                    webViewerInstance={webViewerInstance}
                                    dropPointRef={dropPointRef}
                                    createAnnotation={(dropPoint, options) => {
                                        if (webViewerInstance) {
                                            createFreeTextAnnotation(
                                                agentName,
                                                isAgencyUserAccount(accountType)
                                                    ? ANNOTATION_SUBJECT_AGENT_NAME
                                                    : ANNOTATION_SUBJECT_SENDER_NAME,
                                                true,
                                                webViewerInstance,
                                                dropPoint,
                                                options
                                            );
                                        }
                                    }}
                                    dragImageText={agentName}
                                    fontSize={fontSize}
                                >
                                    Name
                                </AnnotationButton>
                            )}
                            <AnnotationButton
                                webViewerInstance={webViewerInstance}
                                dropPointRef={dropPointRef}
                                createAnnotation={(dropPoint, options) => {
                                    if (webViewerInstance) {
                                        createFreeTextAnnotation(
                                            completionDateText,
                                            ANNOTATION_SUBJECT_COMPLETION_DATE,
                                            true,
                                            webViewerInstance,
                                            dropPoint,
                                            options
                                        );
                                    }
                                }}
                                icon={<Icon className={styles.icon} icon={Icons.DATE} size={IconSize.SMALL} />}
                                type={AnnotationButtonTypes.SENDER}
                                dragImageText={completionDateText}
                                fontSize={fontSize}
                            >
                                {completionDateText}
                            </AnnotationButton>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </AccordionRoot>
        </Card>
    );
};

export default Sender;
