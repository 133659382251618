import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { cloneDeep, sortBy, pull, find, findIndex, debounce } from 'lodash';
import axios from 'axios';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { confirmAlert } from 'react-confirm-alert';
import Warning from '../../../../components/dashboard/warnings/Warning';
import styles from './CreateAFlk.module.scss';
import cx from 'classnames';
import { useMutation } from '@tanstack/react-query';

import {
    closeCreateFlkModal,
    setCurrentDocument,
    addOrReplaceDocumentInList,
    removeDocumentInList
} from '../../../../actions/document';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../actions/dashboard';
import { getTemplates } from '../../../../actions/template';

import { FormTextMultiline, FormTextRegular } from '../../../../components/form/FormText';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import CreateFlkPersonGroup from '../../../../components/document/CreateFlkPersonGroup';
import ModalDialog from '../../../../common/components/ModalDialog';
import QuestionGroup from '../QuestionGroup';
import CreateCreateAFlk from './CreateCreateAFlk';
import ClientSummary from './ClientSummary';
import AgentSummary from './AgentSummary.tsx';
import CreateFlkFooter from './CreateFlkFooter';
import CreateFlkPreview from './CreateFlkPreview';
import { CloseSharp } from '@flk-mui-icons';
import SmsSharpIcon from '@flk-mui-icons/ChatSharp';
import EmailSharpIcon from '@flk-mui-icons/EmailSharp';
import PreviewIcon from '@flk-mui-icons/Visibility';
import ClosePreviewIcon from '@flk-mui-icons/VisibilityOff';
import { FormRadioGroupButton } from '../../../../components/form/FormRadioGroupButton';
import Button from '../../../../common/components/Button';
import useDoubleSendGuard, { clearDoubleSendGuard } from '../../../../hooks/useDoubleSendGuard';
import useResendCounter from '../../../../hooks/useResendCounter';

import {
    DOCUMENT_CREATE_A_FLK,
    CREATE_A_FLK_TEMPLATE,
    CREATE_A_FLK_GLOBAL_TEMPLATE,
    CLIENT_AGENT,
    CLIENT_LANDLORD,
    CLIENT_TENANT,
    CLIENT_VENDOR,
    CLIENT_CUSTOM,
    CLIENT_TYPE_FROM,
    CLIENT_TYPE_TO,
    PARTY_NONE,
    PARTY_LANDLORD,
    CLOSE_MODAL_MESSAGE,
    defaultAnswerOptions,
    FORM_SUBMIT_TYPE_SEND,
    DOCUMENT_DELIVERY_TYPE_EMAIL,
    DOCUMENT_DELIVERY_TYPE_SMS,
    CLIENT_FIELD_LABEL_MOBILE,
    CLIENT_TRADING_NAME,
    CLIENT_OTHER,
    CREATE_A_FLK_DISPLAY,
    CONTACT_TYPE_AUTO_SAVE
} from '../../../../config';
import {
    isDocumentTemplateMode,
    isDocumentEditMode,
    isDocumentReadOnlyMode,
    isDocumentTemplateReadOnlyMode,
    isDocumentStatusDraft
} from '../../../../utils/generalUtils';
import { setQuestionsToFormFromBackend, canShowField } from '../../../../utils/questionsUtils';

import { getUserInfo } from '../../../../selectors/user';
import { getCurrentDocument, getDocumentEditMode, getIsGlobalTemplate } from '../../../../selectors/document';
import { getIsOpenCreateFlkModal } from '../../../../selectors/document/createFlk';

import '../../../../sass/questionsDocument.scss';
import '../../../../sass/documentSummary.scss';
import '../../../../sass/createFlkForm.scss';

import DocumentTitleHeader from '../../DocumentTitleHeader';
import { NewDocumentHeader } from '../../../../components/dashboard/InfoAgreementComponents/NewDocumentHeaderLeft';
import { CreateFlkHelp } from '../../help/CreateFlkHelp';
import DocumentSignatures from '../DocumentSignatures';
import { getClientTypeFromDoc } from '../../../../utils/documentUtils';
import ReactTooltip from 'react-tooltip';
import ContactSearch from '../../../../components/search/ContactSearch';

import { useAccountProvider } from '../../../../components/providers/AccountProvider';
import { isBusinessUserAccount } from '../../../../utils/userUtils';
import appHistory from '../../../../AppHistory';
import { agreementsSentAlert } from '../../../shared/DocumentConfirmAlertHelper';
import { useHistory } from 'react-router-dom';
import { getHeaderTitle } from '../../../../utils/formUtils';
import AnswersGroup from './AnswersGroup';
import CompletionEmailNotifications from '../../../../components/CompletionEmailNotifications.tsx';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { DOC_COMPLETION_EMAIL_SEND_LIST, TEMPLATE_SCREEN_V2 } from '../../../../constants/featureFlags';

const landlordAgentToolTip =
    'When an agreement is between a landlord and a tenant you can tick this box to have your agency nominated as acting as the “landlords agent” and it will nominate your agency details on the agreement as well.';

const CREATE_A_FLK_SAVE_API = 'api/document/create-a-flk';
const CREATE_A_FLK_SEND_API = 'api/document/send-create-a-flk';
const CREATE_A_FLK_TEMPLATE_API = 'api/template/create-a-flk';

const CORPORATION_NAME_LABEL = 'Corporation Name';
const FIRST_NAME_LABEL = 'First Name';

const CreateFlkSummary = ({ values, currentDocument, loggedInUser, isCompletionSendListActive, form }) => {
    return (
        <div className="summary-view">
            {values.documentInstructions && (
                <div className="summary-item">
                    <div className="help-message help-message-and-video">
                        <p className="description">{values.documentInstructions}</p>
                    </div>
                </div>
            )}
            <div className="summary-item">
                <h2>Delivery type</h2>
                {currentDocument.deliveryType === DOCUMENT_DELIVERY_TYPE_SMS && (
                    <div className="icon-group">
                        <SmsSharpIcon /> SMS
                    </div>
                )}
                {currentDocument.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL && (
                    <div className="icon-group">
                        <EmailSharpIcon /> EMAIL
                    </div>
                )}
            </div>
            <div className={styles.summaryPanel}>
                <div className={styles.item}>
                    <ClientSummary currentDocument={currentDocument} type={CLIENT_TYPE_FROM} />
                </div>
                {currentDocument.from.includeLandlordAgentDetails && (
                    <div className={styles.item}>
                        <AgentSummary
                            currentDocument={currentDocument}
                            type={CLIENT_TYPE_FROM}
                            agency={loggedInUser.agency}
                        />
                    </div>
                )}
            </div>
            <div className={styles.summaryPanel}>
                <div className={styles.item}>
                    <ClientSummary
                        currentDocument={currentDocument}
                        type={CLIENT_TYPE_TO}
                        showCopySigningLink={currentDocument.status === LEASE_STATUS_SENT_SIGNING}
                    />
                </div>
                {currentDocument.to.includeLandlordAgentDetails && (
                    <div className={styles.item}>
                        <AgentSummary
                            currentDocument={currentDocument}
                            type={CLIENT_TYPE_TO}
                            agency={loggedInUser.agency}
                        />
                    </div>
                )}
            </div>
            <div className="summary-item allow-wrap">
                <h2>Document description</h2>
                <p>{values.documentDescription}</p>
            </div>
            <AnswersGroup questions={currentDocument.questions} doc={currentDocument} />
            <div className="summary-item allow-wrap">
                <h2>Client acknowledgement statement</h2>
                <p>{values.acknowledgementText}</p>
            </div>
            {isCompletionSendListActive && currentDocument?.status === LEASE_STATUS_AWAITING_COMPLETION && (
                <div className="summary-item">
                    <CompletionEmailNotifications
                        form={form}
                        values={values}
                        emails={currentDocument.to.clientsEmailList}
                        ccEmails={[currentDocument.agent.email]}
                        bccEmails={[]}
                    />
                </div>
            )}
        </div>
    );
};

const CreateFlkForm = ({ availableFields }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const formValuesRef = React.useRef(null);
    const accountType = useAccountProvider();
    const isOpen = useSelector(getIsOpenCreateFlkModal);
    const loggedInUser = useSelector(getUserInfo);
    const currentDocument = useSelector(getCurrentDocument);
    const documentEditMode = useSelector(getDocumentEditMode);
    const isGlobalTemplate = useSelector(getIsGlobalTemplate);

    const [permanentToolTip, setPermanentToolTip] = useState('You can now send a document for signing via email');
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
    const [isSubmitFail, setIsSubmitFail] = useState(false);
    const [isSendingCreateFlk, setIsSendingCreateFlk] = useState(false);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState('');
    const [removedToFields, setRemovedToFields] = useState({});
    const [removedFromFields, setRemovedFromFields] = useState({});
    const [formValues, setFormValues] = useState({});
    const [isDirty, setIsDirty] = useState(false);
    const [isSaveDraft, setIsSaveDraft] = useState(false);
    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [initialDocumentSaved, setInitialDocumentSaved] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [showPreview, setShowPreview] = useState(false);

    const generalOfficeName = loggedInUser?.agency ? loggedInUser?.agency.details.agencyName : 'Office details';
    const isGeneralUser = isBusinessUserAccount(accountType);
    const isCompletionSendListActive = useFeatureFlag(DOC_COMPLETION_EMAIL_SEND_LIST);
    const isTemplateScreenV2Active = useFeatureFlag(TEMPLATE_SCREEN_V2);

    const doubleSendGuard = useDoubleSendGuard();
    const { resendWaitSeconds, startResendCounter } = useResendCounter();

    const getClientLabel = (values, type) => {
        if (isGeneralUser) {
            return type === CLIENT_TYPE_FROM ? 'Sender' : 'Client';
        }
        return values[type].label;
    };

    const getClientType = type => {
        if (type === CLIENT_TYPE_FROM) {
            return isGeneralUser ? CLIENT_TRADING_NAME : CLIENT_AGENT;
        }
        return isGeneralUser ? CLIENT_OTHER : CLIENT_LANDLORD;
    };

    useEffect(() => {
        if (
            initialDocumentSaved &&
            (isDocumentEditMode(documentEditMode) || isDocumentTemplateMode(documentEditMode))
        ) {
            setShowPreview(true);
        }
    }, [initialDocumentSaved]);

    useEffect(() => {
        if (currentDocument && isOpen) {
            window.history.pushState({}, '', `/dashboard/document/${currentDocument.id}`);
        }
        if (isBusinessUserAccount(loggedInUser.accountType)) {
            setPermanentToolTip('');
        }
    }, [isOpen, currentDocument]);

    useEffect(() => {
        setRemovedFromFields({});
        if (currentDocument) {
            if (!currentDocument.isCreatedFromTemplate) {
                setInitialDocumentSaved(true);
            }

            setDocumentToFormData(
                currentDocument,
                currentDocument.isCreatedFromTemplate
                    ? {
                          label: currentDocument.templateName,
                          value: currentDocument.id
                      }
                    : ''
            );
        } else {
            clearFormData();
        }
    }, [isOpen, loggedInUser, currentDocument]);

    const setDocumentToFormData = (documentData, template) => {
        const newQuestions = setQuestionsToFormFromBackend(documentData.questions ? documentData.questions : []);
        newQuestions.forEach(question => {
            const answerOptionsSelected = [];
            // this index will use to keep the same order when we push and pull
            question.answerOptionsSelected.forEach((option, index) => {
                answerOptionsSelected.push({
                    ...option,
                    index
                });
            });
            question.answerOptionsSelected = answerOptionsSelected;
            question.requireClientAction = question.partyToAnswer === PARTY_LANDLORD;
        });
        setClientsRemovedData(documentData);
        setSelectedTemplate(template);
        let templateName = documentData.templateName;
        if (documentData.docType === DOCUMENT_CREATE_A_FLK) {
            templateName = documentData.documentTitle;
        } else if (documentData.isCreatedFromTemplate) {
            templateName = '';
        }
        setFormData({
            templateName,
            from: {
                label: documentData?.from?.label,
                clients: documentData.from
                    ? documentData.from.clients
                    : [
                          {
                              data: getClientFieldData(documentData.from.label, CLIENT_TYPE_FROM, true, documentData),
                              isCorporation: false,
                              source: CONTACT_TYPE_AUTO_SAVE
                          }
                      ],
                includeLandlordAgentDetails: documentData?.from?.includeLandlordAgentDetails
            },
            to: {
                label: documentData?.to?.label,
                clients: documentData.to
                    ? documentData.to.clients
                    : [
                          {
                              data: getClientFieldData(documentData.to.label, CLIENT_TYPE_TO, false, documentData),
                              isPrimaryClient: true,
                              isCorporation: false,
                              source: CONTACT_TYPE_AUTO_SAVE
                          }
                      ],
                includeLandlordAgentDetails: documentData?.to?.includeLandlordAgentDetails
            },
            documentInstructions: documentData.documentInstructions,
            acknowledgementText: documentData.acknowledgementText,
            documentTitle: documentData.documentTitle,
            documentDescription: documentData.documentDescription,
            clauseTitle: documentData.clauseTitle,
            deliveryType: documentData.deliveryType,
            questions: newQuestions,
            disabled: isDocumentReadOnlyMode(documentEditMode),
            useTemplate: !!template,
            signElectronically: true
        });
    };

    const getClientFieldData = (label, type, useMapping = true, values = {}) => {
        const clonedDetails = cloneDeep(availableFields);
        const details = find(clonedDetails, { client: label });
        if (details) {
            if (label === CLIENT_AGENT && loggedInUser.agency) {
                details.fields.forEach(field => {
                    if (field.mappingFieldName) {
                        field.value = useMapping ? loggedInUser?.agency?.details[field.mappingFieldName] : '';
                    }
                });
            }
            if (isGeneralUser) {
                if (label === CLIENT_TRADING_NAME && loggedInUser.agency) {
                    details.fields.forEach(field => {
                        if (field.mappingFieldName) {
                            field.value = useMapping ? loggedInUser.agency.details[field.mappingFieldName] : '';
                        }
                    });
                }
                details.fields.forEach(field => {
                    if (field.label === 'Type') {
                        field.label = `${type === CLIENT_TYPE_FROM ? 'Sender' : 'Client'} Title`;
                        field.mandatory = false;
                    }
                });
                if (useMapping) {
                    loggedInUser?.agency?.details?.otherDetails.forEach(otherDetail => {
                        details.fields.push({
                            label: otherDetail.label,
                            value: otherDetail.value,
                            mandatory: false,
                            order: details.fields.length + 1,
                            prerequisite: []
                        });
                    });
                }
            }
            if (values.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL) {
                details.fields.forEach((field, index) => {
                    if (field.label === CLIENT_FIELD_LABEL_MOBILE) {
                        details.fields[index].mandatory = false;
                    }
                });
            }
            return details.fields;
        }
        return [];
    };

    const populateFieldData = (label, clientData, type) => {
        // find function return actual object. therefore we need to clone it.
        const details = cloneDeep(find(availableFields, { client: label }));
        if (details) {
            details.fields.forEach(field => {
                if (field.mappingFieldName) {
                    field.value = clientData[field.mappingFieldName] ? clientData[field.mappingFieldName] : '';
                }
                if (isGeneralUser) {
                    if (field.label === 'Type') {
                        field.label = `${type === CLIENT_TYPE_FROM ? 'Sender' : 'Client'} Title`;
                        field.mandatory = false;
                    }
                    if (field.label === 'Mobile') {
                        field.value = clientData.mobile;
                    }
                }
            });
            return details.fields;
        }
        return [];
    };

    const closeModal = () => {
        clearFormData();
        setIsDirty(false);
        dispatch(closeCreateFlkModal());
        if (currentDocument) {
            if (isDocumentTemplateMode(documentEditMode)) {
                if (isTemplateScreenV2Active) {
                    window.history.pushState({}, '', `/templates`);
                } else {
                    window.history.pushState({}, '', `/user/build-a-doc-templates`);
                }
            } else {
                window.history.pushState(
                    {},
                    '',
                    `/dashboard/documents/${currentDocument.docType}/${currentDocument.status}`
                );
            }
        } else {
            appHistory.push('/dashboard/documents/create_a_flk/draft');
        }
    };

    const handleCloseModal = () => {
        if (isDirty) {
            confirmAlert({
                title: '',
                message: CLOSE_MODAL_MESSAGE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            const values = formValuesRef.current;
                            let url = CREATE_A_FLK_SAVE_API;
                            if (isDocumentTemplateMode(documentEditMode)) {
                                url = CREATE_A_FLK_TEMPLATE_API;
                            }
                            processValuesAndMakeApiCallPromise(values, url).then(response => {
                                if (isDocumentTemplateMode(documentEditMode)) {
                                    dispatch(
                                        getTemplates(
                                            isGlobalTemplate ? CREATE_A_FLK_GLOBAL_TEMPLATE : CREATE_A_FLK_TEMPLATE
                                        )
                                    );
                                } else {
                                    dispatch(addOrReplaceDocumentInList(response.data.createFlkDocument));
                                }
                                closeModal();
                            });
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            closeModal();
                        }
                    },
                    {
                        className: 'close close-modal',
                        label: <CloseSharp>Close</CloseSharp>,
                        onClick: () => {}
                    }
                ]
            });
        } else {
            closeModal();
        }
    };

    const setClientsRemovedData = currentDocument => {
        if (currentDocument.from) {
            const clonedRemovedFromFields = cloneDeep(removedFromFields);
            currentDocument.from.clients.forEach((from, clientIndex) => {
                getClientFieldData(currentDocument.from.label, CLIENT_TYPE_FROM, true, currentDocument).forEach(
                    detailsField => {
                        const canShow = canShowField(detailsField, from);
                        if (canShow) {
                            const tempData = find(from.data, {
                                label: detailsField.label
                            });
                            if (!tempData) {
                                if (
                                    clonedRemovedFromFields[clientIndex] &&
                                    !find(clonedRemovedFromFields[clientIndex], {
                                        label: detailsField.label
                                    })
                                ) {
                                    clonedRemovedFromFields[clientIndex].push(detailsField);
                                } else {
                                    clonedRemovedFromFields[clientIndex] = [detailsField];
                                }
                                setRemovedFromFields(clonedRemovedFromFields);
                            }
                        }
                    }
                );
            });
        }

        if (currentDocument.to) {
            const clonedRemovedToFields = cloneDeep(removedToFields);
            currentDocument.to.clients.forEach((to, clientIndex) => {
                getClientFieldData(currentDocument.to.label, CLIENT_TYPE_TO, false, currentDocument).forEach(
                    detailsField => {
                        const canShow = canShowField(detailsField, to);
                        if (canShow) {
                            const tempData = find(to.data, {
                                label: detailsField.label
                            });
                            if (!tempData) {
                                if (
                                    clonedRemovedToFields[clientIndex] &&
                                    !find(clonedRemovedToFields[clientIndex], {
                                        label: detailsField.label
                                    })
                                ) {
                                    clonedRemovedToFields[clientIndex].push(detailsField);
                                } else {
                                    clonedRemovedToFields[clientIndex] = [detailsField];
                                }
                                setRemovedToFields(clonedRemovedToFields);
                            }
                        }
                    }
                );
            });
        }
    };

    const clearFormData = () => {
        setRemovedFromFields({});
        setRemovedToFields({});
        setInitialDocumentSaved(false);
        setFormData({
            templateName: '',
            from: {
                label: getClientType(CLIENT_TYPE_FROM),
                clients: [
                    {
                        data: getClientFieldData(getClientType(CLIENT_TYPE_FROM), CLIENT_TYPE_FROM),
                        source: CONTACT_TYPE_AUTO_SAVE
                    }
                ]
            },
            to: {
                label: getClientType(CLIENT_TYPE_TO),
                clients: [
                    {
                        data: getClientFieldData(getClientType(CLIENT_TYPE_TO), CLIENT_TYPE_TO, false),
                        isPrimaryClient: true,
                        isCorporation: false,
                        source: CONTACT_TYPE_AUTO_SAVE
                    }
                ]
            },
            acknowledgementText: 'I confirm I have read and reviewed the document',
            deliveryType: undefined,
            agentName: loggedInUser.fullName,
            agentId: loggedInUser.id,
            agentPhone: loggedInUser.phone,
            clauseTitle: 'Terms & Conditions',
            documentInstructions: '',
            questions: [
                {
                    questionId: '1',
                    noAgentAnswerQuestion: true,
                    description: '',
                    partyToAnswer: PARTY_NONE,
                    answerOptions: defaultAnswerOptions
                }
            ],
            disabled: false
        });
        setIsSubmitSuccessful(false);
        setIsSubmitFail(false);
    };
    const removePerson = (values, index, field) => {
        let newState = cloneDeep(values);
        if (field === CLIENT_TYPE_TO && newState[field].clients[index].isPrimaryClient) {
            newState[field].clients[0].isPrimaryClient = true;
        }
        newState[field].clients = [
            ...newState[field].clients.slice(0, index),
            ...newState[field].clients.slice(index + 1)
        ];
        setFormData({
            ...newState
        });
    };

    const addPerson = (values, field) => {
        if (!values.disabled) {
            let newState = cloneDeep(values);
            newState[field].clients.push({
                data: getClientFieldData(values[field].label, field, false, values),
                isCorporation: false,
                source: CONTACT_TYPE_AUTO_SAVE
            });
            setFormData({
                ...newState
            });
        }
    };
    const addOther = (values, index, field) => {
        let newState = cloneDeep(values);
        newState[field].clients[index].data.push({
            label: '',
            value: '',
            mandatory: false,
            other: true,
            order: newState[field].clients[index].data[newState[field].clients[index].data.length - 1].order + 1
        });
        setFormData({
            ...newState
        });
    };

    const addRemovedField = (removedField, values, index, field) => {
        let newState = cloneDeep(values);
        newState[field].clients[index].data.push(removedField);
        newState[field].clients[index].data = sortBy(newState[field].clients[index].data, ['order']);
        if (field === CLIENT_TYPE_TO) {
            pull(removedToFields[index], removedField);
            setRemovedToFields(removedToFields);
        } else if (field === CLIENT_TYPE_FROM) {
            pull(removedFromFields[index], removedField);
            setRemovedFromFields(removedFromFields);
        }
        setIsDirty(true);
        setFormData({
            ...newState
        });
    };

    const removeOther = (values, index, fieldIndex, field) => {
        let newState = cloneDeep(values);
        const removedField = newState[field].clients[index].data[fieldIndex];
        if (!removedField.other) {
            if (field === CLIENT_TYPE_TO) {
                if (removedToFields[index]) {
                    removedToFields[index].push(removedField);
                } else {
                    removedToFields[index] = [removedField];
                }
                setRemovedToFields(removedToFields);
            } else if (field === CLIENT_TYPE_FROM) {
                if (removedFromFields[index]) {
                    removedFromFields[index].push(removedField);
                } else {
                    removedFromFields[index] = [removedField];
                }
                setRemovedFromFields(removedFromFields);
            }
        }
        newState[field].clients[index].data = [
            ...newState[field].clients[index].data.slice(0, fieldIndex),
            ...newState[field].clients[index].data.slice(fieldIndex + 1)
        ];
        setIsDirty(true);
        setFormData({
            ...newState
        });
    };

    const changeFromLabel = (data, values, form) => {
        const selectedLabel = data.target.value;
        if (values[CLIENT_TYPE_FROM].label !== selectedLabel) {
            // First we should clear all from clients. then add new clients.
            // Otherwise some fields behave unexpextedly. because this is an array and there can be some cache
            form.change('from.clients', []);

            let newState = cloneDeep(values);
            newState.from.label = selectedLabel;
            if (selectedLabel !== CLIENT_LANDLORD) {
                newState.from.includeLandlordAgentDetails = false;
            }
            newState.from.clients = [
                {
                    data: getClientFieldData(selectedLabel, CLIENT_TYPE_FROM, false, values),
                    isCorporation: false,
                    source: CONTACT_TYPE_AUTO_SAVE
                }
            ];
            setRemovedFromFields({});
            setFormData({
                ...newState
            });
        }
    };

    const changeToLabel = (data, values, form) => {
        // First we should clear all to clients. then add new clients.
        // Otherwise some fields behave unexpextedly. because this is an array and there can be some cache
        form.change('to.clients', []);

        let newState = cloneDeep(values);
        const selectedLabel = data.target.value;
        if (selectedLabel !== CLIENT_LANDLORD) {
            newState.to.includeLandlordAgentDetails = false;
        }
        newState.to.label = selectedLabel;
        newState.to.clients = [
            {
                data: getClientFieldData(selectedLabel, CLIENT_TYPE_TO, false, values),
                isCorporation: false,
                isPrimaryClient: true,
                source: CONTACT_TYPE_AUTO_SAVE
            }
        ];
        setRemovedToFields({});
        setFormData({
            ...newState
        });
    };

    const processValues = values => {
        const newValues = cloneDeep(values);
        newValues.id = currentDocument && !currentDocument.isCreatedFromTemplate ? currentDocument.id : null;
        newValues.questions.forEach(question => {
            question.answerOptionsSelected = sortBy(question.answerOptionsSelected, ['index']);
        });
        newValues.isGlobalTemplate = isGlobalTemplate;
        return newValues;
    };

    const processValuesAndMakeApiCallPromise = (values, url) => {
        setIsSendingCreateFlk(true);
        setIsSubmitFail(false);
        setIsSubmitSuccessful(false);
        setError('');

        return axios.post(url, processValues(values));
    };

    const createInitialDocument = values => {
        let url = '';
        if (isDocumentTemplateMode(documentEditMode)) {
            url = CREATE_A_FLK_TEMPLATE_API;
        } else {
            url = CREATE_A_FLK_SAVE_API;
        }
        return processValuesAndMakeApiCallPromise(values, url)
            .then(response => {
                setIsSendingCreateFlk(false);
                const createFlkDocument = response.data.createFlkDocument
                    ? response.data.createFlkDocument
                    : response.data.createFlkTemplate;
                createFlkDocument.defaultOpenedQuestions = [0];
                setInitialDocumentSaved(true);
                setRemovedFromFields({});
                setRemovedToFields({});
                dispatch(setCurrentDocument(createFlkDocument));
                if (isDocumentTemplateMode(documentEditMode)) {
                    dispatch(getTemplates(isGlobalTemplate ? CREATE_A_FLK_GLOBAL_TEMPLATE : CREATE_A_FLK_TEMPLATE));
                } else {
                    dispatch(addOrReplaceDocumentInList(response.data.createFlkDocument));
                }
            })
            .catch(() => {
                setIsSendingCreateFlk(false);
                setIsSubmitFail(true);
            });
    };

    const handleFormSubmit = values => {
        if (isDocumentTemplateMode(documentEditMode)) {
            return saveTemplate(values);
        } else if (values.submitType === FORM_SUBMIT_TYPE_SEND) {
            // if it has been sent in the past, it won't change anything about the quota
            if (isBusinessUserAccount(loggedInUser.accountType) && !currentDocument.firstSentForSigning) {
                return agreementsSentAlert(values, loggedInUser, sendForSigning, dispatch, history);
            } else {
                return sendForSigning(values);
            }
        } else {
            return saveDraft(values);
        }
    };

    const saveDraft = values => {
        // This state is used to display the correct success message
        setIsSaveDraft(true);
        return processValuesAndMakeApiCallPromise(values, 'api/document/create-a-flk')
            .then(response => {
                setIsSendingCreateFlk(false);
                setIsSubmitSuccessful(true);

                dispatch(addOrReplaceDocumentInList(response.data.createFlkDocument));
                dispatch(setCurrentDocument(response.data.createFlkDocument));
                setIsDirty(false);
            })
            .catch(() => {
                setIsSendingCreateFlk(false);
                setIsSubmitFail(true);
            });
    };

    const saveTemplate = values => {
        // This state is used to display the correct success message
        setIsSaveDraft(true);
        return processValuesAndMakeApiCallPromise(values, CREATE_A_FLK_TEMPLATE_API)
            .then(() => {
                setIsSendingCreateFlk(false);
                setIsSubmitSuccessful(true);
                setIsDirty(false);

                dispatch(getTemplates(isGlobalTemplate ? CREATE_A_FLK_GLOBAL_TEMPLATE : CREATE_A_FLK_TEMPLATE));
            })
            .catch(() => {
                setIsSendingCreateFlk(false);
                setIsSubmitFail(true);
            });
    };

    const sendForSigning = async values =>
        doubleSendGuard(() => {
            // This state is used to display the correct success message
            setIsSaveDraft(false);
            return processValuesAndMakeApiCallPromise(values, CREATE_A_FLK_SEND_API)
                .then(response => {
                    dispatch(removeDocumentInList(response.data.signors[0].document, LEASE_STATUS_DRAFT));
                    setShowPreview(false);
                    setIsSendingCreateFlk(false);
                    setIsSubmitSuccessful(true);
                    setIsDirty(false);
                    return true;
                })
                .catch(error => {
                    clearDoubleSendGuard();
                    setIsSendingCreateFlk(false);
                    setIsSubmitFail(true);

                    const response = error.response;
                    let createFlkError = response.data.errors;
                    setError(createFlkError);

                    setTimeout(() => {
                        scrollToError(response.data.errors);
                    }, 1000);
                    return createFlkError;
                });
        });

    const scrollToError = errors => {
        let errorClass = 'FormError';
        if (errors.address) {
            errorClass = 'document-header';
        }
        const elementList = document.getElementsByClassName(errorClass);
        if (elementList && elementList.length > 0) {
            elementList[0].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    };

    const handlePrimaryClient = (values, index, value) => {
        let newState = cloneDeep(values);
        // If deselect primary client option, then assign that to first client.
        if (!value) {
            newState.to.clients[0].isPrimaryClient = true;
            // we can't deselect first client. we have to select other one
            if (index !== 0) {
                newState.to.clients[index].isPrimaryClient = false;
            }
            // if select any client, deselect current one and assign it to selected one
        } else {
            newState.to.clients.forEach((to, i) => {
                // Looping through clients and then check looped item with the item we want to change
                // if they are equal(index === i) then set it to primaty otherwise not a primary client
                to.isPrimaryClient = index === i;
            });
        }
        setFormData({
            ...newState
        });
    };

    const getTemplate = useMutation(
        value => {
            if (value?.docType === DOCUMENT_CREATE_A_FLK) {
                return axios.get(`api/document/${value.value}`);
            } else if ([CREATE_A_FLK_TEMPLATE, CREATE_A_FLK_GLOBAL_TEMPLATE].includes(value?.docType)) {
                return axios.get(`api/template/${value.value}`);
            }
            return null;
        },
        {
            onSuccess: (res, value) => {
                const template = res.data.lease || res.data.doc;
                if (template) {
                    if (isDocumentTemplateMode(documentEditMode) && template.to && template.to.clients) {
                        template.to.clients.forEach(client => {
                            client.signature = undefined;
                            client.tempSignature = undefined;
                            client.acknowledgedDate = undefined;
                        });
                    }
                    // If loaded from a global template, then remove old agent details and replace that with loggedin agent data
                    if (template.docType === CREATE_A_FLK_GLOBAL_TEMPLATE && template.from.label === CLIENT_AGENT) {
                        template.from.clients = [
                            {
                                data: getClientFieldData(CLIENT_AGENT, CLIENT_TYPE_FROM),
                                isCorporation: false
                            }
                        ];
                    }
                    // If loaded from a global template, then remove old agent details and replace that with loggedin agent data
                    if (
                        template.docType === CREATE_A_FLK_GLOBAL_TEMPLATE &&
                        template.from.label === CLIENT_TRADING_NAME
                    ) {
                        template.from.clients = [
                            {
                                data: getClientFieldData(CLIENT_TRADING_NAME, CLIENT_TYPE_FROM),
                                isCorporation: false
                            }
                        ];
                    }
                    setDocumentToFormData(template, value);
                } else {
                    clearFormData();
                    setSelectedTemplate(null);
                }
            },
            onError: () => {
                clearFormData();
                setSelectedTemplate(null);
            }
        }
    );

    const changeTemplate = value => {
        if (value) {
            getTemplate.mutate(value);
        } else {
            clearFormData();
            setSelectedTemplate(null);
        }
    };

    /**
     * Custom header on a modal
     * @returns {JSX.Element}
     */
    // TODO: Extract to component
    function customHeaderContent() {
        let docType = DOCUMENT_CREATE_A_FLK;
        if (isDocumentTemplateMode(documentEditMode)) {
            docType = isGlobalTemplate ? CREATE_A_FLK_GLOBAL_TEMPLATE : CREATE_A_FLK_TEMPLATE;
        }
        if (currentDocument && initialDocumentSaved) {
            return (
                <DocumentTitleHeader
                    isReadOnlyTemplate={isDocumentTemplateReadOnlyMode(documentEditMode)}
                    doc={currentDocument}
                    closeModal={handleCloseModal}
                    showHelpIcon={true}
                    helpComponent={<CreateFlkHelp />}
                    isTemplate={isDocumentTemplateMode(documentEditMode)}
                    docType={docType}
                    showLockedIcon={true}
                    hideCloseButton={isSendingCreateFlk}
                    saveDoc={() => {
                        const values = formValuesRef.current;
                        let url = CREATE_A_FLK_SAVE_API;
                        if (isDocumentTemplateMode(documentEditMode)) {
                            url = CREATE_A_FLK_TEMPLATE_API;
                        }
                        return processValuesAndMakeApiCallPromise(values, url).then(() => {
                            setIsSendingCreateFlk(false);
                            return true;
                        });
                    }}
                    customRightButton={
                        (isDocumentEditMode(documentEditMode) || isDocumentTemplateMode(documentEditMode)) && (
                            <Button
                                className={styles.previewButton}
                                startIcon={
                                    showPreview ? (
                                        <ClosePreviewIcon className={styles.previewIcon} />
                                    ) : (
                                        <PreviewIcon className={styles.previewIcon} />
                                    )
                                }
                                onClick={() => setShowPreview(oldState => !oldState)}
                                tertiary
                            >
                                {showPreview ? 'Close preview' : 'Preview'}
                            </Button>
                        )
                    }
                />
            );
        } else {
            return <NewDocumentHeader headerTitle={getHeaderTitle(docType)} />;
        }
    }

    /**
     * If last client is a corporation we should check corporation name field.
     * Otherwise check first name field
     * @param {Object} data.
     * @param {String} type
     * @param {Number} lastClientIndex
     * @return {Boolean}
     */
    const isLastClientDataFilled = (data, type, lastClientIndex) => {
        let checkDataIndex = data[type].clients[lastClientIndex].isCorporation
            ? findIndex(data[type].clients[lastClientIndex].data, {
                  label: CORPORATION_NAME_LABEL
              })
            : findIndex(data[type].clients[lastClientIndex].data, {
                  label: FIRST_NAME_LABEL
              });
        return (
            !data[type].clients[lastClientIndex].data[checkDataIndex].value ||
            data[type].clients[lastClientIndex].data[checkDataIndex].value === ''
        );
    };

    function handleSearchSelect(result, values, type) {
        const data = cloneDeep(values);
        const lastClientIndex = data[type].clients.length - 1;

        // If last client's data is not filled, then add new data to that client
        // Otherwise create a new client
        if (isLastClientDataFilled(data, type, lastClientIndex)) {
            data[type].clients[lastClientIndex].data = populateFieldData(data[type].label, result, type);
            // If client type is custom then isCorporation is always false
            data[type].clients[lastClientIndex].isCorporation =
                data[type].label === CLIENT_CUSTOM ? false : !!result.isCorporation;
        } else {
            data[type].clients.push({
                data: populateFieldData(data[type].label, result, type),
                // If client type is custom then isCorporation is always false
                isCorporation: data[type].label === CLIENT_CUSTOM ? false : !!result.isCorporation
            });
        }

        setFormData({
            ...data
        });
        setIsDirty(true);
    }

    const changeDeliveryType = (deliveryType, values) => {
        const data = cloneDeep(values);
        data.deliveryType = deliveryType;
        data.to.clients.forEach(client => {
            client.data.forEach(field => {
                if (field.label === CLIENT_FIELD_LABEL_MOBILE) {
                    field.mandatory = deliveryType === DOCUMENT_DELIVERY_TYPE_SMS;
                }
            });
        });
        data.from.clients.forEach(client => {
            client.data.forEach(field => {
                if (field.label === CLIENT_FIELD_LABEL_MOBILE) {
                    field.mandatory = deliveryType === DOCUMENT_DELIVERY_TYPE_SMS;
                }
            });
        });
        setFormData({
            ...data
        });
        setIsDirty(true);
    };

    const handleFormValuesChange = form => {
        setFormValues(form.values);

        // handle dirty form
        // Need to pass in whole form and check dirty state otherwise it doesn't recognise its dirty after template is created and form is immediately changed
        if (typeof form.getState === 'function') {
            setFormValues(form.getState().values);

            if (form.getState().dirty && !isDirty) {
                setIsDirty(true);
            }
        }
    };

    const debouncedHandleFormValuesChange = debounce(handleFormValuesChange, 300);

    return (
        <React.Fragment>
            <ModalDialog
                title={CREATE_A_FLK_DISPLAY}
                isOpen={isOpen}
                closeModal={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                className={cx(
                    styles.modalDialog,
                    'document-centre-modal',
                    'document-modal-dialog',
                    'questions-modal',
                    'larger',
                    'tenant',
                    'forced-mobile-view',
                    { 'without-header': !initialDocumentSaved }
                )}
                overlayClassName={styles.modalOverlay}
                customHeader={true}
                customHeaderContent={customHeaderContent}
                sideModal={
                    <CreateFlkPreview
                        data={currentDocument}
                        onRefreshPreview={() => handleFormSubmit(formValues)}
                        isRefreshing={isSendingCreateFlk}
                    />
                }
                sideModalIsOpen={showPreview}
                // Quick fix for templates being cut off by modal
                allowOverflow={!initialDocumentSaved}
            >
                {isDocumentTemplateReadOnlyMode(documentEditMode) && (
                    <Warning>
                        You can’t edit this template, but you can create a copy by cloning using the 3 dots at the top
                        of the screen
                    </Warning>
                )}
                {currentDocument &&
                    (currentDocument.status === LEASE_STATUS_AWAITING_COMPLETION ||
                        currentDocument.status === LEASE_STATUS_SENT_SIGNING) && (
                        <DocumentSignatures
                            label={getClientTypeFromDoc(currentDocument, CLIENT_TYPE_TO)}
                            className="full-width"
                        />
                    )}
                <Form
                    initialValues={formData}
                    mutators={{
                        ...arrayMutators
                    }}
                    onSubmit={values => handleFormSubmit(values)}
                >
                    {({
                        handleSubmit,
                        values,
                        form,
                        form: {
                            mutators: { push }
                        }
                    }) => {
                        // set the values to the ref so that the close modal can access these values
                        formValuesRef.current = values;
                        return (
                            <form noValidate onSubmit={values => handleSubmit(values)} id="create-disclosure-form">
                                <FormSpy
                                    subscription={{ values: true }}
                                    onChange={() => debouncedHandleFormValuesChange(form)}
                                />
                                {initialDocumentSaved && (
                                    <React.Fragment>
                                        <div>
                                            {/* create and edit view */}
                                            {(!currentDocument || isDocumentStatusDraft(currentDocument.status)) && (
                                                <React.Fragment>
                                                    {isDocumentTemplateMode(documentEditMode) ? (
                                                        <React.Fragment>
                                                            <h2 className="title-bottom">Instructions</h2>
                                                            <FormTextMultiline
                                                                name="documentInstructions"
                                                                disabled={values.disabled}
                                                            />
                                                        </React.Fragment>
                                                    ) : (
                                                        values.documentInstructions && (
                                                            <div className="help-message help-message-and-video multiline-text-box">
                                                                <p className={cx(styles.lineBreakText, 'description')}>
                                                                    {values.documentInstructions}
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                                    <h2>Delivery type</h2>
                                                    <FormRadioGroupButton
                                                        label=""
                                                        name="deliveryType"
                                                        data={[
                                                            {
                                                                label: 'SMS',
                                                                value: DOCUMENT_DELIVERY_TYPE_SMS,
                                                                icon: SmsSharpIcon,
                                                                onClick: () =>
                                                                    changeDeliveryType(
                                                                        DOCUMENT_DELIVERY_TYPE_SMS,
                                                                        values
                                                                    )
                                                            },
                                                            {
                                                                label: 'Email',
                                                                value: DOCUMENT_DELIVERY_TYPE_EMAIL,
                                                                icon: EmailSharpIcon,
                                                                permanentToolTip: permanentToolTip,
                                                                onClick: () =>
                                                                    changeDeliveryType(
                                                                        DOCUMENT_DELIVERY_TYPE_EMAIL,
                                                                        values
                                                                    )
                                                            }
                                                        ]}
                                                        validateOnTouch={false}
                                                        value={values.deliveryType}
                                                    />
                                                    <h2>From</h2>
                                                    {!isGeneralUser && (
                                                        <div className="label-group">
                                                            <FormRadioGroup
                                                                label=""
                                                                radioGroupClass="button-group-for-select"
                                                                isYesNoGroup={true}
                                                                name={`from.label`}
                                                                disabled={values.disabled}
                                                                data={[
                                                                    {
                                                                        label: CLIENT_AGENT,
                                                                        value: CLIENT_AGENT,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeFromLabel(data, values, form)
                                                                    },
                                                                    {
                                                                        label: CLIENT_LANDLORD,
                                                                        value: CLIENT_LANDLORD,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeFromLabel(data, values, form)
                                                                    },
                                                                    {
                                                                        label: CLIENT_TENANT,
                                                                        value: CLIENT_TENANT,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeFromLabel(data, values, form)
                                                                    },
                                                                    {
                                                                        label: CLIENT_VENDOR,
                                                                        value: CLIENT_VENDOR,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeFromLabel(data, values, form)
                                                                    },
                                                                    {
                                                                        label: CLIENT_CUSTOM,
                                                                        value: CLIENT_CUSTOM,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeFromLabel(data, values, form)
                                                                    }
                                                                ]}
                                                                value={values.from.label}
                                                            />
                                                        </div>
                                                    )}
                                                    {isGeneralUser && (
                                                        <div className="label-group">
                                                            <FormRadioGroup
                                                                label=""
                                                                radioGroupClass="button-group-for-select"
                                                                isYesNoGroup={true}
                                                                name={`from.label`}
                                                                disabled={values.disabled}
                                                                data={[
                                                                    {
                                                                        label: generalOfficeName,
                                                                        value: CLIENT_TRADING_NAME,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeFromLabel(data, values, form)
                                                                    },
                                                                    {
                                                                        label: CLIENT_OTHER,
                                                                        value: CLIENT_OTHER,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeFromLabel(data, values, form)
                                                                    }
                                                                ]}
                                                                value={values.from.label}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="tenants from-clients">
                                                        {currentDocument &&
                                                            isDocumentStatusDraft(currentDocument.status) &&
                                                            values[CLIENT_TYPE_FROM].label !== CLIENT_AGENT &&
                                                            values[CLIENT_TYPE_FROM].label !== CLIENT_TRADING_NAME && (
                                                                <React.Fragment>
                                                                    <ContactSearch
                                                                        searchBarClassName="contact-search"
                                                                        onSelect={result =>
                                                                            handleSearchSelect(
                                                                                result,
                                                                                values,
                                                                                CLIENT_TYPE_FROM
                                                                            )
                                                                        }
                                                                        contactType={DOCUMENT_CREATE_A_FLK}
                                                                        allowStaffSearch={true}
                                                                    />
                                                                    <br />
                                                                </React.Fragment>
                                                            )}
                                                        {values[CLIENT_TYPE_FROM].label === CLIENT_LANDLORD && (
                                                            <CheckboxCheck
                                                                name={`${CLIENT_TYPE_FROM}.includeLandlordAgentDetails`}
                                                                label={`Include landlords agents details`}
                                                                disabled={values.disabled}
                                                                toolTip={landlordAgentToolTip}
                                                            />
                                                        )}
                                                        <FieldArray name={`${CLIENT_TYPE_FROM}.clients`}>
                                                            {({ fields }) =>
                                                                fields.map((name, index) => (
                                                                    <CreateFlkPersonGroup
                                                                        key={`${values[CLIENT_TYPE_FROM].label}${index}`}
                                                                        type={CLIENT_TYPE_FROM}
                                                                        index={index}
                                                                        values={values}
                                                                        removePerson={index =>
                                                                            removePerson(
                                                                                values,
                                                                                index,
                                                                                CLIENT_TYPE_FROM
                                                                            )
                                                                        }
                                                                        addOther={index =>
                                                                            addOther(values, index, CLIENT_TYPE_FROM)
                                                                        }
                                                                        removeOther={(index, fieldIndex) =>
                                                                            removeOther(
                                                                                values,
                                                                                index,
                                                                                fieldIndex,
                                                                                CLIENT_TYPE_FROM
                                                                            )
                                                                        }
                                                                        removedFields={removedFromFields[index]}
                                                                        addRemovedField={(index, removedField) =>
                                                                            addRemovedField(
                                                                                removedField,
                                                                                values,
                                                                                index,
                                                                                CLIENT_TYPE_FROM
                                                                            )
                                                                        }
                                                                        disabled={values.disabled}
                                                                        label={getClientLabel(values, CLIENT_TYPE_FROM)}
                                                                    />
                                                                ))
                                                            }
                                                        </FieldArray>
                                                        {isDocumentEditMode(documentEditMode) && (
                                                            <div className="button">
                                                                <button
                                                                    className="add-tenant"
                                                                    type="button"
                                                                    onClick={() => addPerson(values, CLIENT_TYPE_FROM)}
                                                                >
                                                                    Add {getClientLabel(values, CLIENT_TYPE_FROM)}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <hr />
                                                    <h2>To</h2>
                                                    {!isGeneralUser && (
                                                        <div className="label-group">
                                                            <FormRadioGroup
                                                                label=""
                                                                radioGroupClass="button-group-for-select"
                                                                isYesNoGroup={true}
                                                                name={`to.label`}
                                                                disabled={values.disabled}
                                                                data={[
                                                                    {
                                                                        label: CLIENT_LANDLORD,
                                                                        value: CLIENT_LANDLORD,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeToLabel(data, values, form)
                                                                    },
                                                                    {
                                                                        label: CLIENT_TENANT,
                                                                        value: CLIENT_TENANT,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeToLabel(data, values, form)
                                                                    },
                                                                    {
                                                                        label: CLIENT_VENDOR,
                                                                        value: CLIENT_VENDOR,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeToLabel(data, values, form)
                                                                    },
                                                                    {
                                                                        label: CLIENT_CUSTOM,
                                                                        value: CLIENT_CUSTOM,
                                                                        disabled: values.disabled,
                                                                        onClick: data =>
                                                                            changeToLabel(data, values, form)
                                                                    }
                                                                ]}
                                                                value={values.to.label}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="tenants to-clients">
                                                        {currentDocument &&
                                                            isDocumentStatusDraft(currentDocument.status) && (
                                                                <React.Fragment>
                                                                    <ContactSearch
                                                                        searchBarClassName="contact-search"
                                                                        onSelect={result =>
                                                                            handleSearchSelect(
                                                                                result,
                                                                                values,
                                                                                CLIENT_TYPE_TO
                                                                            )
                                                                        }
                                                                        contactType={DOCUMENT_CREATE_A_FLK}
                                                                        allowStaffSearch={true}
                                                                    />
                                                                    <br />
                                                                </React.Fragment>
                                                            )}
                                                        {values[CLIENT_TYPE_TO].label === CLIENT_LANDLORD && (
                                                            <CheckboxCheck
                                                                name={`${CLIENT_TYPE_TO}.includeLandlordAgentDetails`}
                                                                label={`Include landlords agents details`}
                                                                disabled={values.disabled}
                                                                toolTip={landlordAgentToolTip}
                                                            />
                                                        )}
                                                        <FieldArray name={`${CLIENT_TYPE_TO}.clients`}>
                                                            {({ fields }) =>
                                                                fields.map((name, index) => (
                                                                    <CreateFlkPersonGroup
                                                                        key={`${values[CLIENT_TYPE_TO].label}${index}`}
                                                                        index={index}
                                                                        type={CLIENT_TYPE_TO}
                                                                        values={values}
                                                                        removePerson={index =>
                                                                            removePerson(values, index, CLIENT_TYPE_TO)
                                                                        }
                                                                        addOther={index =>
                                                                            addOther(values, index, CLIENT_TYPE_TO)
                                                                        }
                                                                        removeOther={(index, fieldIndex) =>
                                                                            removeOther(
                                                                                values,
                                                                                index,
                                                                                fieldIndex,
                                                                                CLIENT_TYPE_TO
                                                                            )
                                                                        }
                                                                        removedFields={removedToFields[index]}
                                                                        addRemovedField={(index, removedField) =>
                                                                            addRemovedField(
                                                                                removedField,
                                                                                values,
                                                                                index,
                                                                                CLIENT_TYPE_TO
                                                                            )
                                                                        }
                                                                        handlePrimaryClient={(index, value) => {
                                                                            handlePrimaryClient(values, index, value);
                                                                        }}
                                                                        disabled={values.disabled}
                                                                        label={getClientLabel(values, CLIENT_TYPE_TO)}
                                                                    />
                                                                ))
                                                            }
                                                        </FieldArray>
                                                        {isDocumentEditMode(documentEditMode) && (
                                                            <div className="button">
                                                                <button
                                                                    className="add-tenant"
                                                                    type="button"
                                                                    onClick={() => addPerson(values, CLIENT_TYPE_TO)}
                                                                >
                                                                    Add {getClientLabel(values, CLIENT_TYPE_TO)}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <hr />
                                                    <FormTextMultiline
                                                        name="documentDescription"
                                                        label="Document description"
                                                        disabled={values.disabled}
                                                        required
                                                    />
                                                    <hr />
                                                    <FormTextRegular
                                                        name="clauseTitle"
                                                        label="Clause Title"
                                                        disabled={values.disabled}
                                                        required
                                                    />
                                                    <QuestionGroup
                                                        values={values}
                                                        setFormData={setFormData}
                                                        error={error}
                                                        haveSelectAll={false}
                                                        canAddNewQuestions={true}
                                                        setIsDirty={setIsDirty}
                                                        defaultOpenedQuestions={
                                                            currentDocument
                                                                ? currentDocument.defaultOpenedQuestions
                                                                : []
                                                        }
                                                    />
                                                    <hr />
                                                    <h2>Client acknowledgement statement</h2>
                                                    <ReactTooltip
                                                        id="sign-electronically"
                                                        class="tooltip"
                                                        place="bottom"
                                                        effect="solid"
                                                        globalEventOff="wheel"
                                                    >
                                                        <p>
                                                            As it’s a requirement that your client agrees to sign
                                                            electronically, this will always be displayed on the client
                                                            side
                                                        </p>
                                                    </ReactTooltip>
                                                    <CheckboxCheck
                                                        data-tip
                                                        data-for="sign-electronically"
                                                        label="I agree to sign this document electronically"
                                                        name="signElectronically"
                                                        required={false}
                                                        validateOnTouch={false}
                                                        disabled={true}
                                                    />
                                                    <FormTextMultiline
                                                        name="acknowledgementText"
                                                        label="Acknowledgement"
                                                        disabled={values.disabled}
                                                        required
                                                    />
                                                </React.Fragment>
                                            )}
                                            {/* summary view */}
                                            {currentDocument && !isDocumentStatusDraft(currentDocument.status) && (
                                                <CreateFlkSummary
                                                    loggedInUser={loggedInUser}
                                                    currentDocument={currentDocument}
                                                    isCompletionSendListActive={isCompletionSendListActive}
                                                    values={values}
                                                    form={form}
                                                />
                                            )}
                                        </div>

                                        <div>
                                            <CreateFlkFooter
                                                closeModal={closeModal}
                                                currentDocument={currentDocument}
                                                documentEditMode={documentEditMode}
                                                isSendingCreateFlk={isSendingCreateFlk}
                                                isSubmitSuccessfull={isSubmitSuccessful}
                                                isSubmitFail={isSubmitFail}
                                                isSaveDraft={isSaveDraft}
                                                form={form}
                                                isTemplate={isDocumentTemplateMode(documentEditMode)}
                                                values={values}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                                {!initialDocumentSaved && (
                                    <CreateCreateAFlk
                                        values={values}
                                        isTemplate={isDocumentTemplateMode(documentEditMode)}
                                        changeTemplate={changeTemplate}
                                        closeModal={() => dispatch(closeCreateFlkModal())}
                                        onClickStart={() => createInitialDocument(values)}
                                        setAvailableTemplates={setAvailableTemplates}
                                        selectedTemplate={selectedTemplate}
                                        isSendingCreateFlk={isSendingCreateFlk}
                                        isLoadingTemplates={getTemplate.isLoading}
                                        loggedInUser={loggedInUser}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Form>
            </ModalDialog>
        </React.Fragment>
    );
};
export default CreateFlkForm;
export { CreateFlkSummary };
