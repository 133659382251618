import React from 'react';
import Button from '@app/common/components/Button';
import ModalDialog from '@app/common/components/ModalDialog';
import IntegrationAddressSection, { IntegrationSearchResult } from '../../integration/IntegrationAddressSection';

import styles from './LinkIntegrationModal.module.scss';

type LinkIntegrationModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    saveDoc?: () => Promise<void>; // Only required in drafts
    linkWithIntegration: (data: {
        id: string;
        address: string;
        type: string;
        entityType: string;
        vaultReLifeId?: string;
        vaultReLifeType?: string;
    }) => Promise<void>;
    isLinkingIntegration: boolean;
};

export const LinkIntegrationModal: React.FC<LinkIntegrationModalProps> = ({
    isOpen,
    closeModal,
    saveDoc,
    linkWithIntegration,
    isLinkingIntegration
}) => {
    const [isIntegrationEnabled, setIsIntegrationEnabled] = React.useState(false);
    const [selectedIntegration, setSelectedIntegration] = React.useState<IntegrationSearchResult | null>(null);

    function resetIntegration() {
        setSelectedIntegration(null);
    }

    async function link() {
        if (!selectedIntegration?.addressText) {
            return;
        }

        const data = {
            id: selectedIntegration.id,
            address: selectedIntegration.addressText,
            type: selectedIntegration.integration,
            entityType: selectedIntegration.integrationType,
            vaultReLifeId: selectedIntegration?.lifeId,
            vaultReLifeType: selectedIntegration?.lifeType
        };

        await saveDoc?.();
        await linkWithIntegration(data);

        closeModal();
    }

    return (
        <ModalDialog
            title="Link with integration"
            isOpen={isOpen}
            closeModal={closeModal}
            className={styles.integrationModal}
        >
            <IntegrationAddressSection
                setIsIntegrationEnabled={setIsIntegrationEnabled}
                resetIntegration={resetIntegration}
                handleSelectIntegration={(selectedIntegration: IntegrationSearchResult) =>
                    setSelectedIntegration(selectedIntegration)
                }
            />
            <div className={styles.footer}>
                <Button secondary type="button" onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    type="button"
                    primary
                    onClick={() => link()}
                    className={styles.submitButton}
                    disabled={!(isIntegrationEnabled && selectedIntegration) || isLinkingIntegration}
                    loading={isLinkingIntegration}
                >
                    Save Changes
                </Button>
            </div>
        </ModalDialog>
    );
};
