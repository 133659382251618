import React from 'react';
import { FormEmail } from './FormEmail';
import useSuggestions from '../../hooks/useSuggestions';
import { isEmpty } from 'lodash';

type SuggestionEmailFieldProps = React.PropsWithChildren<{
    suggestionsFieldName: string;
}>;

const SuggestionEmailField: React.FC<SuggestionEmailFieldProps> = ({ suggestionsFieldName, ...rest }) => {
    const { suggestionsList, suggestionIcon, removeSuggestionFromRedux } = useSuggestions(suggestionsFieldName);

    return !isEmpty(suggestionsList) ? (
        <FormEmail
            suggestionsList={suggestionsList}
            removeSuggestion={removeSuggestionFromRedux}
            suggestionIcon={suggestionIcon}
            {...rest}
        />
    ) : (
        <FormEmail {...rest} />
    );
};

export default SuggestionEmailField;
