import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';

import useOpenCreationModal from '../../hooks/useOpenCreationModal';
import {
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_SALES,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_HOLIDAY_LETTING,
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_TERMINATION_NOTICE,
    routes,
    DOCUMENT_CUSTOM,
    DOCUMENT_CREATE_A_FLK,
    DISCLOSURE_DOCUMENT,
    BREACH_NOTICE,
    ENTRY_NOTICE,
    FLK_A_PDF_DISPLAY,
    CREATE_A_FLK_DISPLAY,
    LEASE_TYPE_HOLIDAY_LETTING_DISPLAY,
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE,
    NSW_STATE,
    EXCHANGE_OF_CONTRACTS,
    EXCHANGE_OF_CONTRACTS_DISPLAY
} from '../../config';
import '../../sass/leftMenuDashboard.scss';
import ExpandLessIcon from '@flk-mui-icons/ExpandLessSharp';
import ExpandMoreIcon from '@flk-mui-icons/ExpandMoreSharp';
import TrendingDownIcon from '@flk-mui-icons/TrendingDownSharp';
import TrendingUpIcon from '@flk-mui-icons/TrendingUpSharp';
import DescriptionSharpIcon from '@flk-mui-icons/DescriptionSharp';
import {
    LEASE_STATUS_DRAFT,
    closeMenu,
    toggleAgreementsMenu,
    toggleDocumentsMenu,
    FLK_A_KEY_STATUS_DRAFT
} from '../../actions/dashboard';
import SettingsIcon from '@flk-mui-icons/SettingsSharp';
import HowToRegSharpIcon from '@flk-mui-icons/HowToRegSharp';
import AnnouncementSharpIcon from '@flk-mui-icons/AnnouncementSharp';
import CommercialLeaseIcon from '@flk-mui-icons/BusinessSharp';
import { BarChartSharp, LibraryBooksSharp, PictureAsPdfSharp } from '@flk-mui-icons';
import { getIsAgreementMenuOpen, getIsDocumentsMenuOpen } from '../../selectors/dashboard';
import { getAgencyDefaultLocation } from '../../selectors/settings/account';
import TerminationNotice from '../../common/components/icons/TerminationNotice';
import LeftMenuLink from '../../common/components/LeftMenuLink';

import { ReactComponent as ResidentialTenancyIcon } from '../../../assets/images/icons/tenancy.svg';
import { ReactComponent as PropertyManagementIcon } from '../../../assets/images/icons/case.svg';
import { ReactComponent as SalesIcon } from '../../../assets/images/icons/sales.svg';
import { ReactComponent as KeyIcon } from '../../../assets/images/icons/key.svg';
import { upperFirst } from 'lodash';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { FLK_RE_SIDEBAR_TEMPLATE_BUTTON } from '../../constants/featureFlags';
import Icon, { Icons } from '../../common/components/Icon';
import { openSubapp } from '../../utils/openSubapp';
import {
    HOLIDAY_LETTING_SUBAPP_PATH,
    PM_SUBAPP_PATH,
    COMMERCIAL_PM_SUBAPP_PATH,
    SALES_SUBAPP_PATH
} from '../../constants/constants';

const LeftMenu = props => {
    const isFlkReSidebarTemplateButtonActive = useFeatureFlag(FLK_RE_SIDEBAR_TEMPLATE_BUTTON);
    const isAgreementsOpen = useSelector(getIsAgreementMenuOpen);
    const isDocumentsOpen = useSelector(getIsDocumentsMenuOpen);
    const location = useSelector(getAgencyDefaultLocation);
    const dispatch = useDispatch();

    const {
        openLeaseAgreementCreationModal,
        openDocumentCreationModal,
        openFlkAKeyCreationModal,
        isLeaseTypeEnabled,
        isActiveUser,
        isDagobahAgreement
    } = useOpenCreationModal();

    const closeMenuHandler = () => {
        dispatch(closeMenu());
    };

    const toggleAgreementsMenuOpen = () => {
        dispatch(toggleAgreementsMenu(!isAgreementsOpen));
    };
    const toggleDocumentsMenuOpen = () => {
        dispatch(toggleDocumentsMenu(!isDocumentsOpen));
    };
    const handleClickOutside = () => {
        closeMenuHandler();
    };

    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    const { leftMenu } = props;

    const templateRoutes = [
        routes.ROUTE_AGREEMENTS_TEMPLATES,
        routes.ROUTE_SPECIAL_CONDITIONS_TEMPLATES,
        routes.ROUTE_CREATE_A_FLK_TEMPLATES,
        routes.ROUTE_FLK_A_PDF_TEMPLATES
    ];

    return (
        <nav ref={ref} className={leftMenu ? 'left-menu on' : 'left-menu'}>
            <div className="menu">
                <div className="item-menu" onClick={toggleAgreementsMenuOpen}>
                    <Link to={'#'}>AGREEMENTS</Link>
                    {isAgreementsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                {isAgreementsOpen && (
                    <div className="section-wrapper">
                        <ul>
                            {isLeaseTypeEnabled(LEASE_TYPE_RESIDENTIAL) && (
                                <li>
                                    <LeftMenuLink
                                        label="Residential Tenancy"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}`
                                            );
                                        }}
                                        toPath={`/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}/${LEASE_STATUS_DRAFT}`}
                                        icon={<ResidentialTenancyIcon />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openLeaseAgreementCreationModal(LEASE_TYPE_RESIDENTIAL)
                                                : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_SALES) && (
                                <li>
                                    <LeftMenuLink
                                        label="Sales"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/agreements/${LEASE_TYPE_SALES}`
                                            );
                                        }}
                                        toPath={`/dashboard/agreements/${LEASE_TYPE_SALES}/${LEASE_STATUS_DRAFT}`}
                                        icon={<SalesIcon />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () =>
                                                    isDagobahAgreement(LEASE_TYPE_SALES, location)
                                                        ? openSubapp(SALES_SUBAPP_PATH)
                                                        : openLeaseAgreementCreationModal(LEASE_TYPE_SALES)
                                                : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(EXCHANGE_OF_CONTRACTS) && (
                                <li>
                                    <LeftMenuLink
                                        label={EXCHANGE_OF_CONTRACTS_DISPLAY}
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${EXCHANGE_OF_CONTRACTS}`
                                            );
                                        }}
                                        icon={<Icon icon={Icons.EXCHANGE_OF_CONTRACTS} />}
                                        toPath={`/dashboard/documents/${EXCHANGE_OF_CONTRACTS}/${LEASE_STATUS_DRAFT}`}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openDocumentCreationModal(EXCHANGE_OF_CONTRACTS)
                                                : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_PROPERTY_MANAGEMENT) && (
                                <li>
                                    <LeftMenuLink
                                        label="Property Management"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/agreements/${LEASE_TYPE_PROPERTY_MANAGEMENT}`
                                            );
                                        }}
                                        toPath={`/dashboard/agreements/${LEASE_TYPE_PROPERTY_MANAGEMENT}/${LEASE_STATUS_DRAFT}`}
                                        icon={<PropertyManagementIcon />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () =>
                                                    isDagobahAgreement(LEASE_TYPE_PROPERTY_MANAGEMENT, location)
                                                        ? openSubapp(PM_SUBAPP_PATH)
                                                        : openLeaseAgreementCreationModal(
                                                            LEASE_TYPE_PROPERTY_MANAGEMENT
                                                        )
                                                : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT) && (
                                <li>
                                    <LeftMenuLink
                                        label="Commercial Property Management"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/agreements/${LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT}`
                                            );
                                        }}
                                        toPath={`/dashboard/agreements/${LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT}/${LEASE_STATUS_DRAFT}`}
                                        icon={<PropertyManagementIcon />}
                                        onCreateNew={
                                            isActiveUser() ? () => openSubapp(COMMERCIAL_PM_SUBAPP_PATH) : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_COMMERCIAL_LEASE) && (
                                <li>
                                    <LeftMenuLink
                                        label="Commercial Lease"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/agreements/${LEASE_TYPE_COMMERCIAL_LEASE}`
                                            );
                                        }}
                                        toPath={`/dashboard/agreements/${LEASE_TYPE_COMMERCIAL_LEASE}/${LEASE_STATUS_DRAFT}`}
                                        icon={<CommercialLeaseIcon />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openLeaseAgreementCreationModal(LEASE_TYPE_COMMERCIAL_LEASE)
                                                : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_HOLIDAY_LETTING) && (
                                <li>
                                    <LeftMenuLink
                                        label={LEASE_TYPE_HOLIDAY_LETTING_DISPLAY}
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/agreements/${LEASE_TYPE_HOLIDAY_LETTING}`
                                            );
                                        }}
                                        toPath={`/dashboard/agreements/${LEASE_TYPE_HOLIDAY_LETTING}/${LEASE_STATUS_DRAFT}`}
                                        icon={<Icon icon={Icons.HOLIDAY_HOUSE} />}
                                        onCreateNew={
                                            isActiveUser() ? () => openSubapp(HOLIDAY_LETTING_SUBAPP_PATH) : null
                                        }
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                )}
                <div className="item-menu" onClick={toggleDocumentsMenuOpen}>
                    <Link to={'#'}>DOCUMENT CENTRE</Link>
                    {isDocumentsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                {isDocumentsOpen && (
                    <div className="section-wrapper">
                        <ul>
                            <li>
                                <LeftMenuLink
                                    label="Rent Relief"
                                    onClick={closeMenuHandler}
                                    isActive={(match, location) => {
                                        return location.pathname.startsWith(
                                            `/dashboard/documents/${DOCUMENT_RENT_REDUCTION}`
                                        );
                                    }}
                                    toPath={`/dashboard/documents/${DOCUMENT_RENT_REDUCTION}/${LEASE_STATUS_DRAFT}`}
                                    icon={<TrendingDownIcon />}
                                    onCreateNew={
                                        isActiveUser() ? () => openDocumentCreationModal(DOCUMENT_RENT_REDUCTION) : null
                                    }
                                />
                            </li>
                            {isLeaseTypeEnabled(DOCUMENT_TERMINATION_NOTICE) && (
                                <li>
                                    <LeftMenuLink
                                        label="Termination Notice"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DOCUMENT_TERMINATION_NOTICE}`
                                            );
                                        }}
                                        toPath={`/dashboard/documents/${DOCUMENT_TERMINATION_NOTICE}/${LEASE_STATUS_DRAFT}`}
                                        icon={<TerminationNotice />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openDocumentCreationModal(DOCUMENT_TERMINATION_NOTICE)
                                                : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(DOCUMENT_RENT_INCREASE) && (
                                <li>
                                    <LeftMenuLink
                                        label="Rent Increase Notice"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DOCUMENT_RENT_INCREASE}`
                                            );
                                        }}
                                        toPath={`/dashboard/documents/${DOCUMENT_RENT_INCREASE}/${LEASE_STATUS_DRAFT}`}
                                        icon={<TrendingUpIcon />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openDocumentCreationModal(DOCUMENT_RENT_INCREASE)
                                                : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(BREACH_NOTICE) && (
                                <li>
                                    <LeftMenuLink
                                        label="Breach Notice"
                                        onClick={closeMenuHandler}
                                        active={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${BREACH_NOTICE}`
                                            );
                                        }}
                                        toPath={`/dashboard/documents/${BREACH_NOTICE}/${LEASE_STATUS_DRAFT}`}
                                        icon={<AnnouncementSharpIcon />}
                                        onCreateNew={
                                            isActiveUser() ? () => openDocumentCreationModal(BREACH_NOTICE) : null
                                        }
                                    />
                                </li>
                            )}

                            {isLeaseTypeEnabled(ENTRY_NOTICE) && (
                                <li>
                                    <LeftMenuLink
                                        label="Entry Notice"
                                        onClick={closeMenuHandler}
                                        active={(match, location) => {
                                            return location.pathname.startsWith(`/dashboard/documents/${ENTRY_NOTICE}`);
                                        }}
                                        toPath={`/dashboard/documents/${ENTRY_NOTICE}/${LEASE_STATUS_DRAFT}`}
                                        icon={<Icon icon={Icons.ENTRY_DOOR} />}
                                        onCreateNew={
                                            isActiveUser() ? () => openDocumentCreationModal(ENTRY_NOTICE) : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(DOCUMENT_INTENTION_TO_SELL) && (
                                <li>
                                    <LeftMenuLink
                                        label="Intention to sell notice"
                                        onClick={closeMenuHandler}
                                        active={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DOCUMENT_INTENTION_TO_SELL}`
                                            );
                                        }}
                                        toPath={`/dashboard/documents/${DOCUMENT_INTENTION_TO_SELL}/${LEASE_STATUS_DRAFT}`}
                                        icon={<Icon icon={Icons.MEGAPHONE} />}
                                        onCreateNew={() => openDocumentCreationModal(DOCUMENT_INTENTION_TO_SELL)}
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE) && (
                                <li>
                                    <LeftMenuLink
                                        label="Estimated selling price notice"
                                        onClick={closeMenuHandler}
                                        active={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE}`
                                            );
                                        }}
                                        toPath={`/dashboard/documents/${DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE}/${LEASE_STATUS_DRAFT}`}
                                        icon={<Icon icon={Icons.ESP} />}
                                        onCreateNew={() =>
                                            openDocumentCreationModal(DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE)
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(DISCLOSURE_DOCUMENT) && (
                                <li>
                                    <LeftMenuLink
                                        label="Disclosure Document - VIC"
                                        onClick={closeMenuHandler}
                                        active={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DISCLOSURE_DOCUMENT}`
                                            );
                                        }}
                                        toPath={`/dashboard/documents/${DISCLOSURE_DOCUMENT}/${LEASE_STATUS_DRAFT}`}
                                        icon={<HowToRegSharpIcon />}
                                        onCreateNew={
                                            isActiveUser() ? () => openDocumentCreationModal(DISCLOSURE_DOCUMENT) : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(DOCUMENT_CUSTOM) && (
                                <li>
                                    <LeftMenuLink
                                        label={FLK_A_PDF_DISPLAY}
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DOCUMENT_CUSTOM}`
                                            );
                                        }}
                                        icon={<PictureAsPdfSharp />}
                                        toPath={`/dashboard/documents/${DOCUMENT_CUSTOM}/${LEASE_STATUS_DRAFT}`}
                                        onCreateNew={
                                            isActiveUser() ? () => openDocumentCreationModal(DOCUMENT_CUSTOM) : null
                                        }
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(DOCUMENT_CREATE_A_FLK) && (
                                <li>
                                    <LeftMenuLink
                                        label={CREATE_A_FLK_DISPLAY}
                                        onClick={closeMenuHandler}
                                        active={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DOCUMENT_CREATE_A_FLK}`
                                            );
                                        }}
                                        icon={<DescriptionSharpIcon />}
                                        toPath={`/dashboard/documents/${DOCUMENT_CREATE_A_FLK}/${LEASE_STATUS_DRAFT}`}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openDocumentCreationModal(DOCUMENT_CREATE_A_FLK)
                                                : null
                                        }
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                )}
                {
                    <div className="section-wrapper">
                        <LeftMenuLink
                            label="FLK a Key"
                            onClick={closeMenuHandler}
                            isActive={(match, location) => {
                                return location.pathname.startsWith('/dashboard/flk-a-key');
                            }}
                            toPath={`/dashboard/flk-a-key/${FLK_A_KEY_STATUS_DRAFT}`}
                            icon={<KeyIcon />}
                            onCreateNew={isActiveUser() ? () => openFlkAKeyCreationModal() : null}
                        />
                    </div>
                }
                <hr className="menu-break" />
                <ul>
                    {isFlkReSidebarTemplateButtonActive && (
                        <li>
                            <LeftMenuLink
                                label={'Templates'}
                                onClick={closeMenuHandler}
                                isActive={(match, location) => {
                                    return templateRoutes.some(route => location.pathname.startsWith(route));
                                }}
                                icon={<LibraryBooksSharp />}
                                toPath={routes.ROUTE_AGREEMENTS_TEMPLATES}
                            />
                        </li>
                    )}
                    <li>
                        <LeftMenuLink
                            label={'Settings'}
                            onClick={closeMenuHandler}
                            isActive={(match, location) => {
                                return (
                                    location.pathname.startsWith(`/user`) &&
                                    !location.pathname.startsWith(routes.ROUTE_STATISTICS) &&
                                    !templateRoutes.some(route => location.pathname.startsWith(route))
                                );
                            }}
                            icon={<SettingsIcon />}
                            toPath={`/user`}
                        />
                    </li>
                    <li>
                        <LeftMenuLink
                            label="Statistics"
                            onClick={closeMenuHandler}
                            isActive={(match, location) => {
                                return location.pathname.startsWith(routes.ROUTE_STATISTICS);
                            }}
                            toPath={routes.ROUTE_STATISTICS}
                            icon={<BarChartSharp />}
                        />
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default memo(LeftMenu);
