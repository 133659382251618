import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getTemplateTypeForLeaseType,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales,
    isLeaseTypePm,
    mappingsToBackend,
    removeDagobahStates
} from '../../utils/agreementUtils';

import '../../sass/createNewLease.scss';
import '../../sass/_createNewLeaseMobile.scss';
import { debounce, get, includes } from 'lodash';
import {
    DEBOUNCE_TIME,
    getAvailableStates,
    isMobileWidth,
    NSW_STATE,
    SA_STATE,
    SUB_LEASE_TYPE_LONG_TERM_RENEWAL,
    SUB_LEASE_TYPE_SA_RENEWALS,
    DEFAULT_SUB_LEASE_TYPE,
    QLD_STATE,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    VIC_STATE
} from '../../config';
import { cloneResTenAgreement, createLeaseViaIntegration } from '../../actions/lease';
import { getTemplatesForSelect } from '../../actions/template';
import { isAddressDetailsTouched } from '../../utils/formUtils';

import {
    getUserAgency,
    getAgencyPropertyMeIntegration,
    getAgencyPropertyTreeIntegration,
    getAgencyManagedIntegration,
    getAgencyReapitConsoleIntegration
} from '../../selectors/agency';
import { getLeaseToClone, getMobileViewEnabled } from '../../selectors/dashboard';
import MobileView from './createNewLease/MobileView';
import DesktopView from './createNewLease/DesktopView';
import useQueryParams from '../../hooks/useQueryParams';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { DAGOBAH_QLD_RPMA, PM_SA } from '../../constants/featureFlags';
import { remove } from 'lodash';
import queryString from 'query-string';

function CreateNewLease({ leaseType, cloneLeaseId, createDocument, closeLeaseAgreementForm }) {
    const dispatch = useDispatch();
    const query = useQueryParams();
    const addressFromUrl = query.get('address');
    const isPmSaModuleActive = useFeatureFlag(PM_SA);
    const isDagobahPMQldActive = useFeatureFlag(DAGOBAH_QLD_RPMA);

    const agency = useSelector(getUserAgency);
    const leaseToClone = useSelector(getLeaseToClone);
    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);
    const managedIntegration = useSelector(getAgencyManagedIntegration);
    const reapitConsoleIntegration = useSelector(getAgencyReapitConsoleIntegration);
    const mobileViewEnabled = useSelector(getMobileViewEnabled);

    const [formData, setFormData] = useState({
        address: addressFromUrl,
        unit: '',
        street_number: '',
        route: '',
        locality: '',
        postal_code: '',
        country: '',
        administrative_area_level_1: '',
        isIntegrationSearch: true
    });
    const [addressSet, setAddressSet] = useState(false);
    const [showStates, setShowStates] = useState(false);
    const [selectedPropertyId, setSelectedPropertyId] = useState(null);
    const [creatingLease, setCreatingLease] = useState(false);
    const [enableChangeState, setEnableChangeState] = useState(true);
    const [enableChangeTemplate, setEnableChangeTemplate] = useState(true);
    const [enableChangeAddress, setEnableChangeAddress] = useState(true);
    const [activePropertyType, setActivePropertyType] = useState(null);
    const [stateLeaseType, setStateLeaseType] = useState(leaseType);

    const allowedStates = useMemo(() => {
        let availableStates = getAvailableStates(leaseType)

        availableStates = removeDagobahStates(availableStates, leaseType, {
            isDagobahPMQldActive
        })

        /**
         * Example of how to use states and feature flags
         */
        if (isLeaseTypePm(leaseType) && !isPmSaModuleActive) {
            // remove SA as it should ony show when the user has the module enabled
            remove(availableStates, value => value === SA_STATE);
        }

        // NSW is not available from the V1 creation modal for these lease types
        if (isLeaseTypeSales(leaseType) || isLeaseTypePm(leaseType)) {
            remove(availableStates, value => [NSW_STATE].includes(value));
        }

        return availableStates;
    }, [isPmSaModuleActive, leaseType]);

    const defaultLocation = useMemo(() => {
        let location;
        if (mobileViewEnabled && isMobileWidth()) {
            if (leaseType === LEASE_TYPE_PROPERTY_MANAGEMENT) {
                if ([SA_STATE, QLD_STATE, VIC_STATE].includes(agency.details.location)) {
                    location = agency.details.location;
                    setEnableChangeState(false);
                }
            }
        } else if (leaseType === SUB_LEASE_TYPE_SA_RENEWALS) {
            location = SA_STATE;
        } else {
            location = get(agency, 'details.location', NSW_STATE);
            if (allowedStates.length === 0) {
                return NSW_STATE;
            }
            if (!includes(allowedStates, location)) {
                return allowedStates?.[0]
            }
        }
        return location;
    }, [agency, allowedStates, leaseType, mobileViewEnabled]);

    useEffect(() => {
        if (getTemplateTypeForLeaseType(leaseType)) {
            dispatch(getTemplatesForSelect(getTemplateTypeForLeaseType(leaseType), defaultLocation));
        }
        if (cloneLeaseId) {
            setFormData({
                ...formData,
                //Address
                address: leaseToClone?.address ?? '',
                unit: leaseToClone.addressDetails?.unit ?? '',
                street_number: leaseToClone.addressDetails?.streetNumber ?? '',
                route: leaseToClone.addressDetails?.streetName ?? '',
                locality: leaseToClone.addressDetails?.locality ?? '',
                postal_code: leaseToClone.addressDetails?.postCode ?? '',
                administrative_area_level_1: leaseToClone.addressDetails?.state ?? '',
                country: leaseToClone.addressDetails?.country ?? '',
                isIntegrationSearch: false,

                //meta
                numberOfBedrooms: leaseToClone.resTenMeta?.numberOfBedrooms ?? 0,
                numberOfBathrooms: leaseToClone.resTenMeta?.numberOfBathrooms ?? 0,
                numberOfCarSpaces: leaseToClone.resTenMeta?.numberOfCarSpaces ?? 0,
                rentIncreaseAmount: leaseToClone.resTenMeta?.rentIncreaseAmount ?? 0,
                rentIncreaseAmountIndex: leaseToClone.resTenMeta?.rentIncreaseAmount ?? 1,
                isRenewal: leaseToClone.isRenewal,
                isRentIncrease: leaseToClone.resTenMeta?.isRentIncrease ?? false
            });
            setActivePropertyType(leaseToClone.propertyType);
            setStateLeaseType(leaseToClone.leaseType);
            setAddressSet(!!leaseToClone.address);
        } else {
            const isIntegrationSearch = !!(
                propertyMeIntegration ||
                propertyTreeIntegration ||
                managedIntegration ||
                reapitConsoleIntegration
            );
            setFormData({
                ...formData,
                location: defaultLocation,
                isIntegrationSearch,
                isUseTemplate: mobileViewEnabled ? undefined : false,
                subLeaseType:
                    leaseType === SUB_LEASE_TYPE_SA_RENEWALS ? SUB_LEASE_TYPE_LONG_TERM_RENEWAL : DEFAULT_SUB_LEASE_TYPE
            });
        }
    }, []);

    function setAddressDetails(address, details) {
        setAddressSet(true);
        setFormData({
            ...formData,
            ...details,
            location: formData.location || defaultLocation,
            address
        });
        setEnableChangeAddress(false);
    }

    const selectState = state => {
        setFormData(formData => ({
            ...formData,
            location: state,
            template: null
        }));
        setShowStates(false);
        setEnableChangeState(false);
        setEnableChangeTemplate(formData.isUseTemplate !== false);
        if (getTemplateTypeForLeaseType(leaseType)) {
            dispatch(getTemplatesForSelect(getTemplateTypeForLeaseType(leaseType), state));
        }
    };

    const selectUseTemplate = data => {
        setFormData({
            ...formData,
            isUseTemplate: data,
            template: data ? formData.template : null
        });
    };

    const submitForm = data => {
        const backEndLeaseType = leaseType ? mappingsToBackend[leaseType] : stateLeaseType;
        const newData = {
            ...data,
            propertyType: activePropertyType,
            rentIncreaseAmount: data.rentIncreaseAmount,
            leaseType: backEndLeaseType,
            urlParams: query.get('create') ? queryString.parse(query.toString()) : null
        };
        setCreatingLease(true);

        // if done via integration
        if (selectedPropertyId) {
            return dispatch(createLeaseViaIntegration(selectedPropertyId, newData)).finally(() => {
                setCreatingLease(false);
            });
        }
        // if residential tenancy
        if (isLeaseTypeResidentialTenancy(newData.leaseType)) {
            if (cloneLeaseId) {
                dispatch(cloneResTenAgreement(cloneLeaseId, newData));
            } else {
                createDocument(newData);
            }
        } else {
            createDocument(newData);
        }
    };

    function handleAddressChange(setValue, address) {
        setAddressSet(false);
        setValue('address', address);
    }

    // We have a debounced function here therefor we need to have useCallback
    // We don't want to recreate this function after each state change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeForm = useCallback(
        debounce(form => {
            if (isAddressDetailsTouched(form)) {
                let unitDisplay = '';
                if (form.values.subpremise) {
                    unitDisplay = `${form.values.subpremise}/`;
                }
                form.values.address = `${unitDisplay}${form.values.street_number} ${form.values.route}, ${form.values.locality} ${form.values.administrative_area_level_1}, ${form.values.postal_code}`;
            }
            setFormData(form.values);
        }, DEBOUNCE_TIME),
        []
    );

    return (
        <div className="container create-wrapper-container">
            <MobileView
                closeLeaseAgreementForm={closeLeaseAgreementForm}
                submitForm={submitForm}
                formData={formData}
                changeForm={changeForm}
                enableChangeState={enableChangeState}
                setEnableChangeState={setEnableChangeState}
                enableChangeTemplate={enableChangeTemplate}
                setEnableChangeTemplate={setEnableChangeTemplate}
                selectUseTemplate={selectUseTemplate}
                enableChangeAddress={enableChangeAddress}
                addressSet={addressSet}
                setEnableChangeAddress={setEnableChangeAddress}
                cloneLeaseId={cloneLeaseId}
                handleAddressChange={handleAddressChange}
                setAddressDetails={setAddressDetails}
                selectState={selectState}
            />
            <DesktopView
                closeLeaseAgreementForm={closeLeaseAgreementForm}
                submitForm={submitForm}
                changeForm={changeForm}
                formData={formData}
                setFormData={setFormData}
                showStates={showStates}
                setShowStates={setShowStates}
                selectState={selectState}
                selectedPropertyId={selectedPropertyId}
                setSelectedPropertyId={setSelectedPropertyId}
                leaseType={leaseType}
                cloneLeaseId={cloneLeaseId}
                handleAddressChange={handleAddressChange}
                setAddressDetails={setAddressDetails}
                allowedStates={allowedStates}
                setActivePropertyType={setActivePropertyType}
                activePropertyType={activePropertyType}
                creatingLease={creatingLease}
            />
        </div>
    );
}

export default CreateNewLease;
