import React from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import { useFieldArray } from 'react-final-form-arrays';
import { CustomPlaceholder } from '../../../types/UploadADoc';
import Listen from '../../dashboard/documents/buildADoc/components/Listen';
import {
    getAnnotationsForCustomPlaceholder,
    fitAnnotationToText,
    updateCustomPlaceholderAnnotationStyle
} from '../utils';
import { getCustomPlaceholderEmptyText } from '../../dashboard/documents/FlkAPdf/utils';

type CustomPlaceholderListenerProps = {
    webviewerInstances: WebViewerInstance[];
};
/**
 * Listens for updates in the custom placeholders fields and updates the annotation with the changes
 * @param webviewerInstances
 * @constructor
 */
const CustomPlaceholderListeners: React.FC<CustomPlaceholderListenerProps> = ({ webviewerInstances }) => {
    const customPlaceholdersField = useFieldArray<CustomPlaceholder>('customPlaceholders');

    return (
        <>
            {webviewerInstances.map(instance =>
                customPlaceholdersField.fields.map((name, index) => (
                    <Listen
                        activeOnly={false}
                        to={[`${name}.value`, `${name}.respondentType`]}
                        key={name}
                        onChange={(_, fieldName) => {
                            const customPlaceholder = customPlaceholdersField.fields.value[index];

                            const customPlaceholderAnnotations = getAnnotationsForCustomPlaceholder(
                                instance,
                                customPlaceholder.id
                            );

                            customPlaceholderAnnotations.forEach(annotation => {
                                if (annotation instanceof instance.Core.Annotations.FreeTextAnnotation) {
                                    if (fieldName === `${name}.respondentType`) {
                                        // If the respondent type has changed we want to update the annotation and reset its value to the empty text
                                        annotation.setCustomData(
                                            'customPlaceholderRespondentType',
                                            customPlaceholder.respondentType
                                        );

                                        annotation.setContents(
                                            getCustomPlaceholderEmptyText(customPlaceholder.respondentType)
                                        );
                                        updateCustomPlaceholderAnnotationStyle(annotation, false);
                                    } else if (fieldName === `${name}.value`) {
                                        // If the value has changed we want to update the annotation and set its value to the new value
                                        annotation.setContents(
                                            customPlaceholder.value ||
                                                getCustomPlaceholderEmptyText(customPlaceholder.respondentType)
                                        );
                                        updateCustomPlaceholderAnnotationStyle(annotation, !!customPlaceholder.value);
                                    }

                                    fitAnnotationToText(instance, annotation);
                                    instance.Core.annotationManager.redrawAnnotation(annotation);
                                }
                            });
                        }}
                    />
                ))
            )}
        </>
    );
};

export default CustomPlaceholderListeners;
