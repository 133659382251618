import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'react-cookie';
import {
    EXCHANGE_OF_CONTRACTS,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_SALES,
    NSW_STATE,
    QLD_STATE,
    SA_STATE,
    VIC_STATE
} from '../config';
import { getMobileViewEnabled } from '../selectors/dashboard';
import { Link } from 'react-router-dom';
import appHistory from './../AppHistory';
import { toggleMobileView } from '../actions/dashboard';
import Header from './Header';
import agreementIcon from '../../assets/images/icons/agreement-icon.svg?url';
import dashboardIcon from '../../assets/images/icons/dashboard-icon.svg?url';
import { useAccountProvider } from '../components/providers/AccountProvider';
import { isAgencyUserAccount } from '../utils/userUtils';
import '../sass/layout.scss';
import '../sass/_welcome.scss';
import { getUserInfo } from '../actions/user';
import { getAgencyDefaultLocation } from '../selectors/user';
import useOpenCreationModal from '../hooks/useOpenCreationModal';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { EXCHANGE_OF_CONTRACTS_V1, NSW_SALES_RESPONSIVE, EOC_MOBILE_RESPONSIVE } from '@app/constants/featureFlags';

const InnerWelcomeNavItem = ({ label, icon }) => {
    return (
        <div className="welcome__nav__inner">
            <div className="welcome__nav__icon">{icon}</div>
            <div className="welcome__nav__label">
                <label>{label}</label>
            </div>
        </div>
    );
};

const WelcomeNavItem = ({ to, onClick, active = false, label, icon }) => {
    const className = cx('welcome__nav__item', {
        'welcome__nav__item--active': active,
        'welcome__nav__item--button': !!onClick
    });

    if (to) {
        return (
            <Link to={to}>
                <div className={className}>
                    <InnerWelcomeNavItem label={label} icon={icon} />
                </div>
            </Link>
        );
    }

    return (
        <div className={className} onClick={onClick}>
            <InnerWelcomeNavItem label={label} icon={icon} />
        </div>
    );
};

const Welcome = () => {
    const dispatch = useDispatch();
    const mobileViewEnabled = useSelector(getMobileViewEnabled);
    const [isCreatePmAgreement, setIsCreatePmAgreement] = useState(false);
    const [isCreateSalesAgreement, setIsCreateSalesAgreement] = useState(false);
    const accountType = useAccountProvider();
    const agencyDefaultLocation = useSelector(getAgencyDefaultLocation);
    const isNswSalesResponsiveEnabled = useFeatureFlag(NSW_SALES_RESPONSIVE);
    const isEocEnabled = useFeatureFlag(EXCHANGE_OF_CONTRACTS_V1);
    const isEocMobileResponsiveEnabled = useFeatureFlag(EOC_MOBILE_RESPONSIVE);

    const { openLeaseCreationModalOrSubapp, openDocumentCreationModal } = useOpenCreationModal();

    useEffect(() => {
        document.title = 'Welcome';
        if (!cookie.load('userFromLogin')) {
            appHistory.push('/');
        }
        dispatch(getUserInfo());
    }, [dispatch]);

    const createPmAgreement = () => {
        setIsCreatePmAgreement(true);
        openLeaseCreationModalOrSubapp(LEASE_TYPE_PROPERTY_MANAGEMENT, agencyDefaultLocation);
    };

    const createSalesAgreement = () => {
        setIsCreateSalesAgreement(true);
        openLeaseCreationModalOrSubapp(LEASE_TYPE_SALES, agencyDefaultLocation);
    };

    const canCreatePmAgreement =
        isAgencyUserAccount(accountType) && [NSW_STATE, QLD_STATE, VIC_STATE, SA_STATE].includes(agencyDefaultLocation);

    const canCreateSalesAgreement =
        isAgencyUserAccount(accountType) && agencyDefaultLocation === NSW_STATE && isNswSalesResponsiveEnabled;

    const canCreateEoc =
        isAgencyUserAccount(accountType) &&
        agencyDefaultLocation === NSW_STATE &&
        isEocEnabled &&
        isEocMobileResponsiveEnabled;

    const toggleView = data => {
        dispatch(toggleMobileView(data));
        appHistory.push('/dashboard/agreements/residential_tenancy/draft');
    };

    return (
        <div className="mobile-view-enabled layout">
            <Header />
            <main className="main">
                <div className="main-content">
                    <div className="content">
                        <div className="welcome">
                            <div className="welcome__header">
                                <h1>Welcome to FLK mobile</h1>
                            </div>
                            <div className="welcome__body welcome__nav">
                                <div className="welcome__nav__subtitle">Create New using Templates</div>
                                <WelcomeNavItem
                                    to="/dashboard/documents/document_custom/draft?create=document_custom"
                                    label="Upload a Doc"
                                    icon={<img src={agreementIcon} alt="" />}
                                />
                                {canCreatePmAgreement && (
                                    <WelcomeNavItem
                                        onClick={createPmAgreement}
                                        active={isCreatePmAgreement}
                                        label="Property Management Agreement"
                                        icon={<img src={agreementIcon} alt="Property Management Agreement Icon" />}
                                    />
                                )}
                                {canCreateSalesAgreement && (
                                    <WelcomeNavItem
                                        onClick={createSalesAgreement}
                                        active={isCreateSalesAgreement}
                                        label="Sales Agreement"
                                        icon={<img src={agreementIcon} alt="Sales Agreement Icon" />}
                                    />
                                )}
                                {canCreateEoc && (
                                    <WelcomeNavItem
                                        onClick={() => openDocumentCreationModal(EXCHANGE_OF_CONTRACTS)}
                                        active={isCreateSalesAgreement}
                                        label="Exchange of Contracts"
                                        icon={<img src={agreementIcon} alt="Sales Agreement Icon" />}
                                    />
                                )}
                                <div className="welcome__nav__subtitle">Go to Dashboard</div>
                                <WelcomeNavItem
                                    to="/dashboard/documents/document_custom/draft"
                                    label="Upload a Doc"
                                    icon={<img src={dashboardIcon} alt="" />}
                                />
                                {canCreatePmAgreement && (
                                    <WelcomeNavItem
                                        to="/dashboard/agreements/property_management/draft"
                                        label="Property Management Agreement"
                                        icon={<img src={dashboardIcon} alt="Property Management Dashboard Icon" />}
                                    />
                                )}
                                {canCreateSalesAgreement && (
                                    <WelcomeNavItem
                                        to="/dashboard/agreements/sales/draft"
                                        label="Sales Agreement"
                                        icon={<img src={dashboardIcon} alt="Sales Dashboard Icon" />}
                                    />
                                )}
                                {canCreateEoc && (
                                    <WelcomeNavItem
                                        to="/dashboard/documents/exchange_of_contracts/draft"
                                        label="Exchange of Contracts"
                                        icon={<img src={dashboardIcon} alt="Sales Dashboard Icon" />}
                                    />
                                )}
                            </div>
                            <div className="welcome__footer">
                                {mobileViewEnabled && (
                                    <div className="welcome__footer--link" onClick={() => toggleView(false)}>
                                        View desktop experience
                                        <br />
                                        for more options
                                    </div>
                                )}
                                {!mobileViewEnabled && (
                                    <div className="welcome__footer--link" onClick={() => toggleView(true)}>
                                        Show mobile experience
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Welcome;
