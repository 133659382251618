import React from 'react';
import cx from 'classnames';
import { WebViewerInstance } from '@pdftron/webviewer';

import { CustomPlaceholder } from '../../../../types/UploadADoc';
import { Point } from '../../types';
import { AddPlaceholder } from './AddPlaceholder';
import { AnnotationButtons } from './AnnotationButtons';

import styles from './CustomPlaceholderAccordionContent.module.scss';
import { AddPlaceholderButton } from './AddPlaceholderButton';

type CustomPlaceholderAccordionContentProps = {
    customPlaceholderData?: CustomPlaceholder[];
    webViewerInstance?: WebViewerInstance;
    dropPointRef: React.MutableRefObject<Point | undefined>;
    hideCustomPlaceholderTitles: boolean;
    toggleHideCustomPlaceholderTitles: () => void;
    hoveredCustomPlaceholderId?: string;
    setHoveredCustomPlaceholderId: (customPlaceholderId?: string) => void;
    fontSize?: number;
};

const CustomPlaceholderAccordionContent: React.FC<CustomPlaceholderAccordionContentProps> = ({
    customPlaceholderData,
    webViewerInstance,
    dropPointRef,
    hideCustomPlaceholderTitles,
    toggleHideCustomPlaceholderTitles,
    setHoveredCustomPlaceholderId,
    fontSize
}) => {
    const [isAdding, setIsAdding] = React.useState(false);
    const containerRef = React.useRef<HTMLDivElement>(null);

    return (
        <div className={cx(styles.container, { [styles.addPlaceholderContainer]: isAdding })} ref={containerRef}>
            {isAdding ? (
                <AddPlaceholder
                    close={addedPlaceholder => {
                        setIsAdding(false);
                        if (addedPlaceholder) {
                            setTimeout(() => {
                                containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
                            }, 200);
                        }
                    }}
                />
            ) : customPlaceholderData?.length ? (
                <AnnotationButtons
                    customPlaceholderData={customPlaceholderData}
                    webViewerInstance={webViewerInstance}
                    dropPointRef={dropPointRef}
                    handleAddCustomPlaceholder={() => setIsAdding(true)}
                    hideCustomPlaceholderTitles={hideCustomPlaceholderTitles}
                    toggleHideCustomPlaceholderTitles={toggleHideCustomPlaceholderTitles}
                    setHoveredCustomPlaceholderId={setHoveredCustomPlaceholderId}
                    fontSize={fontSize}
                />
            ) : (
                <AddPlaceholderButton
                    onClick={() => setIsAdding(true)}
                    className={styles.addPlaceholderButton}
                    text="Add your first placeholder"
                />
            )}
        </div>
    );
};

export default CustomPlaceholderAccordionContent;
