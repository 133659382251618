import React from 'react';
import cx from 'classnames';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { useForm } from 'react-final-form';
import { confirmAlert } from 'react-confirm-alert';
import { capitalize } from 'lodash';

import Icon, { Icons, IconSize } from '../../../../common/components/Icon';
import FormDropdown, { DropdownTriggerSize, DropdownTriggerVariant } from '../../../../components/form/FormDropdown';
import { CustomPlaceholderRespondentType } from '../../../../types/UploadADoc';
import { MULTIPLE_RECIPIENTS_CLIENT_PLACEHOLDERS } from '../../../../constants/featureFlags';
import ConfirmAlert from './ConfirmAlert';

import styles from './CustomPlaceholderTypeDropdown.module.scss';
import Listen from '../buildADoc/components/Listen';

type CustomPlaceholderRespondentTypeDropdownProps = {
    customPlaceholderName: string;
    customPlaceholderRespondentType: CustomPlaceholderRespondentType;
    disabled?: boolean;
    isSignerPlaceholderOptionDisabled: boolean;
    className?: string;
    hideTriggerIcon?: boolean;
    dropdownTriggerSize?: DropdownTriggerSize;
    index: number;
    recipientLabelLowercase?: string;
};

const CustomPlaceholderTypeDropdown: React.FC<CustomPlaceholderRespondentTypeDropdownProps> = ({
    customPlaceholderName,
    customPlaceholderRespondentType,
    disabled,
    isSignerPlaceholderOptionDisabled,
    className,
    hideTriggerIcon,
    dropdownTriggerSize,
    index,
    recipientLabelLowercase = 'recipient'
}) => {
    const isMultipleRecipientsClientPlaceholdersEnabled = useFeatureFlag(MULTIPLE_RECIPIENTS_CLIENT_PLACEHOLDERS);

    const form = useForm();
    const values = form.getState().values;

    const recipientLabelTitlecase = capitalize(recipientLabelLowercase);
    const firstClientName = values.clients?.[0]?.name || values.purchasers?.[0]?.name;

    const onChangeRespondentType = (value?: unknown) => {
        if (
            values.clients?.length > 1 &&
            !values.enforceSequentialSigning &&
            value === CustomPlaceholderRespondentType.CLIENT
        ) {
            form.change(`${customPlaceholderName}.respondentType`, CustomPlaceholderRespondentType.SENDER);
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ConfirmAlert
                            title="Signing Order will be enabled"
                            message={
                                <p>
                                    <strong>{firstClientName || `${recipientLabelLowercase} 1`}</strong> will be
                                    required to fill in the assigned <strong>Custom Info Placeholders</strong> when the
                                    document is sent for signing.
                                </p>
                            }
                            confirmText={`Yes, Assign to ${firstClientName || `${recipientLabelTitlecase} 1`}`}
                            cancelText="Cancel"
                            onConfirm={() => {
                                form.change('enforceSequentialSigning', true);
                                form.change(
                                    `${customPlaceholderName}.respondentType`,
                                    CustomPlaceholderRespondentType.CLIENT
                                );
                                onClose();
                            }}
                            onCancel={onClose}
                            onClose={onClose}
                        />
                    );
                }
            });
        }
    };

    return (
        <>
            <FormDropdown
                className={cx(styles.dropdown, className, { [styles.disabled]: disabled })}
                triggerClassName={styles.trigger}
                optionsClassName={styles.options}
                name={`${customPlaceholderName}.respondentType`}
                disabled={disabled}
                ariaLabel="Choose who answers"
                contentHeading="Choose who answers:"
                options={[
                    {
                        label: 'Me (the sender)',
                        value: CustomPlaceholderRespondentType.SENDER
                    },
                    {
                        label: isMultipleRecipientsClientPlaceholdersEnabled
                            ? `${recipientLabelTitlecase} 1${firstClientName ? ` (${firstClientName})` : ''}`
                            : recipientLabelTitlecase,
                        value: CustomPlaceholderRespondentType.CLIENT,
                        disabled: !isMultipleRecipientsClientPlaceholdersEnabled && isSignerPlaceholderOptionDisabled,
                        disabledMessage: 'This feature currently only works with one recipient per document.'
                    }
                ]}
                placeholder={
                    <Icon
                        icon={Icons.USER}
                        className={cx(styles.icon, {
                            [styles.signerIcon]:
                                customPlaceholderRespondentType === CustomPlaceholderRespondentType.CLIENT
                        })}
                        size={IconSize.SMALL}
                    />
                }
                alwaysShowPlaceholder
                triggerVariant={DropdownTriggerVariant.FLAT}
                triggerIconSize={IconSize.SMALL}
                showTriggerIcon={!hideTriggerIcon}
                triggerSize={dropdownTriggerSize}
                dataTest={`customPlaceholders[${index}].respondentType`}
            />
            <Listen to={`${customPlaceholderName}.respondentType`} onChange={onChangeRespondentType} />
        </>
    );
};

export default CustomPlaceholderTypeDropdown;
