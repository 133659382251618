import { Core, WebViewerInstance } from '@pdftron/webviewer';
import axios from 'axios';

import { FLK_A_PDF_GLOBAL_TEMPLATE, FLK_A_PDF_TEMPLATE, DOCUMENT_CUSTOM, EXCHANGE_OF_CONTRACTS } from '../../config';
import { ANNOTATION_FREE_TEXT_DEFAULT_VALUE, SIGN_CONFIRMATION_TYPE } from '../../constants/constants';
import { generateRandomId } from '../../utils/generalUtils.js';
import {
    AgentNameSubjectType,
    AgentSignatureSubjectType,
    AnnotationAction,
    ClientAnnotation,
    ClientAnnotationCounts,
    ClientDateAnnotation,
    ClientDateSubjectType,
    ClientNameSubjectType,
    ClientSignatureAnnotation,
    ClientSignatureSubjectType,
    CompletionDateSubjectType,
    CreateAnnotationOptions,
    CustomPlaceholderAnnotation,
    CustomPlaceholderSubjectType,
    FreeTextSubjectType,
    Point,
    SenderNameSubjectType,
    WitnessAnnotation,
    WitnessNameAnnotation,
    WitnessNameSubjectType,
    WitnessSignatureAnnotation,
    WitnessSignatureSubjectType,
    isAgentNameAnnotation,
    isAgentSignatureAnnotation,
    isClientDateAnnotation,
    isClientNameAnnotation,
    isClientSignatureAnnotation,
    isCompletionDateAnnotation,
    isCustomPlaceholderAnnotation,
    isFreeTextAnnotation,
    isSenderNameAnnotation,
    isWitnessAnnotation,
    isWitnessNameAnnotation
} from './types';

import { FormApi } from 'final-form';
import { capitalize, keyBy } from 'lodash';
import cloneAnnotationActiveIcon from '../../../assets/images/icons/pdfViewer/cloneAnnotationActiveIcon.svg?url';
import cloneAnnotationIcon from '../../../assets/images/icons/pdfViewer/cloneAnnotationIcon.svg?url';
import deleteAnnotationIcon from '../../../assets/images/icons/pdfViewer/deleteAnnotationIcon.svg?url';
import styleAnnotationIcon from '../../../assets/images/icons/pdfViewer/styleAnnotationIcon.svg?url';
import { ToastTypes } from '../../common/components/Toast';
import variables from '../../sass/variables.scss';
import {
    CustomPlaceholder,
    CustomPlaceholderRespondentType,
    isUploadedDocument,
    UploadedDocument,
    UploadedFile
} from '../../types/UploadADoc';
import { UploadedDocumentState } from './components/useUploadedDocumentState';

import clientOnePlaceHolderImage from '../../../assets/images/pdf-viewer/Client-1.png';
import clientTwoPlaceHolderImage from '../../../assets/images/pdf-viewer/Client-2.png';
import clientThreePlaceHolderImage from '../../../assets/images/pdf-viewer/Client-3.png';
import clientFourPlaceHolderImage from '../../../assets/images/pdf-viewer/Client-4.png';

// Note that these images are actually transparent, but we cannot change the image path because it is referred to in existing documents
import clientOneImage from '../../../assets/images/pdf-viewer/Signer 1-solid@4x.png';
import clientTwoImage from '../../../assets/images/pdf-viewer/Signer 2-solid@4x.png';
import clientThreeImage from '../../../assets/images/pdf-viewer/Signer 3-solid@4x.png';
import clientFourImage from '../../../assets/images/pdf-viewer/Signer 4-solid@4x.png';
import clientFiveImage from '../../../assets/images/pdf-viewer/Signer 5@4x.png';
import clientSixImage from '../../../assets/images/pdf-viewer/Signer 6@4x.png';
import clientSevenImage from '../../../assets/images/pdf-viewer/Signer 7@4x.png';
import clientEightImage from '../../../assets/images/pdf-viewer/Signer 8@4x.png';

import witnessOneImage from '../../../assets/images/pdf-viewer/WitnessSigner 1@4x.png';
import witnessTwoImage from '../../../assets/images/pdf-viewer/WitnessSigner 2@4x.png';
import witnessThreeImage from '../../../assets/images/pdf-viewer/WitnessSigner 3@4x.png';
import witnessFourImage from '../../../assets/images/pdf-viewer/WitnessSigner 4@2x.png';
import witnessFiveImage from '../../../assets/images/pdf-viewer/WitnessSigner 5@4x.png';
import witnessSixImage from '../../../assets/images/pdf-viewer/WitnessSigner 6@4x.png';
import witnessSevenImage from '../../../assets/images/pdf-viewer/WitnessSigner 7@4x.png';
import witnessEightImage from '../../../assets/images/pdf-viewer/WitnessSigner 8@4x.png';

import agentImageHashed from '../../../assets/images/pdf-viewer/Agent.png';
import senderImageHashed from '../../../assets/images/pdf-viewer/Sender signature@4x.png';
import clientOneSolidButtonImageHashed from '../../../assets/images/pdf-viewer/Signer 1-solid@4x.png?hashed';
import clientTwoSolidButtonImageHashed from '../../../assets/images/pdf-viewer/Signer 2-solid@4x.png?hashed';
import clientThreeSolidButtonImageHashed from '../../../assets/images/pdf-viewer/Signer 3-solid@4x.png?hashed';
import clientFourSolidButtonImageHashed from '../../../assets/images/pdf-viewer/Signer 4-solid@4x.png?hashed';
import witnessOneSolidButtonImageHashed from '../../../assets/images/pdf-viewer/WitnessSigner 1@4x.png?hashed';
import witnessTwoSolidButtonImageHashed from '../../../assets/images/pdf-viewer/WitnessSigner 2@4x.png?hashed';
import witnessThreeSolidButtonImageHashed from '../../../assets/images/pdf-viewer/WitnessSigner 3@4x.png?hashed';
import witnessFourSolidButtonImageHashed from '../../../assets/images/pdf-viewer/WitnessSigner 4@2x.png?hashed';
import { getCustomPlaceholderEmptyText } from '../dashboard/documents/FlkAPdf/utils';

/**
 * DONT DELETE THESE THESE ARE USED IN OLD TEMPLATES>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OLD_SIGNATURE_IMAGES = [
    clientOnePlaceHolderImage,
    clientTwoPlaceHolderImage,
    clientThreePlaceHolderImage,
    clientFourPlaceHolderImage
];

/**
 * Do not delete. Required for templates created from Nov 2024 - Jan 2025
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HASHED_SIGNATURE_IMAGES = [
    agentImageHashed,
    senderImageHashed,
    clientOneSolidButtonImageHashed,
    clientTwoSolidButtonImageHashed,
    clientThreeSolidButtonImageHashed,
    clientFourSolidButtonImageHashed,
    witnessOneSolidButtonImageHashed,
    witnessTwoSolidButtonImageHashed,
    witnessThreeSolidButtonImageHashed,
    witnessFourSolidButtonImageHashed
];

export const SIGNATURE_IMAGES = [
    clientOneImage,
    clientTwoImage,
    clientThreeImage,
    clientFourImage,
    clientFiveImage,
    clientSixImage,
    clientSevenImage,
    clientEightImage
];

export const WITNESS_SIGNATURE_IMAGES = [
    witnessOneImage,
    witnessTwoImage,
    witnessThreeImage,
    witnessFourImage,
    witnessFiveImage,
    witnessSixImage,
    witnessSevenImage,
    witnessEightImage
];

export const getWitnessNameAnnotationText = (clientIndex: number) => `Signer ${clientIndex + 1} Witness Name`;
export const getClientDateAnnotationText = (clientIndex: number) => `Signer ${clientIndex + 1} Signed Date`;

const ONBOARDING_API_URL = 'api/onboarding/custom-document';
const CLONE_ID_PREFIX = 'cloneGroup';
// The text in a free text annotation is not centered vertically, so we use this offset to align it with center point from the drag image
const FREE_TEXT_VERTICAL_OFFSET = 3;
export const ANNOTATION_PADDING = 8;

type AnnotationType = Core.Annotations.Annotation;
type AnnotationManagerType = Core.AnnotationManager;

type AnnotationColoursType = {
    selectionStroke: string;
    selectionFill: string;
    controlStroke: string;
    controlFill: string;
};

const AnnotationColours: { [key: string]: AnnotationColoursType } = {
    CLIENT_0: {
        selectionStroke: variables.orange400,
        selectionFill: `${variables.orange400}10`,
        controlStroke: variables.orange400,
        controlFill: variables.orange50
    },
    CLIENT_1: {
        selectionStroke: variables.yellow600,
        selectionFill: `${variables.yellow600}10`,
        controlStroke: variables.yellow600,
        controlFill: variables.yellow50
    },
    CLIENT_2: {
        selectionStroke: variables.blue400,
        selectionFill: `${variables.blue400}10`,
        controlStroke: variables.blue400,
        controlFill: variables.blue100
    },
    CLIENT_3: {
        selectionStroke: variables.red200,
        selectionFill: `${variables.red200}10`,
        controlStroke: variables.red200,
        controlFill: variables.red50
    },
    CLIENT_4: {
        selectionStroke: variables.pink400,
        selectionFill: `${variables.pink400}10`,
        controlStroke: variables.pink400,
        controlFill: variables.pink50
    },
    CLIENT_5: {
        selectionStroke: variables.mint400,
        selectionFill: `${variables.mint400}10`,
        controlStroke: variables.mint400,
        controlFill: variables.mint50
    },
    CLIENT_6: {
        selectionStroke: variables.aqua400,
        selectionFill: `${variables.aqua400}10`,
        controlStroke: variables.aqua400,
        controlFill: variables.aqua50
    },
    CLIENT_7: {
        selectionStroke: variables.midVibrantPurple,
        selectionFill: `${variables.midVibrantPurple}10`,
        controlStroke: variables.midVibrantPurple,
        controlFill: variables.purple50
    },
    SENDER: {
        selectionStroke: variables.green200,
        selectionFill: `${variables.green200}10`,
        controlStroke: variables.green200,
        controlFill: variables.green50
    },
    DEFAULT: {
        selectionStroke: variables.blue400,
        selectionFill: `${variables.blue400}10`,
        controlStroke: variables.blue400,
        controlFill: variables.blue100
    }
};

const getAnnotationColours = (annotation: Core.Annotations.Annotation): AnnotationColoursType => {
    if (isClientAnnotation(annotation)) {
        const clientIndex = annotation.Subject.split('-').slice(-1)[0];
        switch (clientIndex) {
            case '0':
                return AnnotationColours.CLIENT_0;
            case '1':
                return AnnotationColours.CLIENT_1;
            case '2':
                return AnnotationColours.CLIENT_2;
            case '3':
                return AnnotationColours.CLIENT_3;
            case '4':
                return AnnotationColours.CLIENT_4;
            case '5':
                return AnnotationColours.CLIENT_5;
            case '6':
                return AnnotationColours.CLIENT_6;
            case '7':
                return AnnotationColours.CLIENT_7;
        }
    } else if (
        isSenderNameAnnotation(annotation) ||
        isAgentNameAnnotation(annotation) ||
        isAgentSignatureAnnotation(annotation) ||
        isCompletionDateAnnotation(annotation)
    ) {
        return AnnotationColours.SENDER;
    } else if (
        isCustomPlaceholderAnnotation(annotation) &&
        annotation.getCustomData('customPlaceholderRespondentType') === CustomPlaceholderRespondentType.CLIENT
    ) {
        return AnnotationColours.CLIENT_0;
    }

    return AnnotationColours.DEFAULT;
};

const getAnnotationDisplayName = (annotation: AnnotationType) => {
    // Note that this will also include date annotations until they have a unique subject
    if (isFreeTextAnnotation(annotation)) {
        return 'free text';
    } else if (isCustomPlaceholderAnnotation(annotation)) {
        return 'Custom info placeholder';
    } else if (isClientSignatureAnnotation(annotation) || isAgentSignatureAnnotation(annotation)) {
        return 'signature';
    } else if (isAgentNameAnnotation(annotation)) {
        return 'agent name';
    } else if (isSenderNameAnnotation(annotation)) {
        return 'sender name';
    } else if (isCompletionDateAnnotation(annotation)) {
        return 'completion date';
    } else if (isClientDateAnnotation(annotation)) {
        return 'date';
    } else if (isClientNameAnnotation(annotation)) {
        return 'name';
    } else if (isWitnessNameAnnotation(annotation)) {
        return 'witness name';
    } else if (isWitnessAnnotation(annotation)) {
        return 'witness signature';
    } else {
        return 'annotation';
    }
};

const isAnnotationInPageBounds = async (
    instance: WebViewerInstance,
    annotation: AnnotationType,
    page: Core.PDFNet.Page
) => {
    const annotationRect = annotation.getRect();
    const pageRect = new instance.Core.Math.Rect(0, 0, await page.getPageWidth(), await page.getPageHeight());

    return pageRect.contains(annotationRect);
};

const PAGE_EDGE_PADDING = 8;
const moveAnnotationToBottomCenter = async (annotation: AnnotationType, page: Core.PDFNet.Page) => {
    const pageRect = await page.getCropBox();
    const pageWidth = await pageRect.width();
    const pageHeight = await pageRect.height();

    const annotationRect = annotation.getRect();
    const annotationWidth = annotationRect.getWidth();
    const annotationHeight = annotationRect.getHeight();

    const pageRotationDegrees = (await page.getRotation()) * 90;

    if (pageRotationDegrees === 0) {
        annotation.X = (pageWidth - annotationWidth) / 2;
        annotation.Y = pageHeight - annotationHeight - PAGE_EDGE_PADDING;
    } else if (pageRotationDegrees === 90) {
        annotation.X = pageWidth - annotationWidth - PAGE_EDGE_PADDING;
        annotation.Y = (pageHeight - annotationHeight) / 2;
    } else if (pageRotationDegrees === 180) {
        annotation.X = (pageWidth - annotationWidth) / 2;
        annotation.Y = PAGE_EDGE_PADDING;
    } else if (pageRotationDegrees === 270) {
        annotation.X = PAGE_EDGE_PADDING;
        annotation.Y = (pageHeight - annotationHeight) / 2;
    }
};

// Check whether an annotation is in any clone group
export const isCloneGroupAnnotation = (annotation: AnnotationType, annotationManager: AnnotationManagerType) => {
    return (
        !!annotation.getCustomData('cloneGroup') &&
        annotationManager
            .getAnnotationsList()
            .some(
                annot =>
                    annot.getCustomData('cloneGroup') === annotation.getCustomData('cloneGroup') && annot !== annotation
            )
    );
};

// Rotates an annotation from oldRotationDegrees to newRotationDegrees.
// This function is used to maintain visual consistency when cloning an annotation across
// pages with different rotation values.
const updateAnnotationRotation = async (
    annotation: AnnotationType,
    oldRotationDegrees: number,
    newRotationDegrees: number
) => {
    const relativeRotationDegrees = getAngleDifferenceInDegrees(oldRotationDegrees, newRotationDegrees);

    // We need to do this to make sure the annotation has the correct dimensions for its new rotated orientation
    if (relativeRotationDegrees % 180 !== 0) {
        const { Width, Height } = annotation;
        annotation.Width = Height;
        annotation.Height = Width;
    }
    annotation.Rotation = newRotationDegrees;
};

// Returns the difference between degrees in the range [0, 360)
const getAngleDifferenceInDegrees = (angle: number, otherAngle: number) => {
    return (angle - otherAngle + 360) % 360;
};

const cloneAnnotation = async (
    annotation: AnnotationType,
    instance: WebViewerInstance,
    onCloneComplete: () => void,
    onAutoPosition: () => void
) => {
    const annotationManager = instance.Core.annotationManager;
    const document = instance.Core.documentViewer.getDocument();
    const pdfDoc = await document.getPDFDoc();
    const groupId = `${CLONE_ID_PREFIX}_${generateRandomId()}`;
    const pageIterator = await pdfDoc.getPageIterator(1);
    const cloneAnnotations: AnnotationType[] = [];
    let isGroupMemberAutoPositioned = false;

    const originalPage = await pdfDoc.getPage(annotation.PageNumber);
    const originalPageRotationDegrees = (await originalPage.getRotation()) * 90;

    while (await pageIterator.hasNext()) {
        const page = await pageIterator.current();
        const pageNumber = await page.getIndex();

        if (pageNumber !== annotation.PageNumber) {
            const annotationCopy = instance.Core.annotationManager.getAnnotationCopy(annotation, {
                copyAssociatedLink: false
            });

            annotationCopy.setCustomData('cloneGroup', groupId);
            annotationCopy.setPageNumber(pageNumber);

            const pageRotationDegrees = (await page.getRotation()) * 90;

            if (
                pageRotationDegrees !== originalPageRotationDegrees ||
                !(await isAnnotationInPageBounds(instance, annotation, page))
            ) {
                if (pageRotationDegrees !== originalPageRotationDegrees) {
                    updateAnnotationRotation(annotationCopy, originalPageRotationDegrees, pageRotationDegrees);
                }
                await moveAnnotationToBottomCenter(annotationCopy, page);

                isGroupMemberAutoPositioned = true;
            }
            if (annotationCopy instanceof instance.Core.Annotations.FreeTextAnnotation) {
                maintainFreeTextAnnotationSize(annotationCopy);
                annotationCopy.IsHoverable = true;
            }

            cloneAnnotations.push(annotationCopy);
        }
        await pageIterator.next();
    }

    annotation.setCustomData('cloneGroup', groupId);
    annotationManager.addAnnotations(cloneAnnotations);
    annotationManager.drawAnnotationsFromList(cloneAnnotations);
    onCloneComplete();

    if (isGroupMemberAutoPositioned) {
        onAutoPosition();
    }
};

const removeClones = (
    annotation: AnnotationType,
    annotationManager: AnnotationManagerType,
    onRemoveComplete: () => void
) => {
    const cloneGroup = annotation.getCustomData('cloneGroup');
    const annotations = annotationManager.getAnnotationsList();

    annotationManager.deleteAnnotations(
        annotations.filter(annot => annot.getCustomData('cloneGroup') === cloneGroup && annot !== annotation)
    );

    onRemoveComplete();
};
export function updateSelectionBoxPadding(instance: WebViewerInstance) {
    const { Annotations, Math } = instance.Core;
    // @ts-expect-error setCustomHandlers does not correctly define the type for selectionModel parameter.
    Annotations.SelectionModel.setCustomHandlers(Annotations.FreeTextSelectionModel, {
        getDimensions: (annotation: Core.Annotations.FreeTextAnnotation) => {
            const x = annotation.X - ANNOTATION_PADDING;
            const y = annotation.Y - ANNOTATION_PADDING;
            const width = annotation.Width + ANNOTATION_PADDING * 2;
            const height = annotation.Height + ANNOTATION_PADDING * 2;

            return new Math.Rect(x, y, x + width, y + height);
        }
    });
}

export const updateControlHandles = (instance: WebViewerInstance) => {
    const { Annotations } = instance.Core;

    Annotations.ControlHandle.handleWidth = 8;
    Annotations.ControlHandle.handleHeight = 8;

    Annotations.ControlHandle.prototype.draw = function (ctx, annotation, selectionBox, zoom) {
        // @ts-expect-error this.getDimensions is defined
        const rect: { x1: number; x2: number; y1: number; y2: number } = this.getDimensions(
            annotation,
            selectionBox,
            zoom
        );
        const { controlStroke, controlFill } = getAnnotationColours(annotation);
        ctx.strokeStyle = controlStroke;
        ctx.fillStyle = controlFill;
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.arc(
            (rect.x1 + rect.x2) / 2,
            (rect.y1 + rect.y2) / 2,
            Annotations.ControlHandle.handleHeight / 2,
            0,
            2 * Math.PI
        );
        ctx.stroke();
        ctx.fill();
    };
};

export const isResizableFreeTextAnnotation = (
    annotation: Core.Annotations.FreeTextAnnotation,
    allowSenderPlaceholderResizing?: boolean
) => {
    return (
        (!annotation.Locked && !annotation.LockedContents && isFreeTextAnnotation(annotation)) ||
        isResizableCustomPlaceholderAnnotation(annotation, allowSenderPlaceholderResizing)
    );
};

export const isResizableCustomPlaceholderAnnotation = (
    annotation: Core.Annotations.FreeTextAnnotation,
    allowSenderPlaceholderResizing?: boolean
) => {
    return allowSenderPlaceholderResizing
        ? isCustomPlaceholderAnnotation(annotation) &&
              annotation.getCustomData('customPlaceholderRespondentType') === CustomPlaceholderRespondentType.SENDER
        : false;
};

export const updateFreeTextSelectionBox = (
    instance: WebViewerInstance,
    freeTextAnnotations: Core.Annotations.FreeTextAnnotation[],
    options?: { onlyAllowFreeTextResizing?: boolean; allowSenderPlaceholderResizing?: boolean }
) => {
    const { Annotations } = instance.Core;
    class CustomSelectionModel extends Annotations.FreeTextSelectionModel {
        constructor(
            annotation: Core.Annotations.FreeTextAnnotation,
            canModify: boolean,
            isSelected: boolean,
            docViewer: Core.DocumentViewer
        ) {
            // @ts-expect-error Type definition for super is incorrect - it should also include the document viewer
            super(annotation, canModify, isSelected, docViewer);
            if (canModify) {
                const shouldAllowResize = options?.onlyAllowFreeTextResizing
                    ? isResizableFreeTextAnnotation(annotation, options?.allowSenderPlaceholderResizing)
                    : isResizableCustomPlaceholderAnnotation(annotation, options?.allowSenderPlaceholderResizing);

                const controlHandles = this.getControlHandles();
                // remove all existing handles
                controlHandles.splice(0, controlHandles.length);
                if (shouldAllowResize) {
                    // add new handles
                    controlHandles.push(new Annotations.BoxControlHandle(10, 10, 1, 16));
                    controlHandles.push(new Annotations.BoxControlHandle(10, 10, 4, 16));
                    controlHandles.push(new Annotations.BoxControlHandle(10, 10, 1, 64));
                    controlHandles.push(new Annotations.BoxControlHandle(10, 10, 4, 64));
                }
            }
        }
        getDimensions(annotation: Core.Annotations.FreeTextAnnotation) {
            const x = annotation.X - ANNOTATION_PADDING;
            const y = annotation.Y - ANNOTATION_PADDING;
            const width = annotation.Width + ANNOTATION_PADDING * 2;
            const height = annotation.Height + ANNOTATION_PADDING * 2;

            return new instance.Core.Math.Rect(x, y, x + width, y + height);
        }
        drawSelectionOutline(
            ctx: CanvasRenderingContext2D,
            annotation: Core.Annotations.FreeTextAnnotation,
            zoom: number
        ): void {
            const { X, Y, Width, Height } = annotation;
            const x = X - ANNOTATION_PADDING;
            const y = Y - ANNOTATION_PADDING;
            const width = Width + ANNOTATION_PADDING * 2;
            const height = Height + ANNOTATION_PADDING * 2;

            const { selectionStroke, selectionFill } = getAnnotationColours(annotation);

            ctx.save();
            ctx.strokeStyle = selectionStroke;
            ctx.fillStyle = selectionFill;
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.rect(x, y, width, height);
            ctx.fill();
            ctx.stroke();
            ctx.closePath();
            ctx.restore();
        }
    }

    freeTextAnnotations.forEach(
        annotation =>
            // @ts-expect-error selectionModel is incorrectly defined as a SelectionModel instance rather than the class itself
            (annotation.selectionModel = CustomSelectionModel)
    );
};

export const updateStampAnnotationSelectionBox = (instance: WebViewerInstance) => {
    const { Annotations } = instance.Core;
    const { SelectionModel, BoxSelectionModel } = Annotations;
    // @ts-expect-error setCustomHandlers is incorrectly defined - it should require the class itself, not an instance
    SelectionModel.setCustomHandlers(BoxSelectionModel, {
        // draws a diagonal dashed along across the middle of the selected annotation
        drawSelectionOutline(ctx, annotation, zoom, pageMatrix, { selectionModel, originalDrawSelectionOutline }) {
            if (!(annotation instanceof Annotations.StampAnnotation)) {
                originalDrawSelectionOutline(ctx, annotation, zoom, pageMatrix);
                return;
            }
            const { x1, x2, y1, y2 } = selectionModel.getDimensions(annotation);

            ctx.lineWidth = 1;
            const { selectionStroke } = getAnnotationColours(annotation);
            ctx.strokeStyle = selectionStroke;

            ctx.beginPath();
            ctx.rect(x1, y1, x2 - x1, y2 - y1);
            ctx.stroke();
            ctx.closePath();
        }
    });
};

export const initialiseWebViewerInstance = (instance: WebViewerInstance) => {
    const { documentViewer, annotationManager, Tools, DisplayMode, DisplayModes, Annotations, Math } = instance.Core;
    const { iframeWindow } = instance.UI;

    iframeWindow.document.body.addEventListener('dragenter', e => e.preventDefault());

    iframeWindow.document.body.addEventListener('dragover', e => e.preventDefault());
    iframeWindow.document.body.addEventListener('drop', e => e.preventDefault());

    const displayModeManager = documentViewer.getDisplayModeManager();
    displayModeManager.setDisplayMode(new DisplayMode(documentViewer, DisplayModes.Continuous));
    documentViewer.zoomTo(1);
    annotationManager.promoteUserToAdmin();
    annotationManager.setRotationOptions({ isEnabled: false });
    const allTools = Object.values(documentViewer.getToolModeMap());
    for (const tool of allTools) {
        if (tool instanceof Tools.AnnotationSelectTool) {
            tool.enableImmediateActionOnAnnotationSelection();
        }
    }

    instance.UI.setToolbarGroup('toolbarGroup-Shapes');

    instance.UI.disableElements([
        'header',
        'toolsHeader',
        'freeHandToolGroupButton',
        'outlinesPanelButton',
        'thumbnailsPanelButton',
        'freeHandHighlightToolGroupButton',
        'contextMenuPopup',
        'arrowToolGroupButton',
        'lineToolGroupButton',
        'polygonCloudToolGroupButton',
        'polyLineToolGroupButton',
        'polygonCloudToolGroupButton',
        'polygonToolGroupButton',
        'ellipseToolGroupButton',
        'shapeToolGroupButton',
        'panToolButton',
        'ribbons',
        'eraserToolButton',
        'fileAttachmentToolGroupButton',
        'calloutToolGroupButton',
        'ribbonsDropdown',
        'selectToolButton',
        'annotationCommentButton',
        'linkButton',
        'toggleNotesButton',
        'searchButton',
        'menuButton',
        'viewControlsButton',
        'signaturePanelButton',
        'thumbnailControl',
        'toolsOverlay',
        'annotationGroupButton',
        'richTextFormats',
        'opacitySlider',
        'richTextPopup',
        'arcToolGroupButton'
    ]);

    instance.UI.updateElement('annotationStyleEditButton', { img: styleAnnotationIcon });
    instance.UI.updateElement('annotationDeleteButton', { img: deleteAnnotationIcon });

    const tool = documentViewer.getTool('AnnotationCreateRubberStamp');
    const stampTool = tool as Core.Tools.RubberStampCreateTool;

    stampTool.setCustomStamps([]);
    stampTool.setStandardStamps([]);

    //Set the selected tool
    documentViewer.setToolMode(documentViewer.getTool('AnnotationEdit'));
    instance.UI.openElements(['leftPanel']);

    annotationManager.addEventListener(
        'annotationSelected',
        (annotationList: AnnotationType[], action: 'selected' | 'deselected') => {
            if (annotationList.length === 1) {
                const annotation = annotationList[0];
                updateStylingOptions(instance, annotation, action);
            }
        }
    );
};

/**
 * A function to fix a resizing bug that is caused by
 * enableImmediateActionOnAnnotationSelection().
 *
 * Calling enableImmediateActionOnAnnotationSelection() allows us to immediately
 * move an annotation without having to select it first, but also breaks the
 * ability to resize an annotation.  To work around this bug, we disable
 * immediate action (disableImmediateActionOnAnnotationSelection), then
 * programatically select the annotation when the mouseLeftDown event is
 * triggered. This gives us the same outcome as
 * enableImmediateActionOnAnnotationSelection.
 *
 * @link [Bug details](https://community.apryse.com/t/bug-report-resizing-annotations/8995)
 *
 * @param {WebViewerInstance} instance
 */
export const fixAnnotationResizing = (instance: WebViewerInstance) => {
    const { annotationManager, documentViewer, Tools } = instance.Core;
    const editTool = documentViewer.getTool(Tools.ToolNames.EDIT) as Core.Tools.AnnotationEditTool;
    editTool.disableImmediateActionOnAnnotationSelection();

    documentViewer.addEventListener('mouseLeftDown', (e: MouseEvent) => {
        const annotation = annotationManager.getAnnotationByMouseEvent(e);
        if (annotation) {
            annotationManager.deselectAllAnnotations();
            annotationManager.selectAnnotation(annotation);
        }
    });
};

// Only show relevant styling options for the selected annotation.
const updateStylingOptions = (
    instance: WebViewerInstance,
    annotation: AnnotationType,
    action: 'selected' | 'deselected'
) => {
    const { Annotations } = instance.Core;

    const textStylingElements = [
        'freeTextBoldButton',
        'freeTextItalicButton',
        'freeTextUnderlineButton',
        'freeTextStrikeoutButton',
        'freeTextAlignLeftButton',
        'freeTextAlignCenterButton',
        'freeTextAlignRightButton',
        'freeTextJustifyCenterButton'
    ];

    // Stamp annotations cannot be styled
    if (annotation instanceof Annotations.StampAnnotation) {
        if (action === 'selected') {
            instance.UI.disableElements(['annotationStyleEditButton']);
        } else {
            instance.UI.enableElements(['annotationStyleEditButton']);
        }
    }
    // Placeholder text annotations have limited styling options because we replace them using pdf-lib during document generation
    else if (annotation instanceof Annotations.FreeTextAnnotation && getIsPlaceholderAnnotation(annotation)) {
        // There is some additional customisation we can only do with CSS.
        // We cannot directly get the annotationStylePopup Element, so we need to add a class to the webviewer app element
        // to conditionally apply our styles
        const appElement = instance.UI.iframeWindow.document.querySelector('#app');

        if (action === 'selected') {
            appElement?.classList.add('restrictTextStylingOptions');
            instance.UI.setAnnotationStylePopupTabs(
                instance.UI.AnnotationKeys.FREE_TEXT,
                [instance.UI.AnnotationStylePopupTabs.TEXT_COLOR],
                instance.UI.AnnotationStylePopupTabs.TEXT_COLOR
            );
            instance.UI.disableElements(textStylingElements);
        } else {
            appElement?.classList.remove('restrictTextStylingOptions');
            instance.UI.setAnnotationStylePopupTabs(
                instance.UI.AnnotationKeys.FREE_TEXT,
                [
                    instance.UI.AnnotationStylePopupTabs.TEXT_COLOR,
                    instance.UI.AnnotationStylePopupTabs.STROKE_COLOR,
                    instance.UI.AnnotationStylePopupTabs.FILL_COLOR
                ],
                instance.UI.AnnotationStylePopupTabs.TEXT_COLOR
            );

            instance.UI.enableElements(textStylingElements);
        }
    }
};

const fitLabelToWidth = (ctx: CanvasRenderingContext2D, label: string, maxWidth: number) => {
    const ellipses = '…';
    let labelWidth = ctx.measureText(label).width;
    const ellipsesWidth = ctx.measureText(ellipses).width;
    const firstCharacterWidth = ctx.measureText(label[0]).width;

    if (labelWidth <= maxWidth) {
        return label;
    } else if (maxWidth < ellipsesWidth + firstCharacterWidth) {
        return ellipses;
    } else {
        let truncatedLabel = label;
        while (labelWidth + ellipsesWidth > maxWidth && truncatedLabel.length > 0) {
            truncatedLabel = truncatedLabel.slice(0, -1);
            labelWidth = ctx.measureText(truncatedLabel).width;
        }
        return truncatedLabel + ellipses;
    }
};

const ANNOTATION_BOX_BORDER_RADIUS = 4;

const getCustomPlaceholderAnnotationColours = (customPlaceholder: CustomPlaceholder) => {
    switch (customPlaceholder.respondentType) {
        case CustomPlaceholderRespondentType.CLIENT:
            return {
                highlightStroke: variables.orange300,
                headerBackground: variables.orange300,
                headerText: variables.black
            };
        case CustomPlaceholderRespondentType.SENDER:
        default:
            return {
                highlightStroke: variables.blue400,
                headerBackground: variables.blue600,
                headerText: variables.white
            };
    }
};

// This function translates and rotates the origin of a canvas context to match the visual top left of an annotation.
// When an annotation is rotated, the origin of the canvas context that PDFTron gives us is also rotated. By normalising to the
// top left corner of the annotation, we can draw the annotation box and header without having to worry about the rotation.
const setCanvasOriginToAnnotationTopLeft = (ctx: CanvasRenderingContext2D, annotation: AnnotationType) => {
    switch (annotation.Rotation) {
        case 0:
            ctx.translate(annotation.X, annotation.Y);
            break;
        case 90:
            ctx.translate(annotation.X, annotation.Y + annotation.Height);
            break;
        case 180:
            ctx.translate(annotation.X + annotation.Width, annotation.Y + annotation.Height);
            break;
        case 270:
            ctx.translate(annotation.X + annotation.Width, annotation.Y);
            break;
    }

    ctx.rotate((-1 * (annotation.Rotation * Math.PI)) / 180);
};

type AnnotationBoxDimensions = {
    x: number;
    y: number;
    width: number;
    height: number;
};

// These are the padded annotation coordinates relative to the canvas origin, which has been normalised to the top left point of the annotation.
// This means that the annotation box will be drawn in the correct position and rotation regardless of the annotation's rotation - we just need
// to make sure we swap the width and height if the annotation is rotated 90 or 270 degrees.
const getPaddedAnnotationBoxRelativeToCanvas = (annotation: AnnotationType): AnnotationBoxDimensions => {
    return {
        x: 0 - ANNOTATION_PADDING,
        y: 0 - ANNOTATION_PADDING,
        width: (annotation.Rotation % 180 === 0 ? annotation.Width : annotation.Height) + ANNOTATION_PADDING * 2,
        height: (annotation.Rotation % 180 === 0 ? annotation.Height : annotation.Width) + ANNOTATION_PADDING * 2
    };
};

const drawAnnotationBox = (
    annotationBoxWithPadding: AnnotationBoxDimensions,
    customPlaceholder: CustomPlaceholder,
    ctx: CanvasRenderingContext2D,
    isHighlighted: boolean,
    options?: {
        hideTopBorder?: boolean;
    }
) => {
    const { highlightStroke } = getCustomPlaceholderAnnotationColours(customPlaceholder);
    const adjustedAnnotationBox = {
        ...annotationBoxWithPadding,
        // Subtract 1 from width to prevent the stroke from being misaligned with the header
        width: annotationBoxWithPadding.width - 1
    };

    ctx.fillStyle = `${variables.blue400}05`;

    if (isHighlighted) {
        ctx.strokeStyle = highlightStroke;
        ctx.lineWidth = 2;
    } else {
        ctx.strokeStyle = variables.gray;
        ctx.setLineDash([2.5, 2.5]);
    }

    ctx.beginPath();

    // If we are hiding the top border we do not want to add a rounded corner to the top left and right and we do not want to
    // add a stroke to the top of the rectangle, so we cannot use the roundRect function.
    // Instead we draw the partial rectangle using a combination of lineTo and quadraticCurveTo
    if (options?.hideTopBorder) {
        ctx.moveTo(adjustedAnnotationBox.x, adjustedAnnotationBox.y);
        ctx.lineTo(
            adjustedAnnotationBox.x,
            adjustedAnnotationBox.y + adjustedAnnotationBox.height - ANNOTATION_BOX_BORDER_RADIUS
        );
        ctx.quadraticCurveTo(
            adjustedAnnotationBox.x,
            adjustedAnnotationBox.y + adjustedAnnotationBox.height,
            adjustedAnnotationBox.x + ANNOTATION_BOX_BORDER_RADIUS,
            adjustedAnnotationBox.y + adjustedAnnotationBox.height
        );
        ctx.lineTo(
            adjustedAnnotationBox.x + adjustedAnnotationBox.width - ANNOTATION_BOX_BORDER_RADIUS,
            adjustedAnnotationBox.y + adjustedAnnotationBox.height
        );
        ctx.quadraticCurveTo(
            adjustedAnnotationBox.x + adjustedAnnotationBox.width,
            adjustedAnnotationBox.y + adjustedAnnotationBox.height,
            adjustedAnnotationBox.x + adjustedAnnotationBox.width,
            adjustedAnnotationBox.y + adjustedAnnotationBox.height - ANNOTATION_BOX_BORDER_RADIUS
        );
        ctx.lineTo(adjustedAnnotationBox.x + adjustedAnnotationBox.width, adjustedAnnotationBox.y);
    } else {
        ctx.roundRect(
            adjustedAnnotationBox.x,
            adjustedAnnotationBox.y,
            adjustedAnnotationBox.width,
            adjustedAnnotationBox.height,
            ANNOTATION_BOX_BORDER_RADIUS
        );
    }

    ctx.stroke();
    ctx.fill();
};

const ANNOTATION_HEADER_BORDER_RADIUS = 80;

// Refer to the documentation here for a detailed explanation of what is being calculated and drawn:
// https://flkitover.atlassian.net/wiki/spaces/~62be817437780d89a352c8c1/pages/1983479825/Custom+placeholder+UI+documentation
const drawAnnotationHeader = (
    annotationBoxWithPadding: AnnotationBoxDimensions,
    customPlaceholder: CustomPlaceholder,
    customPlaceholderNumber: number, // index from 1
    ctx: CanvasRenderingContext2D
) => {
    const { headerBackground, headerText } = getCustomPlaceholderAnnotationColours(customPlaceholder);

    // Get font sizes so that we can calculate the dimensions of the background
    const labelFontSize = 14;
    const labelBoxPadding = 4;
    ctx.font = `${labelFontSize}px sans-serif`;

    // label starts in line with the annotation, but can extend to the padded annotation box, leaving space for padding after the text
    const maxLabelWidth = annotationBoxWithPadding.width - ANNOTATION_PADDING - labelBoxPadding;

    const labelText = fitLabelToWidth(ctx, customPlaceholder.label, maxLabelWidth);

    // Measure label text height using the height of a capital A. This ensures that the label box
    // (and therefore the header) is always the same height regardless of the label text
    const labelTextHeight = ctx.measureText('A').actualBoundingBoxAscent;
    const labelBoxHeight = labelTextHeight + labelBoxPadding * 2;

    const numberFontSize = 12;
    ctx.font = `${numberFontSize}px sans-serif`;
    const numberText = `${customPlaceholderNumber}`;

    const numberTextMeasure = ctx.measureText(numberText);
    const numberTextHeight = numberTextMeasure.actualBoundingBoxAscent;

    const numberBoxVerticalPadding = 4 + (labelTextHeight - numberTextHeight) / 2;
    const numberBoxHorizontalPadding = 6;
    const numberBoxWidth = numberTextMeasure.width + numberBoxHorizontalPadding * 2;

    const backgroundCoordinates = {
        x: annotationBoxWithPadding.x - numberBoxWidth,
        y: annotationBoxWithPadding.y - labelBoxHeight,
        width: annotationBoxWithPadding.width + numberBoxWidth,
        height: labelBoxHeight
    };

    ctx.fillStyle = headerBackground;

    // Draw background
    ctx.beginPath();
    ctx.roundRect(
        backgroundCoordinates.x,
        backgroundCoordinates.y,
        backgroundCoordinates.width,
        backgroundCoordinates.height,
        [ANNOTATION_HEADER_BORDER_RADIUS, ANNOTATION_HEADER_BORDER_RADIUS, 0, ANNOTATION_HEADER_BORDER_RADIUS]
    );
    ctx.fill();

    // Draw text
    ctx.fillStyle = headerText;

    ctx.font = `${numberFontSize}px sans-serif`;
    ctx.fillText(
        numberText,
        annotationBoxWithPadding.x - numberBoxWidth + numberBoxHorizontalPadding,
        annotationBoxWithPadding.y - numberBoxVerticalPadding
    );

    ctx.font = `${labelFontSize}px sans-serif`;
    ctx.fillText(
        labelText,
        annotationBoxWithPadding.x + ANNOTATION_PADDING,
        annotationBoxWithPadding.y - labelBoxPadding
    );
};

export const addCustomPlaceholdersDrawMethod = (
    instance: WebViewerInstance,
    customPlaceholderData: CustomPlaceholder[],
    options: { hideTitles: boolean; highlightedPlaceholderId?: string }
) => {
    const { Annotations, annotationManager } = instance.Core;

    Annotations.setCustomDrawHandler(
        // @ts-expect-error setCustomDrawHandler is incorrectly defined - it should require the class itself, not an instance
        Annotations.FreeTextAnnotation,
        (ctx, pageMatrix, rotation, { annotation, originalDraw }) => {
            if (isCustomPlaceholderAnnotation(annotation)) {
                const isSelected = !!annotationManager.getSelectedAnnotations().find(annot => annot === annotation);
                const customPlaceholderIndex = customPlaceholderData.findIndex(
                    placeholder => placeholder.id === annotation.getCustomData('customPlaceholderId')
                );

                const customPlaceholder = customPlaceholderData[customPlaceholderIndex];

                if (customPlaceholder) {
                    ctx.save();
                    setCanvasOriginToAnnotationTopLeft(ctx, annotation);
                    const annotationBoxWithPadding = getPaddedAnnotationBoxRelativeToCanvas(annotation);

                    const isHighlighted = customPlaceholder.id === options.highlightedPlaceholderId;
                    if (isSelected) {
                        // When selected, we only need to draw the annotation header. We do not need to draw the annotation box,
                        // as the selection box will be drawn in its place.
                        drawAnnotationHeader(
                            annotationBoxWithPadding,
                            customPlaceholder,
                            customPlaceholderIndex + 1,
                            ctx
                        );
                    }
                    // @ts-expect-error annotation.IsHovering (note the casing) is not defined but it does exist according to PDFTron documentation
                    // https://docs.apryse.com/api/web/Core.Annotations.FreeTextAnnotation.html#IsHoverable__anchor
                    else if (!options.hideTitles || isHighlighted || annotation.IsHovering) {
                        drawAnnotationHeader(
                            annotationBoxWithPadding,
                            customPlaceholder,
                            customPlaceholderIndex + 1,
                            ctx
                        );
                        drawAnnotationBox(annotationBoxWithPadding, customPlaceholder, ctx, isHighlighted, {
                            hideTopBorder: true
                        });
                    } else if (!customPlaceholder.value) {
                        drawAnnotationBox(annotationBoxWithPadding, customPlaceholder, ctx, isHighlighted);
                        // TODO - find the actual root cause of this issue.
                        // After parseString annotation and build it again in backend, it is not apply correct styles for empty annotation.
                        // So we need to update styles here.
                        updateCustomPlaceholderAnnotationStyle(annotation, !!customPlaceholder.value);
                    }

                    ctx.restore();
                }
            }

            originalDraw(ctx, pageMatrix, rotation);
        },
        { generateAppearance: false }
    );
};

export const setupCloneButton = (
    instance: WebViewerInstance,
    addNewToast: (message: string, type?: ToastTypes) => void
) => {
    const annotationManager = instance.Core.annotationManager;

    annotationManager.addEventListener('annotationSelected', () => {
        const pageCount = instance.Core.documentViewer.getPageCount();
        const selectedAnnotationsCount = annotationManager.getSelectedAnnotations().length;

        if (pageCount <= 1 || selectedAnnotationsCount !== 1) {
            instance.UI.disableElements(['cloneButton']);
        } else {
            instance.UI.enableElements(['cloneButton']);
        }
    });

    const cloneButton = {
        type: 'statefulButton',
        dataElement: 'cloneButton',
        initialState: 'Off',
        states: {
            Off: {
                img: cloneAnnotationIcon,
                title: 'Add to all pages',
                onClick: async (update: (state: string) => void) => {
                    const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];
                    const annotationDisplayName = getAnnotationDisplayName(selectedAnnotation);

                    await cloneAnnotation(
                        selectedAnnotation,
                        instance,
                        () => {
                            addNewToast(
                                `${capitalize(annotationDisplayName)} has been successfully added to all other pages`
                            );
                        },
                        () => {
                            addNewToast(
                                `Oops! Some pages are different shapes. Adjust the ${annotationDisplayName} to your liking on these pages.`,
                                ToastTypes.WARNING
                            );
                        }
                    );
                    update('On');
                }
            },
            On: {
                img: cloneAnnotationActiveIcon,
                title: 'Remove from pages',
                onClick: (update: (state: string) => void) => {
                    const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];
                    const annotationDisplayName = getAnnotationDisplayName(selectedAnnotation);

                    removeClones(selectedAnnotation, instance.Core.annotationManager, () => {
                        addNewToast(`${capitalize(annotationDisplayName)} has been removed from all other pages`);
                    });
                    update('Off');
                }
            }
        },
        mount: (update: (state: string) => void) => {
            const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];

            if (isCloneGroupAnnotation(selectedAnnotation, instance.Core.annotationManager)) {
                return update('On');
            } else {
                return update('Off');
            }
        }
    };

    instance.UI.annotationPopup.add([cloneButton]);
};

export const addDropEventToWebviewerIframe = (instance: WebViewerInstance, onDrop: (point: Point) => void) => {
    const { documentViewer } = instance.Core;
    const { iframeWindow } = instance.UI;

    iframeWindow.document.body.addEventListener('dragenter', e => e.preventDefault());

    iframeWindow.document.body.addEventListener('dragover', e => e.preventDefault());

    iframeWindow.document.body.addEventListener('drop', e => {
        const scrollElement = documentViewer.getScrollViewElement();
        const scrollLeft = scrollElement.scrollLeft || 0;
        const scrollTop = scrollElement.scrollTop || 0;
        onDrop({
            x: e.clientX + scrollLeft,
            y: e.clientY + scrollTop
        });
        e.preventDefault();
        return false;
    });
};

export const base64ToPdfBlob = (base64: string) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
};

// This function loads annotations from an XFDF string into the annotation manager, and filters out annotations for clients
// that are not part of the current document (by index)
// We need this because we save some annotations in string format instead of adding them to the PDF so we can replace them with other information at a later state
// (e.g. replacing a signature placeholder with the actual signature)
export const loadSavedAnnotationString = (webViewerInstance: WebViewerInstance, existingAnnotationsString: string) => {
    const { annotationManager } = webViewerInstance.Core;
    // The importAnnotations function appears to handle duplicates, so we do not need to worry about that
    return annotationManager.importAnnotations(existingAnnotationsString).then(importedAnnotations => {
        importedAnnotations.forEach((annotation: AnnotationType) => {
            if (annotation instanceof webViewerInstance.Core.Annotations.FreeTextAnnotation) {
                if (isClientNameAnnotation(annotation)) {
                    // Update the annotation size since the contents may be different if the client has changed
                    // FUTURE: We could store the client name in the annotation customData so that we only resize when the name changes
                    fitAnnotationToText(webViewerInstance, annotation);
                }
                // Set this to true again, as it seems to not be saved in the xfdf string
                annotation.IsHoverable = true;
            }

            annotationManager.redrawAnnotation(annotation);
        });

        return importedAnnotations;
    });
};

// This function updates the annotations in a document to only be visible if they belong to a client that exists on the document
export const updateAnnotationsVisibility = (
    webViewerInstance: WebViewerInstance,
    isTemplate: boolean,
    numberOfClients: number
) => {
    const { annotationManager } = webViewerInstance.Core;

    const annotations = annotationManager.getAnnotationsList();
    annotations.forEach((annotation: AnnotationType) => {
        const clientIndex = parseInt(
            annotation.Subject.substring(annotation.Subject.lastIndexOf('-') + 1, annotation.Subject.length)
        );
        if (isNaN(clientIndex) || clientIndex < numberOfClients || isTemplate) {
            annotation.Hidden = false;
        } else {
            annotation.Hidden = true;
        }

        annotationManager.redrawAnnotation(annotation);
    });
};

export const isClientAnnotation = (annotation: AnnotationType) => {
    return (
        isClientSignatureAnnotation(annotation) ||
        isClientNameAnnotation(annotation) ||
        isClientDateAnnotation(annotation) ||
        isWitnessNameAnnotation(annotation) ||
        isWitnessAnnotation(annotation)
    );
};

const KEEP_AS_SVG = false;

const SIGNATURE_WIDTH = 96;
const SIGNATURE_HEIGHT = 32;

// Calculate the offset of the drag event co-ordinates from the top left of the drag image.
// Used to normalise the co-ordinates of the drag event to the co-ordinates of the drag image
export const getOffsetFromDragEvent = (e: DragEvent) => {
    const rect = (e.target as HTMLElement)?.getBoundingClientRect() || {
        left: 0,
        top: 0
    };
    const offset = {
        x: e.pageX - rect.left,
        y: e.pageY - rect.top
    };
    return offset;
};
// This function gets the bottom left point of the drag image after accounting for the drag offset
export const calculateDragImageBottomLeftPoint = (dragImage: HTMLElement | null, dropPoint: Point, offset: Point) => {
    const rect = dragImage?.getBoundingClientRect() || {
        height: 0
    };

    const x = dropPoint.x - offset.x;
    // Normally y refers to the top left of the element, but we want to align the placeholder to the bottom of the drag image, so we add the height of the drag image to the y position
    const y = dropPoint.y - offset.y + rect.height;

    return { x, y };
};

// This function gets the center of the drag image after accounting for the drag offset
export const calculateDragImageCenter = (dragImage: HTMLElement | null, dropPoint: Point, offset: Point) => {
    const rect = dragImage?.getBoundingClientRect() || {
        height: 0,
        width: 0
    };
    const x = dropPoint.x - offset.x + rect.width / 2;
    const y = dropPoint.y - offset.y + rect.height / 2;

    return { x, y };
};

// Use the center point of the drag image to calculate the top left point of the annotation.
// This accounts for any differences in annotation and drag image size.
// https://flkitover.atlassian.net/wiki/spaces/BRI/pages/1878262176/PDFTron+Co-ordinate+System#Rotation
export const getAnnotationCoordinatesFromCenterPoint = (
    centerPoint: Point,
    annotationHeight: number,
    annotationWidth: number,
    options?: {
        isFreeTextAnnotation?: boolean;
    }
) => {
    return {
        x: centerPoint.x - annotationWidth / 2,
        y: options?.isFreeTextAnnotation
            ? centerPoint.y - annotationHeight / 2 + FREE_TEXT_VERTICAL_OFFSET
            : centerPoint.y - annotationHeight / 2
    };
};

export const fitAnnotationToText = (
    webViewerInstance: WebViewerInstance,
    freeTextAnnotation: Core.Annotations.FreeTextAnnotation
) => {
    const { documentViewer, Annotations } = webViewerInstance.Core;
    const doc = documentViewer.getDocument();
    const pageNumber = freeTextAnnotation.PageNumber;
    // Fit the annotation to the size of its text setSizeOfAnnotation
    const pageMatrix = doc.getPageMatrix(pageNumber);
    const pageRotation = doc.getPageRotation(pageNumber);
    const pageInfo = doc.getPageInfo(pageNumber);
    freeTextAnnotation.setAutoSizeType(Annotations.FreeTextAnnotation.AutoSizeTypes.AUTO);
    freeTextAnnotation.fitText(pageInfo, pageMatrix, pageRotation);
    // Add visual buffer to annotation
    freeTextAnnotation.Width = Math.ceil(freeTextAnnotation.Width) + 2;
    freeTextAnnotation.Height = Math.ceil(freeTextAnnotation.Height) + 1;
};

export const maintainFreeTextAnnotationSize = (freeTextAnnotation: Core.Annotations.FreeTextAnnotation) => {
    // Add buffer so that annotation is not truncated when cloning/dragging between pages
    if (freeTextAnnotation.Rotation % 180 === 0) {
        freeTextAnnotation.Width = Math.ceil(freeTextAnnotation.Width) + 1;
    } else {
        freeTextAnnotation.Height = Math.ceil(freeTextAnnotation.Height) + 1;
    }
};

export const createStampAnnotation = (
    imageUrl: string,
    subject: ClientSignatureSubjectType | AgentSignatureSubjectType | WitnessSignatureSubjectType,
    webViewerInstance: WebViewerInstance,
    dropPoint?: Point,
    options?: CreateAnnotationOptions
) => {
    const point = dropPoint || {};
    const rect = { width: SIGNATURE_WIDTH, height: SIGNATURE_HEIGHT };
    const { documentViewer, annotationManager, Annotations } = webViewerInstance.Core;
    const doc = documentViewer.getDocument();
    // Document may not be loaded yet
    if (!doc) {
        return;
    }
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(point, point);
    if (!!dropPoint && page.first == null) {
        return; // don't add to an invalid page location
    }
    const pageNumber = page.first !== null ? page.first : documentViewer.getCurrentPage();
    const pageInfo = doc.getPageInfo(pageNumber);
    const pagePoint = displayMode.windowToPage(point, pageNumber);
    const zoom = documentViewer.getZoomLevel();

    const stampAnnot = new Annotations.StampAnnotation();
    stampAnnot.PageNumber = pageNumber;
    stampAnnot.Subject = subject;
    const rotation = documentViewer.getCompleteRotation(pageNumber) * 90;
    stampAnnot.Rotation = rotation;
    if (rotation === 270 || rotation === 90) {
        stampAnnot.Width = rect.height / zoom;
        stampAnnot.Height = rect.width / zoom;
    } else {
        stampAnnot.Width = rect.width / zoom;
        stampAnnot.Height = rect.height / zoom;
    }

    if (pagePoint.y && pagePoint.x) {
        const alignedPosition = getAnnotationCoordinatesFromCenterPoint(pagePoint, stampAnnot.Height, stampAnnot.Width);
        stampAnnot.X = alignedPosition.x;
        stampAnnot.Y = alignedPosition.y;
    } else {
        // Center on page
        stampAnnot.X = pageInfo.width / 2 - stampAnnot.Width / 2;
        stampAnnot.Y = pageInfo.height / 2 - stampAnnot.Height / 2;
    }

    stampAnnot.setImageData(imageUrl, KEEP_AS_SVG);
    stampAnnot.Author = annotationManager.getCurrentUser();

    if (options?.clientId) {
        stampAnnot.setCustomData('clientId', options.clientId);
    }

    annotationManager.deselectAllAnnotations();
    annotationManager.addAnnotation(stampAnnot);
    annotationManager.redrawAnnotation(stampAnnot);
    annotationManager.selectAnnotation(stampAnnot);
};

export const createFreeTextAnnotation = (
    content: string,
    subject:
        | ClientDateSubjectType
        | ClientNameSubjectType
        | FreeTextSubjectType
        | AgentNameSubjectType
        | SenderNameSubjectType
        | CompletionDateSubjectType
        | WitnessNameSubjectType
        | CustomPlaceholderSubjectType,
    locked: boolean,
    webViewerInstance: WebViewerInstance,
    dropPoint?: Point,
    options?: CreateAnnotationOptions
) => {
    const point = dropPoint || {};
    const { documentViewer, annotationManager, Annotations } = webViewerInstance.Core;

    const doc = documentViewer.getDocument();
    // Document may not be loaded yet
    if (!doc) {
        return;
    }
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(point, point);
    if (!!dropPoint && page.first == null) {
        return; // don't add to an invalid page location
    }
    const pageNumber = page.first !== null ? page.first : documentViewer.getCurrentPage();
    const pageInfo = doc.getPageInfo(pageNumber);
    const pagePoint = displayMode.windowToPage(point, pageNumber);

    const rotation = documentViewer.getCompleteRotation(pageNumber) * 90;

    const freeTextAnnotation = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
        autoSizeProperties: {
            shrinkWidth: true,
            shrinkHeight: true,
            expandWidth: true,
            expandHeight: true
        },
        Author: annotationManager.getCurrentUser(),
        FontSize: options?.fontSize ? `${options?.fontSize}pt` : '12pt',
        IsHoverable: true,
        LockedContents: locked,
        PageNumber: pageNumber,
        Subject: subject,
        StrokeThickness: 0,
        TextColor: new Annotations.Color(0, 0, 0)
    });

    freeTextAnnotation.setContents(content);

    if (isCustomPlaceholderAnnotation(freeTextAnnotation) && options?.customPlaceholderData?.id) {
        freeTextAnnotation.setCustomData('customPlaceholderId', options.customPlaceholderData.id);
        freeTextAnnotation.setCustomData(
            'customPlaceholderRespondentType',
            options.customPlaceholderData.respondentType
        );
        updateCustomPlaceholderAnnotationStyle(freeTextAnnotation, !options.customPlaceholderData.isBlank);
    }

    if (options?.clientId) {
        freeTextAnnotation.setCustomData('clientId', options.clientId);
    }

    // First we fit the annotation to its text
    fitAnnotationToText(webViewerInstance, freeTextAnnotation);

    // Workaround for bug in annotation.fitText() when the annotation has a rotation value and no set width/height.
    // We work around by setting the rotation afterwards. This means that we also have to swap the width and height.
    if (rotation % 180 !== 0) {
        const oldWidth = freeTextAnnotation.Width;
        freeTextAnnotation.Width = freeTextAnnotation.Height;
        freeTextAnnotation.Height = oldWidth;
    }

    freeTextAnnotation.Rotation = rotation;

    // Then we correctly position it based on its text contents
    if (pagePoint.x && pagePoint.y) {
        const alignedPosition = getAnnotationCoordinatesFromCenterPoint(
            pagePoint,
            freeTextAnnotation.Height,
            freeTextAnnotation.Width,
            {
                isFreeTextAnnotation: true
            }
        );
        freeTextAnnotation.X = alignedPosition.x;
        freeTextAnnotation.Y = alignedPosition.y;
    } else {
        // Center on page
        freeTextAnnotation.X = pageInfo.width / 2 - freeTextAnnotation.Width / 2;
        freeTextAnnotation.Y = pageInfo.height / 2 - freeTextAnnotation.Height / 2;
    }

    annotationManager.deselectAllAnnotations();
    annotationManager.addAnnotation(freeTextAnnotation);
    annotationManager.redrawAnnotation(freeTextAnnotation);
    // Only select locked annotations because PdfTron action button UI positions incorrectly for editable annotations
    if (locked) {
        annotationManager.selectAnnotation(freeTextAnnotation);
    }

    return freeTextAnnotation;
};

export const fetchUploadedDocumentFile = async (
    documentId: string,
    uploadedDocumentFileName: string,
    onSuccess: (blob: Blob) => void,
    onError: (error: unknown) => void,
    isOnboarding?: boolean
) => {
    try {
        const apiUrl = isOnboarding
            ? `${ONBOARDING_API_URL}/${documentId}/file/${uploadedDocumentFileName}`
            : `api/document/custom-document-file/${documentId}/${uploadedDocumentFileName}`;

        const response = await axios.get(apiUrl, {
            // ignoring because there seems to be no way to extend axios config types
            // (see comments on https://github.com/axios/axios/pull/1964)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            crossDomain: true,
            headers: { 'Content-Type': 'application/vnd.api+json' }
        });
        const blob = base64ToPdfBlob(response.data.pdfAsBase64);
        if (blob) {
            onSuccess(blob);
        } else {
            throw new Error();
        }
    } catch (error) {
        onError(error);
    }
};

// Returns true if the annotation is a free text annotation with the default text value
export function getIsDefaultTextAnnotation(annotation: Core.Annotations.Annotation) {
    return isFreeTextAnnotation(annotation) && annotation.getContents() === ANNOTATION_FREE_TEXT_DEFAULT_VALUE;
}

// Returns true if the annotation is a placeholder annotation (an annotation that we will replace when generating the document)
export function getIsPlaceholderAnnotation(annot: Core.Annotations.Annotation) {
    return (
        (annot.Subject &&
            (isClientSignatureAnnotation(annot) ||
                isClientNameAnnotation(annot) ||
                isAgentSignatureAnnotation(annot) ||
                isClientDateAnnotation(annot))) ||
        isAgentNameAnnotation(annot) ||
        isCompletionDateAnnotation(annot) ||
        isSenderNameAnnotation(annot) ||
        isWitnessAnnotation(annot) ||
        isWitnessNameAnnotation(annot) ||
        isCustomPlaceholderAnnotation(annot)
    );
}

// A FLK annotation is one that we have added from our system.
// Free text annotations are the only non-placeholder annotation
export function getIsFlkAnnotation(annot: Core.Annotations.Annotation) {
    return annot.Subject && (getIsPlaceholderAnnotation(annot) || isFreeTextAnnotation(annot));
}

export const getClientAnnotationClientIndex = (annotation: ClientAnnotation): string => {
    return annotation.Subject.split('-').slice(-1)[0];
};

export const getWitnessAnnotationSignatureIndex = (annotation: WitnessAnnotation): string => {
    if (annotation.Subject.includes('witnessSign')) {
        return annotation.Subject.split('-').slice(-1)[0];
    }
    return '';
};

export const getWitnessAnnotationNameIndex = (annotation: WitnessAnnotation): string => {
    if (annotation.Subject.includes('witnessName')) {
        return annotation.Subject.split('-').slice(-1)[0];
    }
    return '';
};

// Takes an array of client annotations and returns an object with the number of annotations for each clientIndex
export const getClientAnnotationCounts = (annotations: ClientAnnotation[]): ClientAnnotationCounts => {
    const annotationCounts = annotations.reduce((acc: ClientAnnotationCounts, annotation) => {
        const clientIndex = annotation.Subject.split('-').slice(-1)[0];
        if (!acc[clientIndex]) {
            acc[clientIndex] = 0;
        }
        acc[clientIndex]++;
        return acc;
    }, {});
    return annotationCounts;
};

export const getClientWitnessAnnotationCounts = (annotations: WitnessAnnotation[]): ClientAnnotationCounts => {
    const annotationCounts = annotations.reduce((acc: ClientAnnotationCounts, annotation) => {
        const clientIndex = annotation.Subject.split('-').slice(-1)[0];
        if (!acc[clientIndex]) {
            acc[clientIndex] = 0;
        }
        acc[clientIndex]++;
        return acc;
    }, {});
    return annotationCounts;
};

export const saveDocumentPromise = async (
    webViewerInstance: WebViewerInstance,
    uploadADocId: string,
    uploadedDocumentId: string,
    confirmationType: string,
    isOnboarding: boolean,
    senderDetails: {
        senderName: string;
        nameCount: number;
        signatureCount: number;
    },
    isEoc: boolean
) => {
    const promises: Promise<unknown>[] = [];
    const { documentViewer, PDFNet } = webViewerInstance.Core;
    const webViewerDocument = documentViewer.getDocument();
    const annotationManager = documentViewer.getAnnotationManager();
    const annotations = annotationManager.getAnnotationsList();

    const clientSignatureAnnotations = annotations.filter(annotation =>
        isClientSignatureAnnotation(annotation)
    ) as ClientSignatureAnnotation[];

    const clientWitnessSignatureAnnotations = annotations.filter(annotation =>
        isWitnessAnnotation(annotation)
    ) as WitnessAnnotation[];

    const clientSignatureCounts = getClientAnnotationCounts(clientSignatureAnnotations);
    const clientWitnessSignatureCounts = getClientWitnessAnnotationCounts(clientWitnessSignatureAnnotations);

    //Save current document
    if (confirmationType === SIGN_CONFIRMATION_TYPE) {
        const placeholderAnnotationString = await getPlaceholderAnnotationString(webViewerInstance);

        const placeholderAnnotationData = {
            annotations: placeholderAnnotationString,
            annotationsCount: JSON.stringify(clientSignatureCounts),
            witnessAnnotationsCount: JSON.stringify(clientWitnessSignatureCounts),
            senderDetails
        };
        //Save the annotations
        promises.push(
            postPlaceholderAnnotationData(
                placeholderAnnotationData,
                uploadADocId,
                uploadedDocumentId,
                isOnboarding,
                isEoc
            )
        );
    }

    const embeddedAnnotations = annotations.filter(annotation => !getIsPlaceholderAnnotation(annotation));

    const embeddedAnnotationString = await annotationManager.exportAnnotations({
        annotList: embeddedAnnotations
    });

    // Get pdf data using filtered annotations
    const fileData = await webViewerDocument.getFileData({
        xfdfString: embeddedAnnotationString,
        flatten: false,
        flags: PDFNet.SDFDoc.SaveOptions.e_remove_unused
    });
    const fileDataArr = new Uint8Array(fileData);
    const blob = new Blob([fileDataArr], { type: 'application/pdf' });

    const formData = new FormData();
    formData.append(`documents[${0}][documentTitle]`, 'FlattenedDoc');
    formData.append(`documents[${0}][documentName]`, 'name');
    formData.append(`documents[${0}][pdfFile]`, blob, 'flat.pdf');
    formData.append(`documents[${0}][uploadedDocumentId]`, uploadedDocumentId);

    promises.push(postDocumentWithEmbeddedAnnotations(formData, uploadADocId, isOnboarding, isEoc));

    return Promise.all(promises);
};

/**
 * This function checks that all documents have at least one signature annotations
 */
export const doUploadedDocumentsHaveSignatures = (uploadedDocumentStateItems: UploadedDocumentState[]) => {
    return uploadedDocumentStateItems.every(uploadedDocumentStateItem => {
        const { annotationManager } = uploadedDocumentStateItem.instance.Core;
        const annotations = annotationManager.getAnnotationsList();
        const clientSignatureAnnotations = annotations.filter(
            annotation => isClientSignatureAnnotation(annotation) && !annotation.Hidden
        ) as ClientSignatureAnnotation[];

        return clientSignatureAnnotations.length > 0;
    });
};

export const getPlaceholderAnnotationString = async (
    webViewerInstance: WebViewerInstance,
    options?: { removeHidden?: boolean }
) => {
    const { documentViewer } = webViewerInstance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const annotations = annotationManager.getAnnotationsList();

    const placeholderAnnotationList = annotations.filter(annotation => {
        if (options?.removeHidden && annotation.Hidden) {
            return false;
        } else {
            return getIsPlaceholderAnnotation(annotation);
        }
    });

    return await annotationManager.exportAnnotations({
        links: false,
        widgets: false,
        annotList: placeholderAnnotationList
    });
};

const postDocumentWithEmbeddedAnnotations = async (
    documentData: FormData,
    uploadADocId: string,
    isOnboarding: boolean,
    isEoc: boolean
) => {
    if (isEoc) {
        return axios.post(`api/document/exchange-of-contracts/${uploadADocId}/annotated-pdf`, documentData);
    } else {
        if (isOnboarding) {
            return axios.post(`${ONBOARDING_API_URL}/${uploadADocId}/annotated`, documentData);
        } else {
            return axios.post(`api/document/annotated/${uploadADocId}`, documentData);
        }
    }
};

async function postPlaceholderAnnotationData(
    placeholderAnnotationData: {
        annotations: string;
        annotationsCount: string;
        senderDetails: {
            senderName: string;
            nameCount: number;
            signatureCount: number;
        };
    },
    uploadADocId: string,
    uploadedDocumentId: string,
    isOnboarding: boolean,
    isEoc: boolean
) {
    if (isEoc) {
        return axios.post(
            `api/document/exchange-of-contracts/${uploadADocId}/${uploadedDocumentId}/annotations`,
            placeholderAnnotationData
        );
    } else {
        if (isOnboarding) {
            return axios.post(
                `${ONBOARDING_API_URL}/${uploadADocId}/${uploadedDocumentId}/annotations`,
                placeholderAnnotationData
            );
        } else {
            return axios.post(
                `api/document/annotations/${uploadADocId}/${uploadedDocumentId}`,
                placeholderAnnotationData
            );
        }
    }
}

export const getAnnotationsForCustomPlaceholder = (
    instance: WebViewerInstance,
    customPlaceholderId: string
): CustomPlaceholderAnnotation[] => {
    const { annotationManager } = instance.Core;
    const annotations = annotationManager.getAnnotationsList();
    return annotations.filter(
        annotation =>
            isCustomPlaceholderAnnotation(annotation) &&
            annotation.getCustomData('customPlaceholderId') === customPlaceholderId
    ) as CustomPlaceholderAnnotation[];
};

export const deleteAnnotationsForCustomPlaceholder = (
    customPlaceholderId: string,
    uploadedDocumentStateItems: UploadedDocumentState[]
) => {
    uploadedDocumentStateItems.forEach(uploadedDocumentStateItem => {
        const { annotationManager } = uploadedDocumentStateItem.instance.Core;

        const customPlaceholderAnnotations = getAnnotationsForCustomPlaceholder(
            uploadedDocumentStateItem.instance,
            customPlaceholderId
        );

        annotationManager.deleteAnnotations(customPlaceholderAnnotations);
    });
};

/**
 * Syncs all custom placeholder annotations with their form values.
 */
export const syncCustomPlaceholders = (
    uploadedDocuments: (UploadedDocument | { document: UploadedFile })[],
    uploadedDocumentStateItems: UploadedDocumentState[],
    customPlaceholders?: CustomPlaceholder[]
) => {
    const sync = (instance: WebViewerInstance, customPlaceholders: Record<string, CustomPlaceholder>) => {
        const { annotationManager } = instance.Core;
        const annotations = annotationManager.getAnnotationsList();
        const customPlaceholderAnnotations = annotations.filter(isCustomPlaceholderAnnotation);

        customPlaceholderAnnotations.forEach(annotation => {
            const customPlaceholderId = annotation.getCustomData('customPlaceholderId');
            const customPlaceholder = customPlaceholders[customPlaceholderId];

            if (customPlaceholder) {
                let shouldRefreshAnnotation = false;

                const annotationRespondentType = annotation.getCustomData('customPlaceholderRespondentType');
                const annotationContents = annotation.getContents();
                const fieldValue =
                    customPlaceholder.value || getCustomPlaceholderEmptyText(customPlaceholder.respondentType);
                const fieldRespondentType = customPlaceholder.respondentType;

                if (annotationRespondentType !== fieldRespondentType) {
                    annotation.setCustomData('customPlaceholderRespondentType', fieldRespondentType);
                    shouldRefreshAnnotation = true;
                }

                if (annotationContents !== fieldValue) {
                    annotation.setContents(fieldValue);
                    shouldRefreshAnnotation = true;
                }

                if (shouldRefreshAnnotation) {
                    updateCustomPlaceholderAnnotationStyle(annotation, !!customPlaceholder.value);
                    annotationManager.redrawAnnotation(annotation);
                }
            }
        });
    };

    const keyedCustomPlaceholders = keyBy(customPlaceholders, 'id');

    uploadedDocuments.forEach(uploadedDocument => {
        if (!isUploadedDocument(uploadedDocument)) {
            return;
        }

        const uploadedDocumentState = uploadedDocumentStateItems.find(
            uploadedDocumentStateItem => uploadedDocumentStateItem.uploadedDocumentId === uploadedDocument.id
        );

        if (uploadedDocumentState) {
            sync(uploadedDocumentState.instance, keyedCustomPlaceholders);
        }
    });
};

export const getDocumentsWithUpdatedAnnotations = async (
    uploadedDocuments: (UploadedDocument | { document: UploadedFile })[],
    uploadedDocumentStateItems: UploadedDocumentState[],
    options?: { removeHidden?: boolean }
) => {
    return await Promise.all(
        uploadedDocuments.map(async uploadedDocument => {
            if (!isUploadedDocument(uploadedDocument)) {
                return uploadedDocument;
            }
            const uploadedDocumentState = uploadedDocumentStateItems?.find(
                uploadedDocumentStateItem => uploadedDocumentStateItem.uploadedDocumentId === uploadedDocument.id
            );
            if (uploadedDocumentState) {
                const annotations = await getPlaceholderAnnotationString(uploadedDocumentState.instance, options);

                return {
                    ...uploadedDocument,
                    annotations
                };
            } else {
                return uploadedDocument;
            }
        })
    );
};

export const updateCustomPlaceholderAnnotationStyle = (annotation: CustomPlaceholderAnnotation, hasValue: boolean) => {
    if (hasValue) {
        // @ts-expect-error updateRichTextStyle is incorrectly defined to require every type of style
        annotation.updateRichTextStyle({
            'font-style': 'normal',
            color: variables.black
        });
    } else {
        // @ts-expect-error updateRichTextStyle is incorrectly defined to require every type of style
        annotation.updateRichTextStyle({
            'font-style': 'italic',
            color: variables.grey600
        });
    }
};

export const updateCustomPlaceholderCounts = (
    form: FormApi,
    annotations: CustomPlaceholderAnnotation[],
    action: AnnotationAction
) => {
    annotations.forEach(annotation => {
        const customPlaceholderId = annotation.getCustomData('customPlaceholderId');
        const customPlaceholdersField = form.getFieldState(`customPlaceholders`);
        if (customPlaceholderId && customPlaceholdersField) {
            const customPlaceholderIndex = customPlaceholdersField.value.findIndex(
                (customPlaceholder: CustomPlaceholder) => customPlaceholder.id === customPlaceholderId
            );

            const customPlaceholderData = customPlaceholdersField.value[customPlaceholderIndex];
            if (action === AnnotationAction.ADD) {
                form.change(`customPlaceholders[${customPlaceholderIndex}].count`, customPlaceholderData.count + 1);
            } else if (action === AnnotationAction.DELETE && customPlaceholderData.count > 0) {
                form.change(`customPlaceholders[${customPlaceholderIndex}].count`, customPlaceholderData.count - 1);
            }
        }
    });
};

// This function will remove all the custom placeholders on a document from the custom placeholder counts.
// It's intended to be used when a document is deleted.
export const getCustomPlaceholderCountsAfterDeletingDocument = (
    form: FormApi,
    uploadedDocumentState?: UploadedDocumentState
) => {
    if (uploadedDocumentState) {
        const webviewerInstance = uploadedDocumentState.instance;

        const { annotationManager } = webviewerInstance.Core;
        const annotations = annotationManager.getAnnotationsList();

        updateCustomPlaceholderCounts(form, annotations.filter(isCustomPlaceholderAnnotation), AnnotationAction.DELETE);
    }
};

export const removeClientAnnotations = (instance: WebViewerInstance) => {
    if (instance) {
        const { annotationManager } = instance.Core;
        const annotations = annotationManager.getAnnotationsList();

        const clientAnnotations = annotations.filter(isClientAnnotation);

        annotationManager.deleteAnnotations(clientAnnotations);
    }
};

/**
 * When a client is deleted, the annotation string is updated in the backend. To make sure we don't keep
 * any annotations that were deleted we need to remove all client annotations and import the new annotation string.
 * We do not need to worry about importing duplicate annotations
 */
export const reloadClientAnnotations = (
    uploadedDocuments: UploadedDocument[],
    uploadedDocumentStateItems: UploadedDocumentState[]
) => {
    uploadedDocuments.forEach(uploadedDocument => {
        const uploadedDocumentState = uploadedDocumentStateItems.find(
            uploadedDocumentStateItem => uploadedDocumentStateItem.uploadedDocumentId === uploadedDocument.id
        );
        if (uploadedDocumentState) {
            // Remove client annotations since they may have been removed from the string
            removeClientAnnotations(uploadedDocumentState.instance);
            // Reload all annotations from the string
            loadSavedAnnotationString(uploadedDocumentState.instance, uploadedDocument.annotations);
        }
    });
};

export const removeExternalAnnotations = (instance: WebViewerInstance) => {
    if (instance) {
        const { annotationManager } = instance.Core;
        const annotations = annotationManager.getAnnotationsList();

        const externalAnnotations = annotations.filter(annotation => !getIsFlkAnnotation(annotation));

        if (externalAnnotations.length > 0) {
            annotationManager.deleteAnnotations(externalAnnotations);
        }
    }
};
const changeClientIndex = (
    annotation: ClientSignatureAnnotation | ClientDateAnnotation | WitnessSignatureAnnotation | WitnessNameAnnotation,
    newIndex: number
) => {
    const regex = /(.*)-(.*)-([0-9]+)$/;
    const newSubject = annotation.Subject.replace(regex, `$1-$2-${newIndex}`) as
        | WitnessSignatureSubjectType
        | ClientSignatureSubjectType
        | ClientDateSubjectType
        | WitnessNameSubjectType;

    annotation.Subject = newSubject;

    if (isClientSignatureAnnotation(annotation)) {
        annotation.setImageData(SIGNATURE_IMAGES[newIndex], KEEP_AS_SVG);
    } else if (isWitnessAnnotation(annotation)) {
        annotation.setImageData(WITNESS_SIGNATURE_IMAGES[newIndex], KEEP_AS_SVG);
    } else if (isWitnessNameAnnotation(annotation)) {
        annotation.setContents(getWitnessNameAnnotationText(newIndex));
    } else if (isClientDateAnnotation(annotation)) {
        annotation.setContents(getClientDateAnnotationText(newIndex));
    }
};

const getStringifiedAnnotationCounts = (annotations: Core.Annotations.Annotation[]) => {
    const clientSignatureAnnotations = annotations.filter(annotation =>
        isClientSignatureAnnotation(annotation)
    ) as ClientSignatureAnnotation[];

    const clientWitnessSignatureAnnotations = annotations.filter(annotation =>
        isWitnessAnnotation(annotation)
    ) as WitnessAnnotation[];

    const annotationsCount = JSON.stringify(getClientAnnotationCounts(clientSignatureAnnotations));
    const witnessAnnotationsCount = JSON.stringify(getClientWitnessAnnotationCounts(clientWitnessSignatureAnnotations));

    return {
        annotationsCount,
        witnessAnnotationsCount
    };
};

export const doAllClientsHaveSignatures = (
    numberOfClients: number,
    uploadedDocumentStateItems: UploadedDocumentState[]
): boolean => {
    const clientsHaveSignaturesArray = new Array(numberOfClients).fill(false);

    uploadedDocumentStateItems.forEach(uploadedDocumentStateItem => {
        const { annotationManager } = uploadedDocumentStateItem.instance.Core;
        const annotations = annotationManager.getAnnotationsList();
        const clientSignatureAnnotations = annotations.filter(
            annotation => isClientSignatureAnnotation(annotation) && !annotation.Hidden
        ) as ClientSignatureAnnotation[];

        clientSignatureAnnotations.forEach(annotation => {
            const clientIndex = getClientAnnotationClientIndex(annotation);
            clientsHaveSignaturesArray[parseInt(clientIndex)] = true;
        });
    });

    return clientsHaveSignaturesArray.every(clientHasSignature => clientHasSignature);
};

export const reorderClientAnnotations = (
    fromIndex: number,
    toIndex: number,
    uploadedDocumentStateItems: UploadedDocumentState[],
    setUploadedDocumentsList: React.Dispatch<React.SetStateAction<(UploadedDocument | { document: UploadedFile })[]>>
) => {
    uploadedDocumentStateItems.forEach(uploadedDocumentStateItem => {
        const { uploadedDocumentId } = uploadedDocumentStateItem;
        const { annotationManager } = uploadedDocumentStateItem.instance.Core;

        const clientAnnotations = annotationManager
            .getAnnotationsList()
            .filter(isClientAnnotation) as ClientAnnotation[];

        if (fromIndex > toIndex) {
            clientAnnotations.forEach(annotation => {
                const clientIndex = getClientAnnotationClientIndex(annotation);
                if (parseInt(clientIndex) === fromIndex) {
                    changeClientIndex(annotation, toIndex);
                } else if (parseInt(clientIndex) >= toIndex && parseInt(clientIndex) < fromIndex) {
                    changeClientIndex(annotation, parseInt(clientIndex) + 1);
                }
                annotationManager.redrawAnnotation(annotation);
            });
        } else if (fromIndex < toIndex) {
            clientAnnotations.forEach(annotation => {
                const clientIndex = getClientAnnotationClientIndex(annotation);
                if (parseInt(clientIndex) === fromIndex) {
                    changeClientIndex(annotation, toIndex);
                } else if (parseInt(clientIndex) > fromIndex && parseInt(clientIndex) <= toIndex) {
                    changeClientIndex(annotation, parseInt(clientIndex) - 1);
                }
                annotationManager.redrawAnnotation(annotation);
            });
        }

        const { annotationsCount, witnessAnnotationsCount } = getStringifiedAnnotationCounts(
            annotationManager.getAnnotationsList()
        );

        setUploadedDocumentsList(uploadedDocumentsList => {
            return uploadedDocumentsList.map(uploadedDocument => {
                if (isUploadedDocument(uploadedDocument) && uploadedDocument.id === uploadedDocumentId) {
                    return {
                        ...uploadedDocument,
                        annotationsCount,
                        witnessAnnotationsCount
                    };
                }
                return uploadedDocument;
            });
        });
    });
};

export const addClientAtIndex = (
    newClientIndex: number,
    uploadedDocumentStateItems: UploadedDocumentState[],
    setUploadedDocumentsList: React.Dispatch<React.SetStateAction<(UploadedDocument | { document: UploadedFile })[]>>
) => {
    uploadedDocumentStateItems.forEach(uploadedDocumentStateItem => {
        const { uploadedDocumentId } = uploadedDocumentStateItem;
        const { annotationManager } = uploadedDocumentStateItem.instance.Core;

        const clientAnnotations = annotationManager
            .getAnnotationsList()
            .filter(isClientAnnotation) as ClientAnnotation[];

        clientAnnotations.forEach(annotation => {
            const clientIndex = getClientAnnotationClientIndex(annotation);
            if (parseInt(clientIndex) >= newClientIndex) {
                changeClientIndex(annotation, parseInt(clientIndex) + 1);
            }
            annotationManager.redrawAnnotation(annotation);
        });

        const { annotationsCount, witnessAnnotationsCount } = getStringifiedAnnotationCounts(
            annotationManager.getAnnotationsList()
        );

        setUploadedDocumentsList(uploadedDocumentsList => {
            return uploadedDocumentsList.map(uploadedDocument => {
                if (isUploadedDocument(uploadedDocument) && uploadedDocument.id === uploadedDocumentId) {
                    return {
                        ...uploadedDocument,
                        annotationsCount,
                        witnessAnnotationsCount
                    };
                }
                return uploadedDocument;
            });
        });
    });
};

export const deleteClientAtIndex = (
    clientToDeleteIndex: number,
    uploadedDocumentStateItems: UploadedDocumentState[],
    setUploadedDocumentsList: React.Dispatch<React.SetStateAction<(UploadedDocument | { document: UploadedFile })[]>>
) => {
    uploadedDocumentStateItems.forEach(uploadedDocumentStateItem => {
        const { uploadedDocumentId } = uploadedDocumentStateItem;
        const { annotationManager } = uploadedDocumentStateItem.instance.Core;

        const clientAnnotations = annotationManager
            .getAnnotationsList()
            .filter(isClientAnnotation) as ClientAnnotation[];

        clientAnnotations.forEach(annotation => {
            const clientIndex = getClientAnnotationClientIndex(annotation);
            if (parseInt(clientIndex) === clientToDeleteIndex) {
                annotationManager.deleteAnnotation(annotation);
            } else if (parseInt(clientIndex) > clientToDeleteIndex) {
                changeClientIndex(annotation, parseInt(clientIndex) - 1);
            }
            annotationManager.redrawAnnotation(annotation);
        });

        const { annotationsCount, witnessAnnotationsCount } = getStringifiedAnnotationCounts(
            annotationManager.getAnnotationsList()
        );

        setUploadedDocumentsList(uploadedDocumentsList => {
            return uploadedDocumentsList.map(uploadedDocument => {
                if (isUploadedDocument(uploadedDocument) && uploadedDocument.id === uploadedDocumentId) {
                    return {
                        ...uploadedDocument,
                        annotationsCount,
                        witnessAnnotationsCount
                    };
                }
                return uploadedDocument;
            });
        });
    });
};
