import React, { useState } from 'react';
import Card, { CardShadow, CardStyles } from '@app/common/components/cards/Card';
import Icon, { Icons, IconSize, PropertyIcon, UserIcon } from '@app/common/components/Icon';
import ToggleButtonV2 from '@app/common/components/ToggleButtonV2';
import { User } from '../../../../types/User';
import { FormRadioGroupButton } from '../../../../components/form/FormRadioGroupButton.js';
import IntegrationAddressInput from '../../../../components/lease/IntegrationAddressInput.js';
import { integration, integrationType } from '@app/constants/constants';

import styles from './EnableIntegrationSection.module.scss';

type EnableIntegrationSection = {
    loggedInUser: User;
    enabled: boolean;
    onChangeToggle: (value: boolean) => void;
    selectedIntegration: integration;
    withIntegrationType: boolean;
    onChange: () => void;
    onSelect: (selectedIntegration: any) => void;
    helpText?: string;
    inputName?: string;
};

function getIconForIntegration(selectedIntegration: integration) {
    switch (selectedIntegration) {
        case integration.INTEGRATION_PROPERTY_ME:
            return Icons.PROPERTY_ME_LOGO;
        case integration.INTEGRATION_PROPERTY_TREE:
            return Icons.MRI_LOGO;
        case integration.INTEGRATION_MANAGED:
            return Icons.MANAGED_LOGO;
        case integration.INTEGRATION_VAULT_RE:
            return Icons.MRI_LOGO;
        default:
            throw new Error('Invalid integration');
    }
}

function getSearchText(selectedIntegration: integration) {
    switch (selectedIntegration) {
        case integration.INTEGRATION_PROPERTY_ME:
            return 'Search PropertyMe';
        case integration.INTEGRATION_PROPERTY_TREE:
            return 'Search PropertyTree';
        case integration.INTEGRATION_MANAGED:
            return 'Search Managed';
        case integration.INTEGRATION_VAULT_RE:
            return 'Search VaultRE';
        default:
            throw new Error('Invalid integration');
    }
}

const EnableIntegrationSection: React.FC<EnableIntegrationSection> = ({
    loggedInUser,
    enabled,
    selectedIntegration,
    withIntegrationType,
    onChangeToggle,
    onChange,
    onSelect,
    helpText,
    inputName = 'address'
}) => {
    const [selectedIntegrationType, setSelectedIntegrationType] = useState<integrationType>(integrationType.PROPERTY);

    function onChangeSearchType(type: integrationType) {
        if (type !== selectedIntegrationType) {
            setSelectedIntegrationType(type);
            onChange();
        }
    }

    return (
        <Card style={CardStyles.SQUARE} shadow={CardShadow.SMALL} animate={false} className={styles.integrationSection}>
            <div className={styles.integrationSelect}>
                <Icon
                    className={styles.integrationIcon}
                    icon={getIconForIntegration(selectedIntegration)}
                    size={IconSize.LARGE}
                />
                <ToggleButtonV2
                    className={styles.integrationToggle}
                    label={getSearchText(selectedIntegration)}
                    onClick={onChangeToggle}
                    checked={enabled}
                />
            </div>
            {enabled && (
                <div className={styles.addressSearch}>
                    {withIntegrationType && (
                        <>
                            <p className={styles.text}>Which profile do you want to link this document to?</p>
                            <FormRadioGroupButton
                                name="searchType"
                                value={selectedIntegrationType}
                                className={styles.searchTypeRadioGroup}
                                radioItemClassName={styles.radioItem}
                                data={[
                                    {
                                        label: 'Property',
                                        value: integrationType.PROPERTY,
                                        icon: PropertyIcon,
                                        onClick: () => onChangeSearchType(integrationType.PROPERTY)
                                    },
                                    {
                                        label: 'Contact',
                                        value: integrationType.CONTACT,
                                        icon: UserIcon,
                                        onClick: () => onChangeSearchType(integrationType.CONTACT)
                                    }
                                ]}
                            />
                        </>
                    )}
                    <IntegrationAddressInput
                        name={inputName}
                        placeholder={
                            selectedIntegrationType === integrationType.CONTACT ? 'Search contact' : 'Search property'
                        }
                        onChange={onChange}
                        onSelect={onSelect}
                        loggedInUser={loggedInUser}
                        integration={selectedIntegration}
                        integrationType={selectedIntegrationType}
                        key={selectedIntegrationType}
                    />
                    {helpText && <p className={styles.helpText}>{helpText}</p>}
                </div>
            )}
        </Card>
    );
};

export default EnableIntegrationSection;
