import React from 'react';
import { FormPhone } from './FormPhone';
import useSuggestions from '../../hooks/useSuggestions';
import { isEmpty } from 'lodash';

type SuggestionPhoneFieldProps = React.PropsWithChildren<{
    suggestionsFieldName: string;
}>;

const SuggestionPhoneField: React.FC<SuggestionPhoneFieldProps> = ({ suggestionsFieldName, ...rest }) => {
    const { suggestionsList, suggestionIcon, removeSuggestionFromRedux } = useSuggestions(suggestionsFieldName);

    return !isEmpty(suggestionsList) ? (
        <FormPhone
            suggestionsList={suggestionsList}
            removeSuggestion={removeSuggestionFromRedux}
            suggestionIcon={suggestionIcon}
            {...rest}
        />
    ) : (
        <FormPhone {...rest} />
    );
};

export default SuggestionPhoneField;
