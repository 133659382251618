import React from 'react';
import cx from 'classnames';
import { useFieldArray } from 'react-final-form-arrays';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { useField, useForm } from 'react-final-form';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlert from './ConfirmAlert';

import CustomLabelTextInput from '../../../dashboard/documents/buildADoc/components/CustomLabelTextInput';
import CustomPlaceholder from '../../../dashboard/documents/createFlk/CustomPlaceholder';
import {
    CustomPlaceholder as CustomPlaceholderType,
    CustomPlaceholderRespondentType
} from '../../../../types/UploadADoc';
import { SIGNER_PLACEHOLDERS_DEMO } from '../../../../constants/featureFlags';
import { getCustomPlaceholderValuePlaceholderText } from './utils';
import CustomPlaceholderTypeDropdown from './CustomPlaceholderTypeDropdown';

import styles from './CustomPlaceholderInputs.module.scss';
import { getFormError } from '@app/utils/finalFormUtils';

type CustomPlaceholderRowProps = {
    name: string;
    disabled?: boolean;
    onDelete?: (customPlaceholderData: CustomPlaceholderType, index: number) => void;
    inputToFocusRef: React.RefObject<HTMLInputElement>;
    customPlaceholderData: CustomPlaceholderType;
    isSignerPlaceholderDemoActive: boolean;
    index: number;
    recipientLabelLowercase?: string;
    suggestions?: string[];
    warnBeforeDelete?: boolean;
};

const CustomPlaceholderRow: React.FC<CustomPlaceholderRowProps> = ({
    index,
    name,
    disabled,
    onDelete,
    inputToFocusRef,
    customPlaceholderData,
    isSignerPlaceholderDemoActive,
    recipientLabelLowercase,
    suggestions,
    warnBeforeDelete
}) => {
    const errorsRef = React.useRef<HTMLDivElement>(null);

    const form = useForm();
    const values = form.getState().values;
    const numberOfClients = values.clients?.length || 0;

    const { meta: countMeta } = useField(`${name}.count`, {
        subscription: { error: true, submitError: true, dirtySinceLastSubmit: true }
    });

    const countError = getFormError(countMeta);

    const handleDelete = () => {
        if (!onDelete) {
            return;
        }

        if (warnBeforeDelete) {
            confirmAlert({
                customUI: ({ onClose }) => (
                    <ConfirmAlert
                        message="Are you sure you want to delete this placeholder?"
                        confirmText="Yes"
                        cancelText="No"
                        onConfirm={async () => {
                            onDelete(customPlaceholderData, index);
                            onClose();
                        }}
                        onCancel={() => {
                            onClose();
                        }}
                        onClose={onClose}
                    />
                )
            });
        } else {
            onDelete(customPlaceholderData, index);
        }
    };

    return (
        <React.Fragment>
            <CustomLabelTextInput
                className={cx(styles.customPlaceholderInput, {
                    [styles.signerPlaceholder]:
                        isSignerPlaceholderDemoActive &&
                        customPlaceholderData.respondentType === CustomPlaceholderRespondentType.CLIENT
                })}
                name={name}
                disabled={disabled}
                showDeleteButton
                onDelete={onDelete ? handleDelete : undefined}
                valuePlaceholder={getCustomPlaceholderValuePlaceholderText(customPlaceholderData.respondentType)}
                hidePlaceholder={
                    customPlaceholderData.respondentType === CustomPlaceholderRespondentType.SENDER && index !== 0
                }
                labelInputRef={inputToFocusRef}
                valueDisabled={
                    customPlaceholderData.respondentType === CustomPlaceholderRespondentType.CLIENT ||
                    customPlaceholderData.lockedValue
                }
                labelDisabled={customPlaceholderData.lockedLabel}
                labelClassName={styles.customPlaceholderInputLabel}
                additionalErrors={countError ? [countError] : []}
                errorsRef={errorsRef}
                suggestions={suggestions}
            />
            {isSignerPlaceholderDemoActive && (
                <div className={styles.dropdownContainer}>
                    <CustomPlaceholderTypeDropdown
                        customPlaceholderName={name}
                        customPlaceholderRespondentType={customPlaceholderData.respondentType}
                        disabled={disabled || customPlaceholderData.lockedRespondentType}
                        isSignerPlaceholderOptionDisabled={numberOfClients > 1}
                        index={index}
                        recipientLabelLowercase={recipientLabelLowercase}
                    />
                    {errorsRef.current && (
                        <div
                            className={styles.errorSpacing}
                            style={{ height: `${errorsRef.current?.clientHeight}px` }}
                        />
                    )}
                </div>
            )}
            {(customPlaceholderData.label || customPlaceholderData.value) && (
                <div className={styles.customPlaceholderContainer}>
                    <CustomPlaceholder
                        className={styles.customPlaceholderPreview}
                        number={index + 1}
                        label={customPlaceholderData.label}
                        value={customPlaceholderData.value}
                        respondentType={customPlaceholderData.respondentType}
                        disabled={disabled}
                    />
                    {errorsRef.current && (
                        <div
                            className={styles.errorSpacing}
                            style={{ height: `${errorsRef.current?.clientHeight}px` }}
                        />
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

type CustomPlaceholderInputsProps = {
    disabled?: boolean;
    onDelete?: (customPlaceholderData: CustomPlaceholderType, index: number) => void;
    inputToFocusRef: React.RefObject<HTMLInputElement>;
    recipientLabelLowercase?: string;
    suggestions?: { key: string; values: string[] }[];
    templatePlaceholderKeys?: string[];
};

const CustomPlaceholderInputs: React.FC<CustomPlaceholderInputsProps> = ({
    disabled,
    onDelete,
    inputToFocusRef,
    recipientLabelLowercase,
    suggestions,
    templatePlaceholderKeys
}) => {
    const isSignerPlaceholderDemoActive = useFeatureFlag(SIGNER_PLACEHOLDERS_DEMO);

    const customPlaceholdersFieldArray = useFieldArray('customPlaceholders');
    const { fields } = customPlaceholdersFieldArray;

    return (
        <div
            className={cx(styles.customPlaceholders, {
                [styles.signerPlaceholders]: isSignerPlaceholderDemoActive
            })}
        >
            {fields.map((name, index) => {
                const customPlaceholderData = fields.value[index];
                const fieldSuggestions = suggestions?.find(s => s.key === customPlaceholderData.key)?.values;
                const shouldWarnBeforeDelete = !!(
                    templatePlaceholderKeys &&
                    customPlaceholderData.key &&
                    templatePlaceholderKeys.includes(customPlaceholderData.key)
                );

                return (
                    <CustomPlaceholderRow
                        key={index}
                        index={index}
                        name={name}
                        disabled={disabled}
                        onDelete={onDelete}
                        inputToFocusRef={inputToFocusRef}
                        customPlaceholderData={customPlaceholderData}
                        isSignerPlaceholderDemoActive={isSignerPlaceholderDemoActive}
                        recipientLabelLowercase={recipientLabelLowercase}
                        suggestions={fieldSuggestions}
                        warnBeforeDelete={shouldWarnBeforeDelete}
                    />
                );
            })}
        </div>
    );
};

export default CustomPlaceholderInputs;
