import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TemplateTable from './components/TemplateTable';
import '../../../sass/settings/templates/templates.scss';
import {
    openAgreementTemplateForm,
    getTemplates,
    deleteAgreementTemplate,
    editAgreementTemplate
} from './../../../actions/template';
import { getTemplatesSorting, getTemplatesRefreshPagination } from '../../../selectors/dashboard/templates';
import { getIsEditingTemplateFinish } from '../../../selectors/lease';
import { isUserRoleAdmin } from '../../../selectors/user';
import { getUser } from '../../../selectors/user';
import { confirmAlert } from 'react-confirm-alert';
import { getTemplatesForLeaseType } from '../../../selectors/dashboard/templates';
import { getTemplatesPaginationByType } from '../../../selectors/dashboard/templates';
import {
    NSW_STATE,
    QLD_STATE,
    VIC_STATE,
    TEMPLATE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_HOLIDAY_LETTING,
    TEMPLATE_TYPE_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_SALES
} from '../../../config';
import { openSubapp } from '../../../utils/openSubapp';
import {
    HOLIDAY_LETTING_TEMPLATE_SUBAPP_PATH,
    HOLIDAY_LETTING_EDIT_NSW_TEMPLATE_SUBAPP_PATH,
    SALES_TEMPLATE_SUBAPP_PATH,
    SALES_EDIT_NSW_TEMPLATE_SUBAPP_PATH,
    SALES_EDIT_VIC_TEMPLATE_SUBAPP_PATH,
    PM_TEMPLATE_SUBAPP_PATH,
    PM_EDIT_NSW_TEMPLATE_SUBAPP_PATH,
    PM_EDIT_QLD_TEMPLATE_SUBAPP_PATH,
    COMMERCIAL_PM_EDIT_NSW_TEMPLATE_SUBAPP_PATH,
    COMMERCIAL_PM_TEMPLATE_SUBAPP_PATH
} from '../../../constants/constants';
import { getAgencyDefaultLocation } from '../../../selectors/settings/account';
import { DAGOBAH_QLD_RPMA } from '@app/constants/featureFlags';
import { useCachedFeatureFlag } from '@app/hooks/useCachedFeatureFlag';

const Templates = ({ leaseType }) => {
    const dispatch = useDispatch();
    const templateList = useSelector(state => getTemplatesForLeaseType(state, leaseType));
    const user = useSelector(getUser);
    const isAdmin = useSelector(isUserRoleAdmin);
    const templatesPagination = useSelector(state => getTemplatesPaginationByType(state, leaseType));
    const templatesSorting = useSelector(state => getTemplatesSorting(state, leaseType));
    const refreshPagination = useSelector(getTemplatesRefreshPagination);
    const isEditingTemplateFinish = useSelector(getIsEditingTemplateFinish);
    const location = useSelector(getAgencyDefaultLocation);

    const isDagobahQldRPMAEnabled = useCachedFeatureFlag(DAGOBAH_QLD_RPMA);

    function getMaxSchemaVersion(leaseType) {
        let maxSchemaVersion;
        if (
            leaseType === TEMPLATE_TYPE_SALES ||
            leaseType === TEMPLATE_TYPE_PROPERTY_MANAGEMENT ||
            leaseType === TEMPLATE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT
        ) {
            maxSchemaVersion = 2;
        }

        return maxSchemaVersion;
    }

    useEffect(() => {
        const maxSchemaVersion = getMaxSchemaVersion(leaseType);

        dispatch(
            getTemplates(
                leaseType,
                templatesPagination.page,
                templatesPagination.limit,
                templatesSorting.field,
                templatesSorting.direction,
                maxSchemaVersion
            )
        );
    }, [dispatch, isEditingTemplateFinish, refreshPagination]);

    function openCreateTemplateForm() {
        // Open holiday letting template from the new app if holiday letting template is selected
        if (leaseType === TEMPLATE_TYPE_HOLIDAY_LETTING) {
            openSubapp(HOLIDAY_LETTING_TEMPLATE_SUBAPP_PATH);
        } else if (leaseType === TEMPLATE_TYPE_SALES && [NSW_STATE, VIC_STATE].includes(location)) {
            openSubapp(SALES_TEMPLATE_SUBAPP_PATH);
        } else if (leaseType === TEMPLATE_TYPE_PROPERTY_MANAGEMENT && location === NSW_STATE) {
            openSubapp(PM_TEMPLATE_SUBAPP_PATH);
        } else if (isDagobahQldRPMAEnabled && leaseType === TEMPLATE_TYPE_PROPERTY_MANAGEMENT && location === QLD_STATE) {
            openSubapp(PM_TEMPLATE_SUBAPP_PATH);
        } else if (leaseType === TEMPLATE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT && location === NSW_STATE) {
            openSubapp(COMMERCIAL_PM_TEMPLATE_SUBAPP_PATH);
        } else {
            dispatch(openAgreementTemplateForm(leaseType));
        }
    }

    function deleteTemplate(template) {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to delete this template?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(deleteAgreementTemplate(template.id, leaseType));
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    function editTemplate(template) {
        // Open holiday letting template from the new app if holiday letting template is selected
        if (leaseType === TEMPLATE_TYPE_HOLIDAY_LETTING) {
            openSubapp(`${HOLIDAY_LETTING_EDIT_NSW_TEMPLATE_SUBAPP_PATH}/${template.id}`);
        } else if (leaseType === TEMPLATE_TYPE_SALES && template.location === NSW_STATE) {
            openSubapp(`${SALES_EDIT_NSW_TEMPLATE_SUBAPP_PATH}/${template.id}`);
        } else if (leaseType === TEMPLATE_TYPE_SALES && template.location === VIC_STATE) {
            openSubapp(`${SALES_EDIT_VIC_TEMPLATE_SUBAPP_PATH}/${template.id}`);
        } else if (leaseType === TEMPLATE_TYPE_PROPERTY_MANAGEMENT && template.location === NSW_STATE) {
            openSubapp(`${PM_EDIT_NSW_TEMPLATE_SUBAPP_PATH}/${template.id}`);
        } else if (isDagobahQldRPMAEnabled && leaseType === TEMPLATE_TYPE_PROPERTY_MANAGEMENT && template.location === QLD_STATE) {
            openSubapp(`${PM_EDIT_QLD_TEMPLATE_SUBAPP_PATH}/${template.id}`);
        } else if (leaseType === TEMPLATE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT && template.location === NSW_STATE) {
            openSubapp(`${COMMERCIAL_PM_EDIT_NSW_TEMPLATE_SUBAPP_PATH}/${template.id}`);
        } else {
            dispatch(editAgreementTemplate(user, template));
        }
    }

    function cloneTemplate(template) {
        dispatch(
            openAgreementTemplateForm(leaseType, {
                label: template.templateName,
                value: template.id,
                type: 'template',
                location: template.location
            })
        );
    }

    function changeTemplatePage(page) {
        const maxSchemaVersion = getMaxSchemaVersion(leaseType);
        dispatch(
            getTemplates(
                leaseType,
                page,
                templatesPagination.limit,
                templatesSorting.field,
                templatesSorting.direction,
                maxSchemaVersion
            )
        );
    }

    function changeSortOptions(options) {
        let field = Object.keys(options)[0];
        let direction = options[field];
        const maxSchemaVersion = getMaxSchemaVersion(leaseType);
        dispatch(
            getTemplates(
                leaseType,
                templatesPagination.page,
                templatesPagination.limit,
                field,
                direction,
                maxSchemaVersion
            )
        );
    }

    return (
        <div className="settings">
            <div className="templates-page">
                <div className="templates-section">
                    <TemplateTable
                        templateType={leaseType}
                        isAdmin={isAdmin}
                        templates={templateList}
                        templatePagination={templatesPagination}
                        templatesSorting={templatesSorting}
                        createNewTemplate={openCreateTemplateForm}
                        deleteTemplate={deleteTemplate}
                        editTemplate={editTemplate}
                        cloneTemplate={cloneTemplate}
                        changePage={changeTemplatePage}
                        changeSortOptions={changeSortOptions}
                    />
                </div>
            </div>
        </div>
    );
};

export default Templates;
