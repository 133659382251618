import React, { useState } from 'react';
import { Field } from 'react-final-form';
import TagInput, { TagInputProps } from '@app/common/design-system/tag-input/TagInput';
import Suggestion from '@app/components/suggestions/Suggestion';
import useSuggestions from '../../hooks/useSuggestions';
import { useFieldArray } from 'react-final-form-arrays';
import { isEmpty, difference, kebabCase } from 'lodash';

type SuggestionTagInputProps = React.PropsWithChildren<{
    name: string;
    suggestionsFieldName: string;
    tagInputProps: TagInputProps;
}>;

const SuggestionTagInput: React.FC<SuggestionTagInputProps> = ({
    name,
    suggestionsFieldName,
    tagInputProps,
    ...rest
}) => {
    const { suggestionsList, suggestionIcon } = useSuggestions(suggestionsFieldName);
    const tagList = useFieldArray(name);

    // Create a local blacklisting of suggestions since we don't actually want to remove the suggestion in redux.
    // Theoretically those suggestions can be used by other fields as well.
    const [removedSuggestions, addToRemovedSuggestions] = useState<string[]>([]);

    // Return only the suggestions that is not in tagList and not in removedSuggestions
    const availableSuggestion = difference(suggestionsList || [], tagList.fields.value || [], removedSuggestions);

    return !isEmpty(availableSuggestion) ? (
        <Field name={name}>
            {({ input, meta }) => (
                <TagInput {...input} {...tagInputProps} required addTagOnBlur>
                    {!isEmpty(availableSuggestion) && (
                        <div className="suggestion-list">
                            {availableSuggestion?.map(suggestion => {
                                return (
                                    <Suggestion
                                        className={'form-suggestions'}
                                        key={`suggestion-${kebabCase(suggestion)}`}
                                        text={suggestion}
                                        icon={suggestionIcon}
                                        onClick={() => {
                                            tagList.fields.push(suggestion);
                                        }}
                                        onClickEndIcon={e => {
                                            e?.stopPropagation();
                                            addToRemovedSuggestions(removedSugg => [...removedSugg, suggestion]);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </TagInput>
            )}
        </Field>
    ) : (
        <Field name={name}>
            {({ input, meta }) => <TagInput {...input} {...tagInputProps} required addTagOnBlur />}
        </Field>
    );
};

export default SuggestionTagInput;
