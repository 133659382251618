import { FormSubmitType } from '@app/config';
import { EocClient, ClientType, ExchangeOfContracts, TemplatePlaceholderKeys } from '@app/types/ExchangeOfContracts';
import {
    CustomPlaceholder,
    CustomPlaceholderRespondentType,
    UploadedDocument,
    UploadedFile
} from '@app/types/UploadADoc';
import { FormApi } from 'final-form';
import * as yup from 'yup';
import { IntegrationSearchResult, VaultReIntegrationSearchResult } from './integration/IntegrationAddressSection';
import { integration, integrationType } from '@app/constants/constants';
import { FormValues } from './eoc-create/EocCreate';

export type FormClient = Pick<EocClient, 'name' | 'email' | 'clientType' | 'phone'> & {
    id?: string;
};

export type FormModel = {
    address: string;
    purchasers: FormClient[];
    vendors: FormClient[];
    customPlaceholders?: CustomPlaceholder[];
    submitType?: FormSubmitType;
    descriptionText: string;
};

export const projectFormModel = (data: ExchangeOfContracts): FormModel => {
    const purchasers = data.client?.clients?.filter(client => client.clientType === ClientType.PURCHASER);
    const vendors = data.client?.clients?.filter(client => client.clientType === ClientType.VENDOR);

    return {
        purchasers: purchasers?.length
            ? purchasers
            : [{ name: '', email: '', phone: '', clientType: ClientType.PURCHASER }],
        vendors: vendors?.length ? vendors : [{ name: '', email: '', phone: '', clientType: ClientType.VENDOR }],
        customPlaceholders: data.customPlaceholders || [],
        address: data.address,
        descriptionText: data.descriptionText
    };
};

type ApiClient = FormClient & { signingOrder: number };

export type ApiModel = {
    clients: ApiClient[];
    uploadedDocuments: (UploadedDocument | { document: UploadedFile })[];
    customPlaceholders: CustomPlaceholder[];
    address: string;
    descriptionText: string;
};

export const projectApiModelWithoutUploadedDocuments = (formModel: FormModel): Omit<ApiModel, 'uploadedDocuments'> => {
    const clients = [...formModel.purchasers, ...formModel.vendors].map((client, index) => ({
        ...client,
        name: client.name || '',
        email: client.email || '',
        phone: client.phone || '',
        signingOrder: index + 1
    }));

    return {
        clients,
        customPlaceholders: formModel.customPlaceholders || [],
        address: formModel.address,
        descriptionText: formModel.descriptionText
    };
};

export const projectApiModel = (formModel: FormModel, uploadedDocuments: ApiModel['uploadedDocuments']): ApiModel => {
    return {
        ...projectApiModelWithoutUploadedDocuments(formModel),
        uploadedDocuments
    };
};

type ClientError = {
    name?: string;
    email?: string;
    phone?: string;
};

type FormErrors = {
    purchasers?: ClientError[];
    vendors?: ClientError[];
    customPlaceholders?: {
        label?: string;
        value?: string;
        count?: string;
    }[];
};

type ApiErrors = {
    client?: { clients?: ClientError[] };
    customPlaceholders?: {
        label?: string;
        value?: string;
        count?: string;
    }[];
};

// TODO: Uploaded documents errors?
export const projectFormErrors = (errors: ApiErrors, values: FormModel): FormErrors => {
    const purchasers = errors.client?.clients?.slice(0, values.purchasers.length);
    const vendors = errors.client?.clients?.slice(values.purchasers.length);
    const e = {
        purchasers,
        vendors,
        customPlaceholders: errors.customPlaceholders
    };

    return e;
};

export const getMaxRecipients = (clientType: ClientType, formValues: FormModel): number => {
    const MAX_TOTAL_RECIPIENTS = 8;
    const MAX_SECTION_RECIPIENTS = 7;

    if (clientType === ClientType.PURCHASER) {
        return Math.min(MAX_SECTION_RECIPIENTS, MAX_TOTAL_RECIPIENTS - formValues.vendors.length);
    } else {
        return Math.min(MAX_SECTION_RECIPIENTS, MAX_TOTAL_RECIPIENTS - formValues.purchasers.length);
    }
};

export const formValuesSchema = yup.object().shape({
    purchasers: yup
        .array(
            yup.object().shape({
                name: yup.string().required('Name is required'),
                email: yup.string().email('Invalid email').required('Email is required'),
                phone: yup.string().required('Phone is required'),
                clientType: yup.string().oneOf([ClientType.PURCHASER]).required(),
                id: yup.string().notRequired()
            })
        )
        .min(1, 'At least one purchaser is required'),
    vendors: yup
        .array(
            yup.object().shape({
                name: yup.string().required('Name is required'),
                email: yup.string().email('Invalid email').required('Email is required'),
                phone: yup.string().required('Phone is required'),
                clientType: yup.string().oneOf([ClientType.VENDOR]).required(),
                id: yup.string().notRequired()
            })
        )
        .min(1, 'At least one vendor is required'),
    customPlaceholders: yup.array(
        yup.object().shape({
            id: yup.string().required(),
            label: yup.string().required('Label is required'),
            value: yup.string().when('respondentType', {
                is: CustomPlaceholderRespondentType.SENDER,
                then: schema => schema.required('Value is required'),
                otherwise: schema => schema.notRequired()
            }),
            respondentType: yup
                .string()
                .oneOf([CustomPlaceholderRespondentType.SENDER, CustomPlaceholderRespondentType.CLIENT])
                .required(),
            count: yup.number().required().min(1, 'Placeholder is missing from the document')
        })
    ),
    uploadedDocuments: yup
        .array(
            yup.object().shape({
                document: yup
                    .object()
                    .shape({
                        documentName: yup.string().required(),
                        fileName: yup.string().required(),
                        file: yup.string().required(),
                        size: yup.number().required(),
                        category: yup.string().required(),
                        leaseType: yup.string().required()
                    })
                    .required()
            })
        )
        .required()
        .min(1, 'At least one document is required'),
    hasReviewedPdf: yup.boolean().test(
        (hasReviewedPdf, { createError }) =>
            hasReviewedPdf ||
            createError({
                message: 'You must review the document before sending.'
            })
    ),
    doAllRecipientsHaveSignatures: yup.boolean().test(
        (doAllRecipientsHaveSignatures, { createError }) =>
            doAllRecipientsHaveSignatures ||
            createError({
                message: 'Add signature placeholders for all recipients'
            })
    )
});

const recipientsSchema = yup.object().shape({
    purchasers: formValuesSchema.fields.purchasers,
    vendors: formValuesSchema.fields.vendors
});

export const isRecipientSectionValid = (values: FormModel): boolean => {
    try {
        recipientsSchema.validateSync(values, { abortEarly: false });
        return true;
    } catch (error) {
        return false;
    }
};

export const doAllRecipientsHaveNames = (values: FormModel): boolean => {
    return values.purchasers.every(client => !!client.name) && values.vendors.every(client => !!client.name);
};

export const getCustomPlaceholderValueField = (key: string, form: FormApi<FormModel>) => {
    const values = form.getState().values;
    const index = values.customPlaceholders?.findIndex(cp => cp.key === key);
    const fieldName = `customPlaceholders[${index}].value`;
    if (index !== undefined && index !== -1 && form.getRegisteredFields().find(f => f === fieldName)) {
        return form.getFieldState(fieldName as keyof FormModel);
    }
};

export const getCombinedPurchaserNames = (purchasers: FormModel['purchasers']): string => {
    return purchasers.map(client => client.name).join(', ');
};

export const handlePurchasersChange = (form: FormApi<FormModel>) => {
    const values = form.getState().values;
    const purchaserNamesField = getCustomPlaceholderValueField(TemplatePlaceholderKeys.PURCHASER_NAMES, form);

    if (!purchaserNamesField) {
        return;
    }

    const combinedPurchaserNames = getCombinedPurchaserNames(values.purchasers);

    if (purchaserNamesField.value === combinedPurchaserNames) {
        return;
    }

    purchaserNamesField.change(getCombinedPurchaserNames(values.purchasers));
};

export const handleSelectIntegration = (
    form: FormApi<FormValues, Partial<FormValues>>,
    selectedIntegration: IntegrationSearchResult
) => {
    if (selectedIntegration) {
        let address = '';
        let integrationPropertyId = '';
        if (selectedIntegration.integration === integration.INTEGRATION_VAULT_RE) {
            selectedIntegration = selectedIntegration as VaultReIntegrationSearchResult;
            address = selectedIntegration.addressText || selectedIntegration.fullName || '';
            integrationPropertyId = selectedIntegration.id;
        }

        form.batch(() => {
            form.change('integration.address', address);
            form.change('integration.id', integrationPropertyId);
            form.change('integration.type', selectedIntegration?.integration);

            if (selectedIntegration?.integration === integration.INTEGRATION_VAULT_RE) {
                selectedIntegration = selectedIntegration as VaultReIntegrationSearchResult;
                form.change('integration.entityType', selectedIntegration.integrationType);
                if (selectedIntegration.integrationType === integrationType.PROPERTY) {
                    form.change('integration.vaultReLifeId', selectedIntegration.lifeId);
                    form.change('integration.vaultReLifeType', selectedIntegration.lifeType);
                }
            } else {
                form.change('integration.entityType', integrationType.PROPERTY);
            }
            form.change('address', address);
        });
    }
};

export const resetIntegrationFormData = (form: FormApi<FormValues, Partial<FormValues>>) => {
    form.batch(() => {
        form.change('address', undefined);
        form.change('integration', {});
    });
};
