import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { DOCUMENT_EDIT_MODE, NSW_STATE } from '@app/config';
import { getIsExchangeOfContractsModalOpen } from '@app/selectors/document/exchangeOfContracts';
import { addOrReplaceDocumentInList, setCurrentDocument, setEditMode } from '@app/actions/document';
import { ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import { GoogleApiAddressDetails } from '@app/types/Common';
import useToast from '@app/hooks/useToast';
import { getErrorMessageForToast } from '@app/utils/errorUtils';
import { ToastTypes } from '@app/common/components/Toast';
import { useHistory } from 'react-router';
import EocModal from '../eoc-modal/EocModal';
import IntegrationAddressSection, { IntegrationSearchResult } from '../integration/IntegrationAddressSection';
import FormAddressDetailsAutocomplete from '@app/components/form/FormAddressDetailsAutocomplete';
import {
    getSelectedAddressEdited,
    getDetailsConfirmed,
    getEditMode,
    getEditAddressFormData
} from '@app/selectors/lease/addresses';
import { clearAddressDetailsStore, openAddressDetailsModal } from '@app/actions/addressDetails';
import Button from '@app/common/components/Button';
import nswImage from '../../../../../../assets/images/content/NSW.svg?url';
import { cloneDeep, isEmpty } from 'lodash';
import { FormApi } from 'final-form';
import { resetIntegrationFormData, handleSelectIntegration } from '../ExchangeOfContracts.model';

import styles from './EocCreate.module.scss';

export type FormValues = {
    address: string;
    addressDetails?: GoogleApiAddressDetails;
    location: string;
    integration: {
        id?: string;
        address?: string;
        entityType?: string;
        enabled?: boolean;
    };
};

type EocCreateProps = {
    close: () => void;
};

const EocCreate: React.FC<EocCreateProps> = ({ close }) => {
    const dispatch = useDispatch();
    const { addNewToast } = useToast();
    const history = useHistory();

    const [integrationError, setIntegrationError] = useState<string | undefined>();
    const [isIntegrationEnabled, setIsIntegrationEnabled] = useState(false);
    const [address, setAddress] = useState<string | undefined>(undefined);
    const [addressDetails, setAddressDetails] = useState<GoogleApiAddressDetails | undefined>(undefined);

    const isExchangeOfContractsModalOpen = useSelector(getIsExchangeOfContractsModalOpen);
    const selectedAddressEdited = useSelector(getSelectedAddressEdited);
    const editAddressFormData = useSelector(getEditAddressFormData);
    const detailsConfirmed = useSelector(getDetailsConfirmed);
    const editMode = useSelector(getEditMode);

    const createEocMutation = useMutation<ExchangeOfContracts, Error, FormValues>(
        async values => {
            const response = await axios.post('/api/document/exchange-of-contracts', values);
            return response.data.doc;
        },
        {
            onError: error => {
                window.rollbar.error('Failed to create exchange of contracts', error);
                addNewToast(getErrorMessageForToast('Could not create the document.'), ToastTypes.ERROR);
            }
        }
    );

    const handleSubmit = async (values: FormValues) => {
        const data = cloneDeep(values);
        if (!isIntegrationEnabled) {
            data.address = address || '';
            data.addressDetails = addressDetails;
        }

        const document = await createEocMutation.mutateAsync(data);

        dispatch(addOrReplaceDocumentInList(document));
        dispatch(setCurrentDocument(document));
        dispatch(setEditMode(DOCUMENT_EDIT_MODE));

        history.push(`/dashboard/document/${document.id}`);
    };

    const canSubmit = (values: FormValues) => {
        if (isIntegrationEnabled) {
            return values.integration.id && values.integration.address && values.address;
        }
        return !!address && !isEmpty(addressDetails);
    };

    const resetIntegration = (form: FormApi<FormValues, Partial<FormValues>>) => {
        setIntegrationError(undefined);
        resetIntegrationFormData(form);
    };

    return (
        <EocModal isOpen={isExchangeOfContractsModalOpen} closeModal={close} allowOverflow className={styles.modal}>
            <Form<FormValues> onSubmit={handleSubmit} initialValues={{ location: NSW_STATE, integration: {} }}>
                {({ handleSubmit, values, form }) => (
                    <form onSubmit={handleSubmit} autoComplete="off" className={styles.form}>
                        <div className={styles.header}>
                            <p className={styles.text}>Create New</p>
                            <p className={styles.docType}>Exchange of Contracts</p>
                        </div>
                        <div className={styles.locationSection}>
                            <img className={styles.icon} src={nswImage} />
                            <p className={styles.text}>{values.location}</p>
                        </div>
                        <h2 className={styles.title}>Which Property are you exchanging Contracts for?</h2>
                        <IntegrationAddressSection
                            integrationError={integrationError}
                            setIsIntegrationEnabled={setIsIntegrationEnabled}
                            resetIntegration={() => resetIntegration(form)}
                            handleSelectIntegration={(selectedIntegration: IntegrationSearchResult) =>
                                handleSelectIntegration(form, selectedIntegration)
                            }
                        />
                        {!isIntegrationEnabled && (
                            <FormAddressDetailsAutocomplete
                                name="address"
                                className={styles.addressSearch}
                                value={values.address}
                                editAddressFormData={editAddressFormData}
                                editedNewAddress={selectedAddressEdited}
                                editMode={editMode}
                                showAddressBelow={false}
                                detailsConfirmed={detailsConfirmed}
                                label={'Address'}
                                onChange={(address?: string) => {
                                    form.change('address', address);
                                }}
                                setAddressDetails={(address: string, details: GoogleApiAddressDetails) => {
                                    setAddress(address);
                                    setAddressDetails(details);
                                }}
                                openAddressDetailsModal={(data: any) => dispatch(openAddressDetailsModal(data))}
                                clearAddressDetailsStore={() => dispatch(clearAddressDetailsStore())}
                            />
                        )}
                        <div className={styles.footer}>
                            <Button secondary type="button" onClick={close}>
                                Cancel
                            </Button>
                            <Button
                                primary
                                type="submit"
                                disabled={!canSubmit(values) || createEocMutation.isLoading}
                                loading={createEocMutation.isLoading}
                            >
                                Create Document
                            </Button>
                        </div>
                    </form>
                )}
            </Form>
        </EocModal>
    );
};

export { EocCreate };
