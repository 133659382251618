import { createSelector } from 'reselect';
import { getCommon } from './index';

export const getAddressDetails = state => state.addressDetails;

export const getAddresses = createSelector([getCommon], common => common.addresses);

export const getSelectedAddressEdited = createSelector(
    [getAddressDetails],
    addressDetails => addressDetails.selectedAddressEdited
);

export const getEditAddressFormData = createSelector(
    [getAddressDetails],
    addressDetails => addressDetails.editAddressFormData
);

export const getEditMode = createSelector([getAddressDetails], addressDetails => addressDetails.editMode);

export const getDetailsConfirmed = createSelector(
    [getAddressDetails],
    addressDetails => addressDetails.detailsConfirmed
);

export const getAddressDetailsOpen = createSelector([getAddressDetails], addressDetails => addressDetails.detailsOpen);
