import { EXCHANGE_OF_CONTRACTS } from '@app/config';
import { BaseDocument } from './BaseDocument';
import { AddressDetails } from './Common';
import { CustomPlaceholder, Client as UadClient, UploadedDocument } from './UploadADoc';
import { DateString } from './utilityTypes';
import { CompletionProgress } from './CompletionProgress';

export enum ClientType {
    VENDOR = 'vendor',
    PURCHASER = 'purchaser'
}
export type EocClient = UadClient & {
    clientType: ClientType;
};

export type Integration = {
    id: string;
    address: string;
    type: string;
    entityType: string;
    vaultReLifeId?: string;
    vaultReLifeType?: string;
    active?: boolean;
};

export type ExchangeOfContracts = BaseDocument & {
    address: string;
    addressDetails: AddressDetails;
    id: string;
    status: string;
    client?: {
        clients: EocClient[];
        currentSigningClientOrder?: number;
    };
    uploadedDocuments: UploadedDocument[];
    customPlaceholders: (CustomPlaceholder & { key?: TemplatePlaceholderKeys })[];
    deadline?: number;
    docType: string;
    sentForSigning?: DateString;
    completion?: {
        progress?: CompletionProgress;
        status: string;
    };
    hasAgentSignatureAnnotations?: boolean;
    descriptionText: string;
    pdfGeneratedState?: string;
    suggestions?: { key: string; values: string[] }[];
    integration?: Integration;
};

export const isExchangeOfContracts = (doc: unknown): doc is ExchangeOfContracts => {
    return !!doc && (doc as ExchangeOfContracts).docType === EXCHANGE_OF_CONTRACTS;
};

export enum TemplatePlaceholderKeys {
    PURCHASER_NAMES = 'purchaser_names',
    PURCHASER_ADDRESS = 'purchaser_address',
    OFFER_AMOUNT = 'offer_amount',
    OFFER_DEPOSIT_AMOUNT = 'offer_deposit_amount',
    BALANCE_PAYABLE = 'balance_payable',
    SOLICITOR_CONVEYANCER_NAME = 'solicitor_conveyancer_name',
    SOLICITOR_CONVEYANCER_EMAIL = 'solicitor_conveyancer_email',
    SOLICITOR_CONVEYANCER_ADDRESS = 'solicitor_conveyancer_address'
}
