import * as React from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';

import { AnnotationButtonsContainer } from './AnnotationButtonsContainer';
import { CustomPlaceholder as CustomPlaceholderType } from '../../../../types/UploadADoc';
import { Point } from '../../types';
import { AddPlaceholderButton } from './AddPlaceholderButton';
import ToggleButtonV2 from '../../../../common/components/ToggleButtonV2';

import styles from './AnnotationButtons.module.scss';

type AnnotationButtonsProps = {
    customPlaceholderData?: CustomPlaceholderType[];
    webViewerInstance?: WebViewerInstance;
    dropPointRef: React.MutableRefObject<Point | undefined>;
    handleAddCustomPlaceholder: () => void;
    hideCustomPlaceholderTitles: boolean;
    toggleHideCustomPlaceholderTitles: (showTitles: boolean) => void;
    setHoveredCustomPlaceholderId: (customPlaceholderId?: string) => void;
    fontSize?: number;
};

export const AnnotationButtons: React.FC<AnnotationButtonsProps> = ({
    customPlaceholderData,
    webViewerInstance,
    dropPointRef,
    handleAddCustomPlaceholder,
    hideCustomPlaceholderTitles,
    toggleHideCustomPlaceholderTitles,
    setHoveredCustomPlaceholderId,
    fontSize
}) => {
    return (
        <>
            <div className={styles.controls}>
                <AddPlaceholderButton onClick={handleAddCustomPlaceholder} text="Add new" />
                <ToggleButtonV2
                    className={styles.toggle}
                    checked={hideCustomPlaceholderTitles}
                    onClick={toggleHideCustomPlaceholderTitles}
                    label="Hide titles"
                    labelClassName={styles.toggleLabel}
                    switchClassName={styles.toggleSwitch}
                />
            </div>
            {customPlaceholderData?.map((placeholder, index) => {
                return (
                    <AnnotationButtonsContainer
                        key={placeholder.id}
                        webViewerInstance={webViewerInstance}
                        dropPointRef={dropPointRef}
                        placeholder={placeholder}
                        index={index}
                        setHoveredCustomPlaceholderId={setHoveredCustomPlaceholderId}
                        fontSize={fontSize}
                    />
                );
            })}
        </>
    );
};
