import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { IconSize } from '../../../../../common/components/Icon';
import TooltipIcon, { TooltipIconType } from '../../../../../common/components/TooltipIcon';
import { getAgencyVaultReIntegration } from '../../../../../selectors/agency.js';
import EnableIntegrationSection from '../../integration/EnableIntegrationSection';
import { integration } from '@app/constants/constants';

import styles from './IntegrationAddressSection.module.scss';
import { getUserInfo } from '@app/selectors/user';

type IntegrationAddressSectionProps = {
    integrationError?: string;
    setIsIntegrationEnabled: (enabled: boolean) => void;
    resetIntegration: () => void;
    handleSelectIntegration: (selectedIntegration: IntegrationSearchResult) => void;
};

type BaseIntegrationSearchResult = {
    integration: string;
};

export type VaultReIntegrationSearchResult = BaseIntegrationSearchResult & {
    id: string;
    addressText?: string;
    integrationType: string;
    fullName?: string;
    lifeId?: string;
    lifeType?: string;
    emails?: string[];
    phoneNumbers?: { number: string }[];
};

export type IntegrationSearchResult = VaultReIntegrationSearchResult;

const IntegrationAddressSection: React.FC<IntegrationAddressSectionProps> = ({
    integrationError,
    setIsIntegrationEnabled,
    resetIntegration,
    handleSelectIntegration
}) => {
    const vaultReIntegration = useSelector(getAgencyVaultReIntegration);
    const loggedInUser = useSelector(getUserInfo);

    const [enabledIntegration, setEnabledIntegration] = useState<{
        [key: string]: boolean;
    }>({
        vaultRe: false
    });

    const enableIntegration = (integration: string, value: boolean) => {
        resetIntegration();
        setEnabledIntegration(v => ({
            ...Object.keys(v).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, v),
            [integration]: value
        }));
        setIsIntegrationEnabled(value);
    };

    return (
        <>
            {vaultReIntegration && (
                <div className={styles.addressSectionHeadingSection}>
                    <div className={styles.container}>
                        <h3 className={styles.addressSectionHeading}>Search from one of your Integrations</h3>
                        <TooltipIcon type={TooltipIconType.INFO} iconSize={IconSize.SMALL}>
                            <div className={styles.integrationTooltipBody}>
                                Enhance your workflow by linking this document to any of these integrations. Simply
                                enter the property's address, and after the document is completed it will be
                                automatically pushed into your CRM.
                            </div>
                        </TooltipIcon>
                    </div>
                    <p className={styles.subText}>You can change this at a later time.</p>
                </div>
            )}
            {vaultReIntegration && (
                <EnableIntegrationSection
                    loggedInUser={loggedInUser}
                    inputName="integration.address"
                    enabled={enabledIntegration.vaultRe}
                    onChangeToggle={value => enableIntegration(integration.INTEGRATION_VAULT_RE, value)}
                    selectedIntegration={integration.INTEGRATION_VAULT_RE}
                    withIntegrationType={false}
                    onChange={() => resetIntegration()}
                    onSelect={(selectedIntegration: IntegrationSearchResult) => {
                        return handleSelectIntegration(selectedIntegration);
                    }}
                    helpText="This is pulled from your existing Property Profiles and their respective lifes’ on VaultRE."
                />
            )}
            {integrationError && <div className={styles.integrationError}>{integrationError}</div>}
        </>
    );
};

export default IntegrationAddressSection;
